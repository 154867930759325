import {collectionMonsterPath, collectionZombiesPath} from "./config";
import {convertFromYocto, formatPrice, landTypeMap, rarityMap, shortAddress} from "./utils";

const itemTypeMap = {
  1: "Bomb",
  2: "Bow",
  3: "Sword",
  4: "Armor",
  5: "Shield",
  6: "Health",
  7: "Potion",
  8: "Poison",
}
const modifierMap = {
  1: "Attack",
  2: "Health",
  3: "Brain",
}

export const transformLand = (land) => {
  return {
    tokenId: parseInt(land.tokenId).toString(),
    landType: landTypeMap[parseInt(land.landType)],
    lastZombieClaim: parseInt(land.lastZombieClaim),
    salePrice: parseInt(land.salePrice) ? formatPrice(land.salePrice, false) : null,
    price: parseInt(land.salePrice) || null,
    media: land.media,
    nftType: land.nftType,
    ownerId: land.ownerId.toLowerCase(),
    discoverEvents: parseInt(land.discoverEvents),
    countMintedZombies: parseInt(land.countMintedZombies),
  };
};

export const transformZombie = (zombie) => {
  return {
    tokenId: parseInt(zombie.tokenId).toString(),
    cardRarity: rarityMap[zombie.cardRarity],
    killTokens: parseInt(zombie.killTokens),
    salePrice: parseInt(zombie.salePrice) ? formatPrice(zombie.salePrice, false) : null,
    price: parseInt(zombie.salePrice) || null,
    media: `${collectionZombiesPath[parseInt(zombie.collection)]}/${zombie.media}.png`,
    nftType: zombie.nftType,
    ownerId: zombie.ownerId.toLowerCase(),
    health: parseInt(zombie.health),
    attack: parseInt(zombie.attack),
    brain: parseInt(zombie.brain),
    speed: parseInt(zombie.speed),
    collection: parseInt(zombie.collection),
    modifierItems: zombie.modifier_items,
  };
};

export const transformMonster = (monster) => {
  return {
    tokenId: parseInt(monster.tokenId).toString(),
    cardRarity: rarityMap[monster.cardRarity],
    killTokens: parseInt(monster.killTokens),
    salePrice: parseInt(monster.salePrice) ? formatPrice(monster.salePrice, false) : null,
    price: parseInt(monster.salePrice) || null,
    media: `${collectionMonsterPath[parseInt(monster.collection)]}`,
    nftType: monster.nftType,
    ownerId: monster.ownerId.toLowerCase(),
    health: parseInt(monster.health),
    attack: parseInt(monster.attack),
    brain: parseInt(monster.brain),
    collection: parseInt(monster.collection),
    nextLandDiscovery: parseInt(monster.nextLandDiscovery) * 1000,
  };
};

export const transformCollections = (coll, index) => {
  return {
    id: parseInt(index).toString(),
    title: coll.title,
    image: coll.image,
  };
};

export const transformMarketHistory = (history) => {
  return {
    tokenId: parseInt(history.tokenId),
    fromUser: shortAddress(history.fromUser),
    toUser: shortAddress(history.toUser),
    price: convertFromYocto(history.price, 3, false),
    nftType: history.nftType,
    timestamp: parseInt(history.timestamp),
  };
};

export const transformItem = (item) => {
  return {
    tokenId: parseInt(item.tokenId),
    itemType: itemTypeMap[item.itemType],
    nftType: item.nftType,
    salePrice: parseInt(item.salePrice) ? formatPrice(item.salePrice, false) : null,
    price: parseInt(item.salePrice) || null,
    media: `inventory/${itemTypeMap[item.itemType]}.png`,
    ownerId: item.ownerId.toLowerCase(),
  }
};

export const transformModifier = (item) => {
  return {
    tokenId: parseInt(item.tokenId),
    itemType: modifierMap[item.itemType],
    nftType: item.nftType,
    level: item.level,
    salePrice: parseInt(item.salePrice) ? formatPrice(item.salePrice, false) : null,
    price: parseInt(item.salePrice) || null,
    media: `modifier/${modifierMap[item.itemType]}.png`,
    ownerId: item.ownerId.toLowerCase(),
  }
};

export const transformMonsterPart = (item) => {
  if (item.salePrice) {
    item.price = item.salePrice.toString();
    item.salePrice = formatPrice(item.salePrice, false);
  }
  return item;
};

const getRating = (clan, users) => {
  if (users.length > 0) {
    let clanUsers = users.filter((u) =>
      clan.participants.map((p) => p[0]).includes(u.accountId)
    );
    const ratingSum = clanUsers
      .map((u) => u.rating)
      .reduce((prev, curr) => prev + curr, 0);

    return parseInt(clan.rating) + ratingSum;
  }
};

export const transformClan = (clan, allUsers = []) => {
  let result = {
    id: clan.id.toString(),
    name: clan.name,
    media: clan.image,
    about: clan.about,
    rating: parseInt(clan.rating),
    entryFee: parseInt(clan.entryFee),
    size: parseInt(clan.size),
    ownerId: clan.ownerId.toLowerCase(),
    blocked: clan.blocked,
    locked: clan.locked,
    participants: clan.participants.map(p => p.toLowerCase()),
  };
  if (allUsers.length > 0) {
    result.rating = getRating(clan, allUsers);
  }

  return result;
};