export const Badge = ({
  text,
  customClassName,
  bgColor,
  borderColor,
  textColor,
}) => (
  <div
    className={`${bgColor} text-white ${
      borderColor ? `border ${borderColor}` : ""
    } ${textColor} text-center text-xs md:w-32 w-20 font-semibold px-2 py-1 rounded ${customClassName}`}
  >
    {text.toUpperCase()}
  </div>
);
