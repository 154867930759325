import React, {useState} from "react";
import S from "../../../assets/styles/clanLine.style";
import {useNavigate} from "react-router-dom";
import {UserIcon, StarIcon, CheckIcon, XIcon} from "@heroicons/react/solid";
import escapeStringRegexp from "escape-string-regexp";
import {Row} from "../../../assets/styles/common.style";
import {LockClosedIcon} from "@heroicons/react/outline";

import attack_icon from "../../../assets/images/attack_icon.png";
import clan_image from "../../../assets/images/clan_image.png";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "../../basic/Button";
import {approveTokenSpend, joinClanCall} from "../../../web3/contracts";
import {addPendingTransaction, addTransactionError, convertFromYocto} from "../../../web3/utils";
import {BigNumber} from "ethers";

export const ClanLine = (
  {
    clan, index, alreadyInClan, battleCount, reloadClanList
  }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const [isReady, setIsReady] = useState(true);
  const navigate = useNavigate();

  const handleClanRemove = async () => {
    if (confirm("Please confirm clan removal. All data and stats will be removed!")) {
      window.contracts.clan.removeClan(clan.id).then(transaction => {
        addPendingTransaction(dispatch, transaction, `Remove Clan "${clan.name}"`);

        transaction.wait().then(receipt => {
          if (receipt.status === 1) {
            reloadClanList();
            navigate("/clans");
          } else {
            console.log(`receipt`, receipt);
          }
        });
      }).catch(err => {
        addTransactionError(dispatch, err.message);
      });
    }
  };

  const handleJoinClan = async () => {
    const fee = BigNumber.from(clan.entryFee.toString());
    if (BigNumber.from(currentUser.tokenBalance).lt(fee)) {
      alert(`You don't have enough ${process.env.ZML_TOKEN} to join this clan!`);
      return false;
    }

    setIsReady(false);
    const allowed = await window.contracts.token.allowance(
      currentUser.accountId,
      window.contracts.clan.address
    );

    if (allowed.lt(fee)) {
      approveTokenSpend(dispatch, window.contracts.token, window.contracts.clan.address, fee).then(() => {
        joinClanCall(dispatch, clan, reloadClanList);
      })
    } else {
      joinClanCall(dispatch, clan, reloadClanList);
    }
  }

  const JoinButton = () => {
    if (clan.ownerId === currentUser.accountId) {
      console.log(`is owner`);
      if (clan.participants.length === 1)
        return (
          <Button
            size={"sm"}
            title={"DELETE"}
            secondary
            icon={<XIcon className="h-5 ml-2"/>}
            onClick={handleClanRemove}
          />
        );
      else
        return <Button size={"sm"} title={"OWNER"} noIcon disabled secondary/>;
    } else if (
      clan.participants.some(
        ([user, _entryFee]) => user === currentUser.accountId
      )
    ) {
      return <Button size={"sm"} title={"JOINED"} noIcon disabled secondary/>;
    }

    if (clan.participants.length < clan.size && !alreadyInClan)
      return (
        <Button
          size={"sm"}
          title={isReady ? "JOIN" : "JOINING..."}
          icon={<CheckIcon className="ml-2 h-5"/>}
          onClick={handleJoinClan}
          disabled={!isReady}
          secondary
        />
      );

    return "";
  };

  return (
    <S.Wrapper
      onClick={() =>
        isReady && navigate(`/clans/${clan.id}?current_tab=Battles`)
      }
    >
      <Row className="flex ml-2 gap-7 w-full lg:justify-start justify-center">
        <section className="flex text-gray-500 w-10">{index > 0 ? `#${index}` : "#"}</section>

        <img
          className="object-cover rounded-full h-12 w-12"
          src={clan.media ?? clan_image}
          onError={({currentTarget}) => {
            currentTarget.onerror = null;
            currentTarget.src = clan_image;
          }}
          alt="Clan"
        />

        <section className="text-left truncate w-22 lg:w-full">
          {escapeStringRegexp(clan.name)}
        </section>
      </Row>
      <Row className="lg:my-0 my-4 max-w-full sm:text-base text-sm">
        <S.Rating isCountGraterThenZero={clan.rating}>
          <div className="text-xs text-gray-400 font-normal">Rating</div>
          <div className="flex items-center">
            <StarIcon className="h-5 mr-1"/>
            {clan.rating}
          </div>
        </S.Rating>
        <S.Rating>
          <div className="text-xs text-gray-400 font-normal">Battles</div>
          <div className="flex items-center">
            <img src={attack_icon} className="h-4 mr-2" alt="battles total"/>
            {battleCount}
          </div>
        </S.Rating>
        <S.Members
          isCountGraterThenZero={
            clan.participants.length > 0 && clan.participants.length < clan.size
          }
        >
          <div className="text-xs text-gray-400 font-normal">Participants</div>
          <div className="flex items-center">
            <UserIcon className="h-5 mr-2 text-gray-400"/>
            {clan.participants.length} / {clan.size}
          </div>
        </S.Members>
        <S.ZMLWrapper>
          <div className="text-xs text-gray-400 font-normal">Entry Fee</div>
          <div className="flex items-center">{`${process.env.ZML_TOKEN} ${convertFromYocto(clan.entryFee, 0, true)}`}</div>
        </S.ZMLWrapper>
      </Row>
      <Row className="lg:ml-5 w-64 items-center lg:justify-start justify-center">
        {clan.locked ? (
          <div className="flex items-center justify-center">
            <div className="border-2 border-gray-500 flex rounded-lg py-2 px-3 items-center justify-center">
              <span className="text-gray-400 text-sm uppercase font-semibold mr-2">
                Locked
              </span>
              <LockClosedIcon className="h-5 w-full text-gray-400"/>
            </div>
          </div>
        ) : (
          <JoinButton/>
        )}
      </Row>
    </S.Wrapper>
  );
};
