import React from "react";
import {getMedia, statusColorTextMap} from "../../web3/utils";
import {BanIcon, CheckCircleIcon} from "@heroicons/react/outline";
import {InventoryClanBattle} from "../../web3/content";

import CardRotate, {Rarity} from "../../assets/styles/cardRotate.style";
import {Button} from "../basic/Button";
import {Price} from "../Price";

export const CardItem = ({
                           isItem,
                           item,
                           size,
                           isSelected,
                           rmFromMarket,
                           handleBuy,
                           handleSelect,
                           noFlip,
                           noText,
                         }) => {
  const itemSize = size || "md";

  const classMapping = {
    xsm: "x-small-square",
    sm: "square-small",
    md: "square",
  };

  return (
    <CardRotate.FlipCardModify
      className={`${classMapping[itemSize]} ${
        noFlip ? "pointer-events-none" : ""
      }`}
    >
      <CardRotate.Front isSelected={isSelected} type={item.level}>
        <section className="absolute z-10 left-0 right-0 bottom-0">
          <Rarity
            type={item.level}
            className={`text-xs ${itemSize !== "md" ? "" : "pl-1"}`}
          >
            <span className={`${statusColorTextMap(item.level)}`}>
                {item.itemType}
              {!noText && item.level ? `- Level ${item.level}` : ""}
              </span>
          </Rarity>
        </section>
        <img
          className={`absolute h-full w-full`}
          src={getMedia(item.media)}
          alt={item.tokenId ? item.tokenId : ""}
        />
        {isItem && !noText && (
          <div className="z-10 absolute left-1 top-1">
            <Price
              title={`${item.durabilityMin ?? "3"} / ${
                item.durabilityMax ?? "3"
              }`}
              token=" "
              size={itemSize}
            />
          </div>
        )}
        {item.salePrice > 0 && !noText && (
          <div className="z-10 absolute right-1 top-1">
            <Price
              title={item.salePrice}
              handleBuy={handleBuy}
              token={process.env.PAYMENT_TOKEN_SYMBOL}
              size={itemSize}
            />
          </div>
        )}
        {isSelected && (
          <div className="top-0 left-0 right-0 bottom-0 z-10 absolute shadow-[0_0_20px_#f97315a8_inset]"/>
        )}
      </CardRotate.Front>

      <CardRotate.Back isSelected={isSelected} type={item.level}>
        <img
          className={`absolute left-0 top-0 opacity-20 h-full w-full scale-x-[-1]`}
          src={getMedia(item.media)}
          alt={item.tokenId ? item.tokenId : ""}
        />

        {!isItem ? (
          <div className="mt-16 font-semibold">
            {item.itemType} +{item.level * 5}
          </div>
        ) : (
          <div
            className={`${itemSize !== "md" ? "text-sm leading-4 pt-1" : "pt-10"}`}
          >
            <b>In Battles:</b>
            <div className="mt-2">
              <small>
                {InventoryClanBattle[item.itemType] && (
                  <>{InventoryClanBattle[item.itemType][item.level || 1]}</>
                )}
              </small>
            </div>
          </div>
        )}

        {itemSize === "md" && (
          <div className="absolute bottom-3 left-3 right-3">
            {item.tokenId > 0 && !item.salePrice && (
              <Button
                title={isSelected ? "Deselect" : "Select"}
                className="w-full"
                secondary
                size="xs"
                icon={
                  isSelected ? (
                    <BanIcon className="h-5 w-5 ml-2 font-semibold"/>
                  ) : (
                    <CheckCircleIcon className="h-5 w-5 ml-2 font-semibold"/>
                  )
                }
                onClick={handleSelect}
              />
            )}

            {handleBuy ? (
              <Button
                title={
                  <>
                    <span className="mr-1">Buy for</span>
                    {item.salePrice} {process.env.PAYMENT_TOKEN_SYMBOL}
                  </>
                }
                size="xs"
                className="w-full"
                noIcon
                onClick={handleBuy}
              />
            ) : (
              item.salePrice > 0 &&
              itemSize === "md" && (
                <Button
                  title="Cancel sell"
                  size="xs"
                  noIcon
                  className="w-full"
                  secondary
                  onClick={rmFromMarket}
                />
              )
            )}
          </div>
        )}
      </CardRotate.Back>
    </CardRotate.FlipCardModify>
  );
};
