import React, {useState, useRef, useEffect, useContext} from "react";
import {secondsToString, timeDiffSeconds} from "../../../web3/timeFormat";
import {ClanDailyRewardResult} from "../ClanDailyRewardResult";
import {SparklesIcon} from "@heroicons/react/outline";
import {useDispatch, useSelector} from "react-redux";
import {Button, iconMapping} from "../../basic/Button";
import {addPendingTransaction} from "../../../web3/utils";

export const RewardButton = ({id, clan}) => {
  const dispatch = useDispatch();
  const funRef = useRef(null);
  const currentUser = useSelector(state => state.user.user);
  const [isTimeReady, setIsTimeReady] = useState(false);
  const [time, setTime] = useState(0);
  const [_, setCurrentDate] = useState(Date.now());
  const [rewardPopupVisible, setRewardPopupVisible] = useState(false);
  const [rewardResult, setRewardResult] = useState([0, [], 0]);

  async function fetchClanDailyTime() {
    let lastClaim = await window.contracts.clan.getLastClaimDailyReward(currentUser.accountId);
    setTime(parseInt(lastClaim) * 1000);
    setIsTimeReady(true);
  }

  useEffect(() => {
    fetchClanDailyTime();
  }, []);

  useEffect(() => {
    funRef.current = setInterval(() => {
      setCurrentDate(Date.now());
    }, 1000);
    return () => {
      clearInterval(funRef.current);
    };
  }, []);

  const cantClaim = () => timeDiffSeconds(time) >= 0 && timeDiffSeconds(time) < 86400;

  const claimMyReward = async () => {
    setIsTimeReady(false);

    const gas = await window.contracts.clan.estimateGas.claimDailyReward();
    console.log(`gas`, parseInt(gas));

    await window.contracts.clan.claimDailyReward({gasLimit: parseInt(gas * 1.1)}).then(transaction => {
      addPendingTransaction(dispatch, transaction, "Claim daily rewards");

      transaction.wait().then(async receipt => {
        if (receipt.status === 1) {
          receipt.events.map(event => {
            if (event.args) {
              const {rewardType, rewardValues, rewardDurability} = event.args;
              const values = rewardValues.map(v => parseInt(v));
              setRewardResult([rewardType, values, rewardDurability]);
              fetchClanDailyTime();
              setRewardPopupVisible(true);
            }
          });
        }
      }).catch(err => {
        console.error(`err`, err);
        alert(`Claim error, please try again later`);
        document.location.reload();
      });
    }).catch(err => {
      console.error(`err`, err);
      alert(`Claim error, please try again later`);
      document.location.reload();
    });
  };

  return (
    <>
      {clan.size < 100 && (
        <>
          <Button
            title={
              isTimeReady
                ? cantClaim()
                  ? `${secondsToString(timeDiffSeconds(time))}`
                  : "CLAIM DAILY REWARDS"
                : "LOADING REWARDS ..."
            }
            disabled={!isTimeReady || cantClaim()}
            size="md"
            icon={<SparklesIcon className={`ml-2 ${iconMapping["md"]}`}/>}
            onClick={claimMyReward}
          />

          <ClanDailyRewardResult
            popupVisible={rewardPopupVisible}
            setPopupVisible={setRewardPopupVisible}
            rewardType={rewardResult[0]}
            rewardValue={rewardResult[1]}
            itemDurability={rewardResult[2]}
          />
        </>
      )}
    </>
  );
};
