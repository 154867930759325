import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      accountId: null,
      tokenBalance: null,
      rating: 0,
      clanId: null,
    }
  },
  reducers: {
    setUserAccountId(state, action) {
      state.user.accountId = action.payload.account.toLowerCase();
    },
    setUserBalance(state, action) {
      state.user.tokenBalance = action.payload.balance;
    },
    setUserRating(state, action) {
      state.user.rating = action.payload.rating;
    },
    updateClanId(state, action) {
      state.user.clanId = action.payload.clanId;
    },
  }
});

export const {setUserAccountId, setUserBalance, setUserRating, updateClanId} = userSlice.actions;
export default userSlice.reducer;