import React, {useEffect, useState} from "react";

import attack_icon from "../../assets/images/attack_icon.png";
import health_icon from "../../assets/images/health_icon.png";
import intellect_icon from "../../assets/images/intelect_icon.png";
import checked_icon from '../../assets/images/checked.png';
import {useDispatch, useSelector} from "react-redux";
import {Popup} from "../../components/Popup";
import {CardItem} from "../../components/cards/CardItem";
import {Pagination} from "../../components/Pagination";
import {Button} from "../../components/basic/Button";
import {Loader} from "../../components/basic/Loader";
import {transformModifier} from "../../web3/transform";
import {addPendingTransaction, addTransactionError} from "../../web3/utils";

const PAGE_LIMIT = 8;

export default function UpgradeZombiePopup(
  {
    modifyPopupVisible,
    setModifyPopupVisible,
    handleModified,
    zombie,
  }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const [isReady, setIsReady] = useState(false);
  const [isModifyLoading, setIsModifyLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userModifiers, setUserModifiers] = useState([0, []]); // [<count>, [<arrayOfZombies>]]
  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const [increaseAttack, setIncreaseAttack] = useState(0);
  const [increaseHealth, setIncreaseHealth] = useState(0);
  const [increaseBrain, setIncreaseBrain] = useState(0);

  useEffect(() => {
    if (modifyPopupVisible) {
      setIsReady(false);
      loadUseModifiers(currentPage);
      setIsModified(false);
      setIsModifyLoading(false);
      setSelectedModifiers([]);
      setIncreaseAttack(0);
      setIncreaseHealth(0);
      setIncreaseBrain(0);
    }
  }, [modifyPopupVisible]);

  const loadUseModifiers = async (page) => {
    const modifierResults = await window.contracts.modifier.userItems(page, PAGE_LIMIT, 0);

    // Convert price from Yocto NEAR
    const modifiers = modifierResults[1].map((item) => transformModifier(item));
    console.log(`modifiers`, modifiers);
    setUserModifiers([parseInt(modifierResults[0]), modifiers]);
    setIsReady(true);
  };

  const onPageChanged = (page) => {
    setCurrentPage(page);
    loadUseModifiers(page);
  };

  const toggleModifyItem = (item) => {
    const index = selectedModifiers.indexOf(item.tokenId);
    const valueDiff = 5 * item.level;

    if (index !== -1) {
      selectedModifiers.splice(index, 1);

      if (item.itemType === "Attack") {
        setIncreaseAttack(increaseAttack - valueDiff);
      } else if (item.itemType === "Health") {
        setIncreaseHealth(increaseHealth - valueDiff);
      } else if (item.itemType === "Brain") {
        setIncreaseBrain(increaseBrain - valueDiff);
      }
    } else {
      if (zombie.modifierItems + selectedModifiers.length >= 3) {
        alert("You can select only 3 mods for zombie");
        return false;
      }

      if (item.itemType === 'Attack') {
        setIncreaseAttack(increaseAttack + valueDiff);
      } else if (item.itemType === 'Health') {
        setIncreaseHealth(increaseHealth + valueDiff);
      } else if (item.itemType === 'Brain') {
        setIncreaseBrain(increaseBrain + valueDiff);
      }
      selectedModifiers.push(item.tokenId);
    }
    setSelectedModifiers([...selectedModifiers]);
  };

  const upgradeZombie = async () => {
    setIsModifyLoading(true);
    try {
      await window.contracts.zombie.modifyZombie(zombie.tokenId, selectedModifiers).then(transaction => {
        addPendingTransaction(dispatch, transaction, "Upgrade Zombie");

        transaction.wait().then(receipt => {
          if (receipt.status === 1) {
            handleModified();
            setIsModified(true);
            setIsModifyLoading(false);
          }
        });
      }).catch(err => {
        addTransactionError(dispatch, err.message);
        setIsModifyLoading(false);
      });
    } catch (e) {
      console.log(`Error`, e);
    }
  };

  const isSelected = (id) => selectedModifiers.includes(id);

  const canUseModifier = (item) => {
    return (zombie.modifierItems + selectedModifiers.length >= 3) || isSelected(item.tokenId);
  }

  const ModifyCharacteristic = (
    {
      title,
      value,
      icon,
      increase,
      isModified,
      modifiedValue
    }) => (
    <div className={`md:mx-2 mb-2 mx-auto flex border rounded-md py-1 px-4 w-48 text-left 
    ${isModified ? "border-orange-500 bg-orange-500/20" : "border-white/10 bg-white/10"}`}>
      <div>
        <img src={icon} alt="attack" className="inline mr-4 mt-3 h-7 w-7 align-middle"/>
      </div>
      <div>
        <div className="text-lg">
          {title}:
          <span className="ml-2 font-semibold">{value}</span>
          {increase > 0 && (
            <span className="font-semibold text-orange-400 ml-1">+{increase}</span>
          )}
        </div>
        <div className={`text-sm ${isModified ? "text-orange-400" : "text-white/50"}`}>
          {isModified ? `modified ${modifiedValue > 0 ? "+" + modifiedValue : ""}` : "not modified"}
        </div>
      </div>
    </div>
  );

  return (
    <Popup
      title={`Upgrade Zombie #${zombie.tokenId}`}
      popupVisible={modifyPopupVisible}
      setPopupVisible={setModifyPopupVisible}
      width={`sm:w-[800px]`}
    >
      <div className="text-left">
        {isReady ? (
          <>
            {userModifiers[0] > 0 ? (
              <div>
                <div className={"text-center font-semibold mb-4 text-orange-400"}>
                  {zombie.modifierItems + selectedModifiers.length}/3 modifiers assigned
                </div>
                <div className="text-center md:flex justify-center pb-4 mb-4 border-b-2 border-white/10">
                  <ModifyCharacteristic title="Attack"
                                        icon={attack_icon}
                                        value={zombie.attack}
                                        increase={increaseAttack}
                                        isModified={increaseAttack > 0}/>
                  <ModifyCharacteristic title="Health"
                                        icon={health_icon}
                                        value={zombie.health}
                                        increase={increaseHealth}
                                        isModified={increaseHealth > 0}/>
                  <ModifyCharacteristic title="Brain"
                                        icon={intellect_icon}
                                        value={zombie.brain}
                                        increase={increaseBrain}
                                        isModified={increaseBrain > 0}/>
                </div>

                {!isModified ? (
                  <>
                    <p className="text-center mt-4 mb-8">
                      Select up to 3 modifiers for your zombie. Modification can't be reverted after assign.
                    </p>
                    <div className="mb-4">
                      <div className="flex flex-wrap md:gap-4 gap-1 justify-center max-h-[300px] overflow-y-auto">
                        {userModifiers[1].map(item => (
                          <div className={`relative ${canUseModifier(item) ? "opacity-50" : ""}`}
                               key={item.tokenId}
                               onClick={() => toggleModifyItem(item)}>
                            <CardItem item={item}
                                      noFlip
                                      size="sm"
                                      isSelected={isSelected(item.tokenId)}
                            />
                            {isSelected(item.tokenId) && (
                              <img src={checked_icon} alt="" className="w-4 h-4 absolute right-2 top-2 mt-1 mr-1"/>
                            )}
                          </div>
                        ))}
                      </div>

                      {userModifiers[0] > PAGE_LIMIT && (
                        <div className="text-center">
                          <Pagination
                            total={parseInt(userModifiers[0])}
                            limit={PAGE_LIMIT}
                            selectedPage={currentPage}
                            onPageChanged={onPageChanged}
                          />
                        </div>
                      )}
                    </div>

                    {!isModifyLoading ? (
                      <div className="mt-6 text-center h-10">
                        {selectedModifiers.length > 0 && (
                          <Button
                            title="UPGRADE"
                            onClick={() => upgradeZombie()}
                          />
                        )}
                      </div>
                    ) : (
                      <Loader/>
                    )}
                  </>
                ) : (
                  <div className="text-lg text-center font-semibold my-10">
                    <p>Zombie has been succesfully upgraded!</p>
                    <div className="text-center mt-4">
                      <Button title="Close" secondary noIcon onClick={() => setModifyPopupVisible(false)}/>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <p className="text-center py-6">
                *You don't have modifier items.
              </p>
            )}
          </>
        ) : (
          <Loader/>
        )}
      </div>
    </Popup>
  );
}
