import React, {useContext} from "react";
import {
  addPendingTransaction,
  convertFromYocto,
  convertToYocto,
  getMedia,
  statusColorBorderMap,
} from "../../web3/utils";
import {XIcon} from "@heroicons/react/outline";
import {Popup} from "../Popup";
import {Button} from "../basic/Button";
import {useDispatch} from "react-redux";

export const SellNftPopup = ({
                               sellPopupVisible,
                               setSellPopupVisible,
                               nftList,
                               nftType,
                               cancelSell,
                               handleReloadData
                             }) => {
  const dispatch = useDispatch();

  const createSaleObject = () => {
    let sellObject = {};
    let isError = false;
    nftList
      .filter((_, index) => index < 10)
      .forEach((item) => {
        if (item.salePrice > 0) {
          sellObject[item.tokenId] = convertToYocto(item.salePrice, false);
        } else {
          isError = true;
        }
      });
    return [isError, sellObject];
  };

  const handleSell = async () => {
    const [isError, sellObject] = createSaleObject();
    if (!isError) {
      console.log(`sellObject`, sellObject);
      console.log('publishOnMarket call');
      console.log(`nftType`, nftType);
      // await mainContract.publishOnMarket(sellObject, nftType).then(() => {
      //   setSellPopupVisible(false);
      //   handleReloadData();
      // });
      await window.contracts.market.publishOnMarket(
        Object.keys(sellObject),
        Object.values(sellObject),
        nftType.toLowerCase()
      ).then(transaction => {
        addPendingTransaction(dispatch, transaction, `Sell ${nftType}s`);
        // dispatch(cleanupSaleList({type: `${nftType}s`}));

        transaction.wait().then(receipt => {
          if (receipt.status === 1) {
            // Todo: Replace it to update component
            // document.location.reload();
            handleReloadData();
            setSellPopupVisible(false);
          }
        });
      });
    } else {
      alert(`Please, provide sale price for all ${nftType}s`);
    }
  };

  const buttonText = () => {
    let countSell = nftList.length > 10 ? 10 : nftList.length;
    return `Sell ${countSell > 1 ? countSell : ""} ${nftType}${countSell > 1 ? "s" : ""}`;
  };

  const SellItem = ({nft}) => {
    const [priceInput, setPriceInput] = React.useState("");

    return (
      <div
        className="border-mainSkyBlue/80 bg-mainSkyBlue/30 flex justify-between items-start px-3 py-2 border-2 rounded-md">
        <img
          className={`w-16 border-2 rounded-md mr-4 ${statusColorBorderMap(
            nft.cardRarity
          )}`}
          src={getMedia(nft.media)}
          alt={nft.tokenId}
        />
        <div className="text-left w-40">
          <>
            <div className="font-semibold mb-2">
              {nftType === 'Inventory' ? (
                <>{nft.itemType} #{nft.tokenId}</>
              ) : (
                <>{nft.cardRarity || nftType} #{nft.tokenId}</>
              )}
            </div>
            {(nftType === "Zombie" || nftType === "Monster") ? (
              <div className="text-xs opacity-70 mb-1">
                {convertFromYocto(nft.killTokens, 1, true)} {process.env.ZML_TOKEN}
              </div>
            ) : (
              <div className={"text-xs opacity-70 mb-0.5 mt-3"}>Sell price:</div>
            )}
          </>
          <input
            type="number"
            max="10000"
            min={"0.01"}
            step={"0.01"}
            placeholder="Price"
            value={priceInput}
            onChange={(e) => {
              const price = e.target.value;
              setPriceInput(price);
              nft.salePrice = price;
            }}
            className="w-16 md:w-20 px-2 py-1 text-sm rounded-md bg-transparent border-2 border-gray-600 mr-1 focus:outline-none focus:border-gray-500"
          />{" "}
          {process.env.PAYMENT_TOKEN_SYMBOL}
        </div>
        <XIcon
          className="h-6 w-6 hover:text-red-700 cursor-pointer transition duration-200"
          onClick={() => cancelSell(nft)}
        />
      </div>
    );
  };

  return (
    <Popup
      title={`Sell ${nftType}${nftList.length > 1 ? "s" : ""}`}
      popupVisible={sellPopupVisible}
      setPopupVisible={setSellPopupVisible}
    >
      <div className="mt-2 mx-auto text-center">
        <div className="flex flex-wrap w-full justify-center mb-6 gap-4">
          {nftList
            .filter((_, index) => index < 10)
            .map((nft) => (
              <SellItem nft={nft} key={nft.tokenId}/>
            ))}
        </div>

        {nftList.length > 10 && (
          <p className="text-center text-sm my-4 text-red-400">
            *NOTE: Sell limited by 10 items per transaction.
          </p>
        )}

        <Button title={buttonText()} onClick={handleSell}/>
      </div>
    </Popup>
  );
};
