import React, { useEffect, useRef, useState } from "react";
import { RUN_EVERY } from "../../server/api/config/roulette";

export const RouletteTimer = ({ setRefreshTimer, setRefreshNumber }) => {
  const [currentDate, setCurrentDate] = useState("-:-");
  const funRef = useRef(null);

  const countdown = () => {
    const dt = new Date();
    let m = dt.getMinutes();
    let s = dt.getSeconds();

    // minutes remaining until next RUN_EVERY minute mark
    m = RUN_EVERY - (m % RUN_EVERY);
    // seconds remaining until next minute mark
    if (s) {
      m = m - 1;
      s = 60 - s;
    }

    setRefreshNumber(
      ((m === RUN_EVERY - 1 && s > 55) || (m === 0 && s < 1)) && true
    );
    setRefreshTimer(m === RUN_EVERY - 1 && s === 55 && true);

    return `${m}:${s < 10 ? "0" + s : s}`;
  };

  useEffect(() => {
    funRef.current = setInterval(() => {
      setCurrentDate(countdown());
    }, 1000);
    return () => {
      clearInterval(funRef.current);
    };
  }, []);

  useEffect(() => {}, [currentDate]);

  return (
    <>
      <div>
        <span className="font-semibold text-xl">Next Roll</span>
        <span className="ml-2 font-semibold text-orange-500 text-xl">
          {currentDate}
        </span>
      </div>
    </>
  );
};
