import ky from 'ky';
import {setUserRating, updateClanId} from "../store/userSlice";

const BASE_URL = process.env.API_URL;

// Fetch GET method
export const getRequest = async (url, params) => {
  if (params) {
    const queryParams = new URLSearchParams(params);
    url = `${url}?${queryParams}`;
  }

  return await ky.get(`${BASE_URL}/${url}`, {
    timeout: 10000,
    limit: 3,
    methods: ['get'],
  }).json();
};

// Fetch POST method
export const postRequest = async (url, body, timeoutSec = 30) => {
  return await ky.post(`${BASE_URL}/${url}`, {
    json: body,
    timeout: timeoutSec * 1000,
  }).json();
};

export const updateUserRating = async (dispatch, currentUser) => {
  try {
    const response = await getRequest("api/users/current", {currentUser});
    if (response.data) {
      dispatch(setUserRating({rating: response.data.rating}));
      dispatch(updateClanId({clanId: response.data.clanId}));
    }
  } catch (e) {
    console.log(`e`, e);
  }
}