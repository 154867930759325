import React, {useEffect, useState} from "react";
import {Doughnut, Bar, Line} from "react-chartjs-2";
import {StatsContent} from "../web3/content";
import {convertFromYocto} from "../web3/utils";
import {getRequest} from "../web3/api";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title, PointElement, LineElement,
} from "chart.js";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../assets/styles/common.style";
import {Header} from "../components/Header";
import {InnerPageHead} from "../components/InnerPageHead";
import {Loader} from "../components/basic/Loader";
import {Footer} from "../components/Footer";
import {chartDateFormat} from "../web3/timeFormat";

const TOTAL_ZML_BALANCE = 320000000;

export const Stats = () => {
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    mintedZombies: 0,
    killedZombies: 0,
    mintedMonsters: 0,
    killedMonsters: 0,
  });
  const [usersActivity, setUsersActivity] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [isLandsReady, setIsLandsReady] = useState(false);
  const [dataChart, setDataChart] = useState([]);

  const getStatsZombies = new Promise(async (resolve, reject) => {
    await contracts.zombie.leaderboardStats().then((stat) => {
      resolve(stat);
    });
  });

  const getStatsMonsters = new Promise(async (resolve, reject) => {
    await contracts.monster.leaderboardStats().then((stat) => {
      resolve(stat);
    });
  });

  async function fetchGeneralStats() {
    setIsReady(false);

    const respUsers = await getRequest("api/users/count-all");
    const respActive = await getRequest("api/users/count-active");
    const totalStake = await contracts.token.stakingTotalSupply();
    const availableTokens = await contracts.token.balanceOf(contracts.main.address);
    const claimed = parseInt(TOTAL_ZML_BALANCE - parseInt(convertFromYocto(availableTokens, true)));
    const volumeResp = await getRequest("api/stats/volume");

    Promise.all([getStatsZombies, getStatsMonsters]).then((values) => {
      const zombie = values[0];
      const monster = values[1];
      setStats({
        totalUsers: respUsers.data,
        activeUsers: respActive.data,
        staked: parseInt(convertFromYocto(totalStake, true)),
        claimed: claimed,
        volume: volumeResp.data,
        mintedZombies: parseInt(zombie[0]),
        killedZombies: parseInt(zombie[1]),
        mintedMonsters: parseInt(monster[0]),
        killedMonsters: parseInt(monster[1]),
      });
      setIsReady(true);
    });
  }
  
  async function fetchUsersActivityStats() {
    const respUsers = await getRequest("api/users/activity-stats");
    setUsersActivity(respUsers.data.reverse());
  }

  const landMintedCount = (landType) => new Promise(async resolve => {
    window.contracts.land.landTypeCount(landType).then(result => {
      resolve(parseInt(result));
    });
  });

  async function fetchCharts() {
    const allLandsObj = await window.contracts.land.getAllLands();

    let data = [];
    Promise.all([
      landMintedCount(0),
      landMintedCount(1),
      landMintedCount(2),
      landMintedCount(3)
    ]).then(landsCount => {
      allLandsObj.map((land, index) => {
        const available = parseInt(land.limitCount) - landsCount[index];
        data[index] = {
          labels: ["Minted", "Available"],
          datasets: [
            {
              data: [landsCount[index], available],
              backgroundColor: [
                "rgba(54, 162, 235, 0.2)",
                "rgba(0, 247, 87, 0.2)",
              ],
              borderColor: ["rgba(54, 162, 235, 1)", "rgba(0, 247, 87, 1)"],
              borderWidth: 2,
              borderRadius: 4,
            },
          ],
        };
      });

      setDataChart(data);
      setIsLandsReady(true);
    });
  }

  useEffect(() => {
    Legend.defaults.display = false;
    ChartJS.register(
      ArcElement,
      Tooltip,
      Legend,
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      PointElement,
      LineElement,
    );
    fetchGeneralStats();
    fetchUsersActivityStats();
    fetchCharts();
  }, []);

  const LandChart = ({title, data, label = true}) => (
    <div className="text-center mb-14 lg:mb-2">
      <Doughnut data={data} type="doughnut"/>
      <div className="mt-4 text-lg font-semibold">{title}</div>
      {data && label && (
        <div className="text-white/50 font-bold text-sm">
          Minted: {data["datasets"][0]["data"][0]} /{" "}
          {data["datasets"][0]["data"][1] + data["datasets"][0]["data"][0]}
        </div>
      )}
    </div>
  );

  return (
    <InnerPageWrapper>
      <Header/>

      <Wrapper>
        <Container className="flex flex-col text-white mt-6 items-center">
          <InnerPageHead title={StatsContent.title}/>
          {isReady ? (
            <>
              <div className="lg:flex lg:flex-row">
                <div className="flex flex-col">
                  <div className="lg:flex lg:flex-row text-center">
                    <div className="m-3 bg-main/80 border-2 border-mainLight rounded-lg p-5 h-fit w-60">
                      <div>Registered Players</div>
                      <div className="mt-3 text-orange-400 font-bold text-4xl uppercase">
                        {parseInt(stats.totalUsers)}
                      </div>
                    </div>
                    <div className="m-3 bg-main/80 border-2 border-mainLight rounded-lg p-5 h-fit w-60">
                      <div>Active Players <small>(30 days)</small></div>
                      <div className="mt-3 text-green-400 font-bold text-4xl uppercase">
                        {parseInt(stats.activeUsers)}
                      </div>
                    </div>
                  </div>

                  <div className="lg:flex lg:flex-row text-center">
                    <div className="m-3 flex flex-col items-center bg-main/80 border-2 border-mainLight rounded-lg p-3 h-fit w-60">
                      <div className="font-bold text-xl my-2">Zombies</div>
                      <div className="m-3 flex flex-row gap-x-10">
                        <div className="flex flex-col">
                          <div className="text-sm">Minted</div>
                          <div className="mt-1 text-blue-400 font-bold text-xl uppercase">
                            {parseInt(stats.mintedZombies)}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-sm">Killed</div>
                          <div className="mt-1 text-red-400 font-bold text-xl uppercase">
                            {parseInt(stats.killedZombies)}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="m-3 flex flex-col items-center bg-main/80 border-2 border-mainLight rounded-lg p-3 h-fit w-60">
                      <div className="font-bold text-xl my-2">Monsters</div>
                      <div className="m-3 flex flex-row gap-x-10">
                        <div className="flex flex-col">
                          <div className="text-sm">Minted</div>
                          <div className="mt-1 text-blue-400 font-bold text-xl uppercase">
                            {parseInt(stats.mintedMonsters)}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-sm">Killed</div>
                          <div className="mt-1 text-red-400 font-bold text-xl uppercase">
                            {parseInt(stats.killedMonsters)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lg:flex lg:flex-row text-center">
                  <div className="m-3 flex flex-col items-center bg-main/80 border-2 border-mainLight rounded-lg p-3 px-5 h-hull w-60">
                    <h3 className="mb-3">Ecosystem token usage</h3>
                    {stats && (
                      <div className="h-full w-full">
                        <LandChart
                          label={false}
                          data={{
                            labels: ["In Staking", "Earned", "Total"],
                            datasets: [
                              {
                                data: [
                                  stats.staked,
                                  stats.claimed,
                                  TOTAL_ZML_BALANCE,
                                ],
                                backgroundColor: [
                                  "rgba(54, 162, 235, 0.2)",
                                  "rgba(255, 145, 0, 0.2)",
                                  "rgba(48, 41, 148, 0.2)",
                                ],
                                borderColor: [
                                  "rgba(54, 162, 235, 1)",
                                  "rgba(255, 145, 0, 1)",
                                  "rgba(48, 41, 148, 1)",
                                ],
                                borderWidth: 2,
                                borderRadius: 4,
                              },
                            ],
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {usersActivity && usersActivity.length > 1 && (
                <>
                  <h3 className="text-xl font-semibold uppercase  mt-10 mb-10">
                    Daily Active users (90 days)
                  </h3>

                  <div className="lg:flex lg:flex-row h-fit w-2/3 mb-3">
                    <Line
                      options={{
                        responsive: true,
                      }}
                      data={{
                        labels: usersActivity.map(
                          (v) => chartDateFormat(v.date)
                        ),
                        datasets: [
                          {
                            label: 'DAU',
                            data: usersActivity.map((v) => v.usersCount),
                            backgroundColor: "rgba(54, 162, 235, 1)",
                            borderColor: "rgba(54, 162, 235, 0.5)",
                          },
                        ],
                      }}
                    />
                  </div>
                </>
              )}

              {stats.volume && stats.volume.length > 1 && (
                <>
                  <h3 className="text-xl font-semibold uppercase  mt-10 mb-8">
                    In-game Market Statistic
                  </h3>

                  <div className="lg:flex lg:flex-row h-fit w-2/3 mb-3">
                    <Bar
                      options={{
                        plugins: {
                          title: {
                            display: true,
                            text: `Total Volume (${process.env.PAYMENT_TOKEN_SYMBOL})`,
                          },
                        },
                        responsive: true,
                      }}
                      data={{
                        labels: stats.volume.map(
                          (v) => `${v.month + 1}/${v.year}`
                        ),
                        datasets: [
                          {
                            label: process.env.PAYMENT_TOKEN_SYMBOL,
                            data: stats.volume.map((v) => v.total),
                            backgroundColor: "rgba(54, 162, 235, 1)",
                            borderRadius: 4,
                          },
                        ],
                      }}
                    />
                  </div>
                </>
              )}

              <h3 className="text-xl font-semibold uppercase mt-10 mb-6">
                Lands Availability
              </h3>
              {isLandsReady ? (
                <div className="lg:flex lg:flex-row lg:gap-10 w-2/3 justify-center">
                  <div className="h-full w-48">
                    <LandChart title="Micro Land" data={dataChart[0]}/>
                  </div>
                  <div className="h-full w-48">
                    <LandChart title="Small Land" data={dataChart[1]}/>
                  </div>
                  <div className="h-full w-48">
                    <LandChart title="Medium Land" data={dataChart[2]}/>
                  </div>
                  <div className="h-full w-48">
                    <LandChart title="Large Land" data={dataChart[3]}/>
                  </div>
                </div>
              ) : (
                <Loader/>
              )}
            </>
          ) : (
            <Loader/>
          )}
        </Container>
      </Wrapper>

      <Footer/>
    </InnerPageWrapper>
  );
};
