import React, {useEffect, useMemo, useState} from "react";
import {inventoryOptions, inventoryTypes} from "../../web3/utils";
import {getRequest} from "../../web3/api";
import {Popup} from "../../components/Popup";
import {Loader} from "../../components/basic/Loader";
import {Dropdown} from "../../components/basic/Dropdown";
import {CardItem} from "../../components/cards/CardItem";
import {Pagination} from "../../components/Pagination";
import {transformItem} from "../../web3/transform";

const INVENTORY_PAGE_LIMIT = 8;

export const SelectInventoryPopup = (
  {
    title,
    inventoryPopupVisible,
    setInventoryPopupVisible,
    selectedInventory,
    selectInventory,
  }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentItemPage, setCurrentItemPage] = useState(1);
  const [userInventory, setUserInventory] = useState([0, []]);
  const [filterType, setFilterType] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    if (inventoryPopupVisible) {
      fetchUserInventory(currentItemPage);
    }
  }, [inventoryPopupVisible]);

  useEffect(() => {
    if (inventoryPopupVisible) {
      setCurrentItemPage(1);
      fetchUserInventory(1);
    }
  }, [filterType]);

  async function fetchUserInventory(currentPage) {
    const itemsResponse = await window.contracts.inventory.userItems(currentPage, INVENTORY_PAGE_LIMIT, filterType);
    const idList = itemsResponse[1].filter(item => item.nftType > 0).map((item) => parseInt(item.tokenId));
    const itemsResp = await getRequest("api/items", {
      ids: idList,
    });
    let itemsDB = itemsResp.data;

    if (itemsDB) {
      // Convert price from wei and add durability
      const items = itemsResponse[1]
        .map((item) => {
          let itemDB = itemsDB.find((i) => i.itemId === parseInt(item.tokenId));
          let transformedItem = transformItem(item);

          transformedItem.durabilityMin = itemDB?.durabilityMin;
          transformedItem.durabilityMax = itemDB?.durabilityMax;
          transformedItem.level = itemDB?.level;

          return transformedItem;
        })
        .sort((a, b) => a.itemType.localeCompare(b.itemType));

      setUserInventory([parseInt(itemsResponse[0]), items]);
    }

    setIsLoading(false);
  }

  const onPageItemChanged = (page) => {
    window.scrollTo({top: 0, behavior: "smooth"});
    setCurrentItemPage(page);
    fetchUserInventory(page);
  };

  const inventoryInPopup = useMemo(() => {
    if (inventoryPopupVisible && userInventory) {
      return userInventory[1].filter((item) => {
        let exists = false;
        selectedInventory.map((innerItem) => {
          if (innerItem && innerItem.tokenId === item.tokenId) {
            exists = true;
          }
        });
        return !exists;
      });
    }
    return [];
  }, [userInventory, selectedInventory]);

  return (
    <Popup
      title={title}
      popupVisible={inventoryPopupVisible}
      width="lg:w-[900px]"
      setPopupVisible={setInventoryPopupVisible}
    >
      <div className="sm:mt-2 min-h-[250px] sm:px-6">
        {isLoading ? (
          <div className={"mx-auto w-8 pt-2"}>
            <Loader/>
          </div>
        ) : (
          <>
            <div className={"absolute z-10 right-24 top-8"}>
              <Dropdown
                title="Item Type"
                selected={inventoryTypes[filterType - 1]}
                options={inventoryOptions(setFilterType)}
              />
            </div>

            {userInventory[0] > 0 ? (
              <>
                <div className="md:flex md:flex-row gap-4 justify-center md:flex-wrap min-h-[250px]">
                  {inventoryInPopup.length === 0 ? (
                    <>Inventory not found.</>
                  ) : (
                    <>
                      {inventoryInPopup.map((item) => (
                        <div
                          className="w-32 mb-1 cursor-pointer"
                          key={item.tokenId}
                          onClick={() => selectInventory(item)}
                        >
                          <CardItem isItem item={item} key={item.tokenId} size="sm"/>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </>
            ) : (
              <p>You don't have {filterType ? filterType : "inventory"} items</p>
            )}

            {userInventory[0] > 1 && (
              <div className="text-center">
                <Pagination
                  total={parseInt(userInventory[0])}
                  limit={INVENTORY_PAGE_LIMIT}
                  selectedPage={currentItemPage}
                  onPageChanged={onPageItemChanged}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Popup>
  )
}
