import React, {useEffect, useState} from "react";
import S from "../../assets/styles/clanView.style";
import {statusColorBgMap} from "../../web3/utils";
import {RequestClanBattlePopup} from "../../components/clan/RequestClanBattlePopup";
import {BASIC_STAKE, LEVEL_MAP} from "../../web3/config";
import {BattleZombiesPopup} from "../../components/clan/BattleZombiesPopup";
import clanImage from "../../assets/images/clan_image.png";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getRequest, postRequest} from "../../web3/api";
import {Loader} from "../../components/basic/Loader";
import {Badge} from "../../components/basic/Badge";
import {BoltIcon} from "../../components/basic/BoltIcon";
import {Button} from "../../components/basic/Button";
import {updateUserBalance} from "../../web3/contracts";
import {transformClan} from "../../web3/transform";

export const ClanBattles = ({clan, alreadyInClan}) => {
  const currentUser = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [battles, setBattles] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [battleRequestVisible, setBattleRequestVisible] = useState();
  const [pendingBattles, setPendingBattles] = useState([]);
  const [myZombiesBattle, setMyZombiesBattle] = useState();
  const [showMyZombiesPopup, setShowMyZombiesPopup] = useState(false);

  const fetchBattles = async () => {
    const response = await getRequest("api/clan-battle/all", {
      clanId: clan.id,
      accountId: currentUser.accountId,
    });

    let pendingBattles = localStorage.getItem("pending_join_battles");
    if (pendingBattles) {
      setPendingBattles(JSON.parse(pendingBattles));
      setTimeout(() => {
        localStorage.removeItem("pending_join_battles");
        fetchBattles().then(() => {
          setPendingBattles([]);
        });
      }, 10000);
    }

    if (response.error) {
      alert(response.error);
    }

    setBattles(response.data);
    setIsReady(true);
  };

  const isJoined = (battle) => {
    return (
      battle.fromClan.participants.some(
        (user) => user.accountId === currentUser.accountId
      ) ||
      battle.toClan.participants.some((user) => user.accountId === currentUser.accountId)
    );
  };

  const canUserJoin = (battle) => {
    if (battle.requiredSize === 6) {
      return !isJoined(battle);
    }
    return true;
  };

  const canCancelBattle = (battle) => {
    const diffSeconds = (new Date() - Date.parse(battle.createdAt)) / 1000;
    return diffSeconds > 3 * 24 * 60 * 60;
  };

  const cancelBattle = async (battle) => {
    setIsReady(false);
    const response = await postRequest(
      "api/clan-battle/cancel",
      {
        clanBattleId: battle.id,
      },
      60
    );

    if (response.error) {
      alert(response.error);
    }
    reloadBattlesOnUpdate();
  };

  const reloadBattlesOnUpdate = () => {
    setTimeout(() => {
      fetchBattles();
      setTimeout(() => {
        fetchBattles();
        updateUserBalance(dispatch, currentUser.accountId);
      }, 7000);
    }, 3000);
  }

  const leaveBattle = async (battle) => {
    setIsReady(false);
    const response = await postRequest(
      "api/clan-battle/leave",
      {
        accountId: currentUser.accountId,
        clanBattleId: battle.id,
      },
      60
    );

    if (response.error) {
      alert(response.error);
    }
    reloadBattlesOnUpdate();
  };

  const successJoinedBattle = async () => {
    updateUserBalance(dispatch, currentUser.accountId);

    window.contracts.clan.getClanByAccountId(currentUser.accountId).then(([userClanResponse, exists]) => {
      const userClan = transformClan(userClanResponse);
      if (exists && clan.id !== userClan.id) {
        navigate(`/clans/${userClan.id}`);
      } else {
        setTimeout(() => {
          fetchBattles();
        }, 2000);
        setTimeout(() => {
          fetchBattles();
        }, 10000);
      }
    });
  };

  const availableToJoin = (battle) => {
    if (battle.fromClan.id === clan.id && alreadyInClan)
      return battle.fromClan.participants.length < battle.requiredSize / 2;

    if (battle.toClan.id === clan.id && alreadyInClan)
      return battle.toClan.participants.length < battle.requiredSize / 2;
  };

  useEffect(() => {
    setIsReady(false);
    fetchBattles();
  }, [!battleRequestVisible]);

  const otherClanImage = (battle) => {
    if (clan.id === battle.fromClan.id) {
      return battle.toClan.media ?? clanImage;
    }
    return battle.fromClan.media ?? clanImage;
  };

  if (!isReady) {
    return <Loader/>;
  }

  return (
    <>
      <S.SectionWrapper className="w-full">
        <S.Table.Header className="hidden md:flex px-3">
          <S.Table.ItemColumn className="flex w-24">
            <b>ID</b>
          </S.Table.ItemColumn>
          <S.Table.ItemColumn className="flex w-1/2 justify-start">
            <b>Clan vs Clan</b>
          </S.Table.ItemColumn>
          <S.Table.ItemColumn className="flex w-1/2 justify-center">
            <S.Table.ItemColumn className="w-40">
              <b>Rarity</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="w-40">
              <b>Total win</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="w-40">
              <b>Power limit</b>
            </S.Table.ItemColumn>
          </S.Table.ItemColumn>
          <S.Table.ItemColumn className="flex w-1/3 justify-end">
            <b>Actions</b>
          </S.Table.ItemColumn>
        </S.Table.Header>

        <S.Table.Header className="flex md:hidden px-2">
          <S.Table.ItemColumn className="w-10">
            <b>Rarity</b>
          </S.Table.ItemColumn>
          <S.Table.ItemColumn className="w-20">
            <b>Total win</b>
          </S.Table.ItemColumn>
          <S.Table.ItemColumn>
            <b>Power</b>
          </S.Table.ItemColumn>
          <S.Table.ItemColumn className="flex justify-end">
            <b>Actions</b>
          </S.Table.ItemColumn>
        </S.Table.Header>
        {battles
          ?.filter((b) => !b.winnerClanId)
          ?.map((battle, index) => (
            <S.Table.Content
              key={index}
              className={"one-battle rounded-lg p-1 md:px-3"}
            >
              <S.Table.ItemColumn className="w-24 hidden md:flex text-sm">
                #{battle.id}
              </S.Table.ItemColumn>
              <S.Table.ItemColumn className="hidden md:flex md:w-1/2 justify-start">
                <div className="flex flex-row items-center w-32 relative">
                  <img
                    alt="our clan"
                    src={clan.media ?? clanImage}
                    onError={({currentTarget}) => {
                      currentTarget.onerror = null;
                      currentTarget.src = clanImage;
                    }}
                    className="h-14 w-14 mr-1 rounded-xl object-cover absolute -left-1 -top-1 z-0 opacity-50"
                  />
                  <img
                    alt="opponent clan"
                    src={otherClanImage(battle)}
                    onError={({currentTarget}) => {
                      currentTarget.onerror = null;
                      currentTarget.src = clanImage;
                    }}
                    className="h-14 w-14 mr-1 rounded-xl object-cover relative z-10 border-2 border-mainLight"
                  />
                </div>
                <div className="flex w-full flex-col gap-y-2 items-center leading-3 md:leading-5">
                  <div className="w-full">
                    <div className="font-semibold flex flex-row w-full justify-between">
                      <div>
                        <Link
                          className="text-lg"
                          to={`/clans/${battle.fromClan.id}`}
                        >
                          {battle.fromClan.name}
                        </Link>
                      </div>
                      <div
                        className={`text-sm items-center flex gap-x-1 ${
                          battle.fromClan.participants.length ===
                          battle.requiredSize / 2
                            ? "text-green-500"
                            : "text-indigo-300"
                        }`}
                      >
                        <div>{battle.fromClan.participants.length}</div>
                        {" / "}
                        <div>{battle.requiredSize / 2}</div>
                      </div>
                    </div>
                    <div className="font-semibold flex flex-row w-full justify-between">
                      <div>
                        <Link
                          className="text-lg"
                          to={`/clans/${battle.toClan.id}`}
                        >
                          {battle.toClan.name}
                        </Link>
                      </div>

                      <div
                        className={`text-sm items-center flex gap-x-1 ${
                          battle.toClan.participants.length ===
                          battle.requiredSize / 2
                            ? "text-green-500"
                            : "text-indigo-300"
                        }`}
                      >
                        <div>{battle.toClan.participants.length}</div>
                        {" / "}
                        <div>{battle.requiredSize / 2}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <S.Table.ItemColumn className="w-12 mx-3 text-orange-500">
                  {isJoined(battle) && (
                    <>
                      {/*<ClockIcon className="text-orange-500 w-6 h-6"/>*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        onClick={() => {
                          setMyZombiesBattle(battle);
                          setShowMyZombiesPopup(true);
                        }}
                        className={
                          "w-7 h-7 fill-current hover:opacity-90 cursor-pointer"
                        }
                      >
                        <g>
                          <path d="M12 10c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1s1-.4 1-1v-5c0-.6-.4-1-1-1z"/>
                          <circle cx="12" cy="8" r="1"/>
                          <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"/>
                        </g>
                      </svg>
                    </>
                  )}
                </S.Table.ItemColumn>
              </S.Table.ItemColumn>
              <S.Table.ItemColumn className="flex w-full w-1/3 md:w-1/2 justify-center">
                <S.Table.ItemColumn className="w-full md:w-40">
                  <Badge
                    text={`${battle.requiredSize / 2}x${
                      battle.requiredSize / 2
                    } ${battle.cardRarity}`}
                    bgColor={statusColorBgMap(battle.cardRarity)}
                  />
                </S.Table.ItemColumn>
                <S.Table.ItemColumn className="w-full pl-2 md:pl-0 md:w-40">
                  <div className="text-orange-500 w-32 flex whitespace-nowrap font-bold md:text-lg">
                    {`${
                      battle.entryFee +
                      BASIC_STAKE[battle.cardRarity].prize[battle.level]
                    }`}{" "}
                    <span className="hidden md:flex ml-2">{process.env.ZML_TOKEN}</span>
                  </div>
                </S.Table.ItemColumn>
                <S.Table.ItemColumn className="w-full md:w-40">
                  <div className="flex flex-row items-center font-bold md:text-lg">
                    <BoltIcon className="h-5"/>
                    <span className="ml-1">
                      {LEVEL_MAP[battle.cardRarity][battle.level]}
                    </span>
                  </div>
                </S.Table.ItemColumn>
              </S.Table.ItemColumn>

              <S.Table.ItemColumn className="flex w-1/3 md:w-1/3 justify-end">
                {isJoined(battle) && !pendingBattles.includes(battle.id) && (
                  <>
                    <Button
                      title="Leave"
                      noIcon
                      secondary
                      size="sm"
                      disabled={!isReady}
                      className="inline ml-2"
                      onClick={() => {
                        if (confirm("Are you sure to leave battle?"))
                          leaveBattle(battle);
                      }}
                    />
                  </>
                )}

                {availableToJoin(battle) && canUserJoin(battle) && (
                  <>
                    <Button
                      title={
                        pendingBattles.includes(battle.id)
                          ? "Processing ..."
                          : "Join"
                      }
                      size="sm"
                      disabled={!isReady || pendingBattles.includes(battle.id)}
                      onClick={() => setBattleRequestVisible(index)}
                      className={"ml-2"}
                    />
                    <RequestClanBattlePopup
                      levelParam={battle.level}
                      title={`${battle.fromClan.name} vs ${battle.toClan.name}`}
                      btnTitle="Join Battle"
                      clan={clan}
                      visible={battleRequestVisible === index}
                      setVisible={setBattleRequestVisible}
                      battleId={battle.id}
                      successJoined={() => successJoinedBattle()}
                    />
                  </>
                )}

                {isJoined(battle) && canCancelBattle(battle) && (
                  <Button
                    title="Cancel"
                    noIcon
                    secondary
                    size="sm"
                    className="mx-3 hidden md:inline"
                    disabled={!isReady}
                    onClick={() => cancelBattle(battle)}
                  />
                )}
              </S.Table.ItemColumn>
            </S.Table.Content>
          ))}
      </S.SectionWrapper>

      {myZombiesBattle && (
        <BattleZombiesPopup
          battle={myZombiesBattle}
          visible={showMyZombiesPopup}
          setVisible={setShowMyZombiesPopup}
        />
      )}
    </>
  );
};
