import React from "react";
import {Popup} from "../Popup";

export const BattleTextResultsPopup = ({
                                         history,
                                         battle,
                                         visible,
                                         setVisible,
                                       }) => {
  return (
    <Popup
      title="Battle details"
      popupVisible={visible}
      setPopupVisible={setVisible}
    >
      <div className="flex flex-col gap-1 items-center justify-center">
        <div className="md:flex w-full gap-4 pb-2 mb-2 border-b-2 border-gray-700 border-dashed font-semibold text-gray-500">
          <div className="md:w-1/2">{battle.fromClan.name}</div>
          <div className="md:w-1/2">{battle.toClan.name}</div>
        </div>
        {visible &&
          history &&
          history.steps.map((step) => (
            <div className="flex w-full flex-row md:gap-4 gap-1">
              <div className="flex-1 text-right justify-start">
                {(!step.side || step.side === "from") && step.text}
              </div>
              <div className="flex w-32 justify-center gap-5">
                <div
                  className={`w-10 font-semibold ${
                    step.fromValue > step.toValue
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {step.fromValue.toFixed()}
                </div>
                <div
                  className={`w-10 font-semibold ${
                    step.toValue > step.fromValue
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {step.toValue.toFixed()}
                </div>
              </div>
              <div className="flex-1 text-left justify-end">
                {(!step.side || step.side === "to") && step.text}
              </div>
            </div>
          ))}
      </div>
    </Popup>
  );
};
