import React, {useEffect, useState} from "react";
import {MonsterContent} from "../../web3/content";
import {rarityOptions} from "../../web3/utils";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../../assets/styles/common.style";
import {List} from "../../assets/styles/common.style";
import {ListWrapper} from "../../assets/styles/common.style";
import {Header} from "../../components/Header";
import {Footer} from "../../components/Footer";
import {InnerPageHead} from "../../components/InnerPageHead";
import {Loader} from "../../components/basic/Loader";
import {Dropdown} from "../../components/basic/Dropdown";
import {Pagination} from "../../components/Pagination";
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {removeFromMarket} from '../../web3/contracts';
import {CardRotate} from "../../components/cards/CardRotate";
import {SelectedItemsFooter} from "../../components/selectedItemsFooter/SelectedItemsFooter";
import {transformMonster} from "../../web3/transform";

const PAGE_LIMIT = "8";

export const Monsters = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  // const sellList = useSelector(state => state.market.sale);
  // const killList = useSelector(state => state.sidebar.kill);

  const [isReady, setIsReady] = useState(false);
  const [userMonsters, setUserMonsters] = useState([]);
  const [userMonstersCount, setUserMonstersCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterRarity, setFilterRarity] = useState("");
  // const [transferItem, setTransferItem] = useState({});
  // const [transferPopupVisible, setTransferPopupVisible] = useState(false);
  const [selectedMonsters, setSelectedMonsters] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = JSON.parse(searchParams.has("page"))
      ? searchParams.get("page")
      : currentPage;
    const rarity = JSON.parse(searchParams.has("rarity"))
      ? searchParams.get("rarity")
      : filterRarity;

    setCurrentPage(parseInt(page));
    setFilterRarity(rarity);

    fetchUserMonsters(page, filterRarity);
  }, [currentUser]);

  useEffect(() => {
    if (isReady) {
      window.scrollTo({top: 0, behavior: "smooth"});
      setCurrentPage(1);
      fetchUserMonsters(1, filterRarity);
      navigate(buildUrl(1, filterRarity));
    }
  }, [filterRarity]);

  async function fetchUserMonsters(currentPage, rarity) {
    const monstersObj = await window.contracts.monster.userMonsters(currentPage, PAGE_LIMIT, rarity || "");
    const monsters = monstersObj[1].filter(monster => monster.nftType).map(monster => transformMonster(monster)).reverse();
    setUserMonstersCount(parseInt(monstersObj[0]));

    setUserMonsters(monsters);
    setIsReady(true);
  }

  const onPageChanged = (page) => {
    window.scrollTo({top: 0, behavior: "smooth"});
    setCurrentPage(page);
    fetchUserMonsters(page, filterRarity);
  };

  const buildUrl = (page, filterRarity) => {
    let url = `/monsters?page=${page}`;
    if (filterRarity) url = `${url}&rarity=${filterRarity}`;
    return url;
  };

  useEffect(() => {
    navigate(buildUrl(currentPage, filterRarity));
  }, [currentPage]);

  // const handleTransfer = async (transferAddress) => {
  //   transferNFT(dispatch, currentUser, transferAddress, transferItem.tokenId, transferItem.nftType).then(() => {
  //     setIsReady(false);
  //     fetchUserMonsters(currentPage, filterRarity);
  //     setTransferPopupVisible(false);
  //   });
  // };

  const rmFromMarket = (tokenId) => {
    removeFromMarket(dispatch, tokenId, "monster").then(() => {
      setIsReady(false);
      fetchUserMonsters(currentPage, filterRarity);
    });
  }

  const selectMonster = (monster) => {
    const findItem = selectedMonsters.findIndex(
      (value) => value.tokenId === monster.tokenId
    );
    if (findItem < 0) {
      return setSelectedMonsters((prevState) => [...prevState, monster]);
    }

    return setSelectedMonsters(
      selectedMonsters.filter((_, ind) => findItem !== ind)
    );
  };

  const isSelected = (monsterId) =>
    selectedMonsters.filter((monster) => monster.tokenId === monsterId).length >
    0;

  return (
    <InnerPageWrapper>
      <Header/>

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={MonsterContent.title}
            description={MonsterContent.description}
          />

          {isReady ? (
            <>
              <div className="flex justify-between mt-8 z-30">
                <div className="basis-1/2 text-lg text-left pt-2 pl-1">
                  Available:
                  <span className="ml-2 font-semibold text-orange-500">
                    {userMonstersCount} monster{userMonstersCount !== 1 ? "s" : ""}
                  </span>
                </div>

                <div className="basis-1/2 z-10 text-right">
                  <div className="inline-block mr-1">
                    <Dropdown
                      title="Rarity"
                      selected={filterRarity}
                      options={rarityOptions(setFilterRarity)}
                    />
                  </div>
                </div>
              </div>

              <ListWrapper>
                {userMonsters.length > 0 ? (
                  <List>
                    {userMonsters.map((monster, index) => (
                      <CardRotate
                        nft={monster}
                        key={index}
                        rmFromMarket={async () => {
                          setIsReady(false);
                          await rmFromMarket(monster.tokenId);
                          setIsReady(true);
                        }}
                        // setTransferPopupVisible={() => {
                        //   setTransferItem(monster);
                        //   setTransferPopupVisible(true);
                        // }}
                        handleSelect={() => selectMonster(monster)}
                        isSelected={isSelected(monster.tokenId)}
                        // setKillItem={() => appendToKillList(monster)}
                        // setSellItems={() => appendToSellList(monster)}
                      />
                    ))}
                  </List>
                ) : (
                  <div>
                    You don't have {filterRarity} Monsters.
                    {!filterRarity && (
                      <p>To get your first Monster you can complete <Link className="link" to="/collections">Collection</Link>{" "}
                        or buy it in the{" "}
                        <Link className="link" to="/market/monsters">Market</Link>.
                      </p>
                    )}
                  </div>
                )}
              </ListWrapper>

              <div className="mb-8">
                <Pagination
                  total={userMonstersCount}
                  limit={parseInt(PAGE_LIMIT)}
                  selectedPage={currentPage}
                  onPageChanged={onPageChanged}
                />
              </div>
            </>
          ) : (
            <Loader/>
          )}
        </Container>
      </Wrapper>

      {selectedMonsters.length > 0 && (
        <SelectedItemsFooter
          selectedItems={selectedMonsters}
          nftType="Monster"
          deselectItem={selectMonster}
          setSelectedItems={setSelectedMonsters}
          items={userMonsters}
          handleReloadData={() => {
            fetchUserMonsters(currentPage, currentPage);
            setSelectedMonsters([]);
          }}
        />
      )}

      <Footer/>
    </InnerPageWrapper>
  );
};
