import {ethers} from "ethers";

const CLAN_FEE_ZML = process.env.CREATE_CLAN_FEE || 5000;
export const CREATE_CLAN_FEE = ethers.utils.parseEther(CLAN_FEE_ZML.toString());

export const BASIC_STAKE = {
  Common: {stake: 50, prize: [90, 95, 100, 110]},
  UnCommon: {stake: 75, prize: [180, 190, 200, 220]},
  Rare: {stake: 150, prize: [270, 285, 300, 330]},
  Epic: {stake: 300, prize: [450, 475, 500, 550]},
};

export const LEVEL_MAP = {
  Common: [17, 34, 51, 74],
  UnCommon: [41, 58, 75, 98],
  Rare: [65, 82, 99, 122],
  Epic: [90, 107, 124, 147],
};

export const WHITEPAPER_LINKS = {
  'Test' : '',
  'Meter' : 'https://zomland-1.gitbook.io/zomland-meter',
  'Aurora' : 'https://zomland-1.gitbook.io/zomland-aurora',
  'Mantle' : 'https://zomland-1.gitbook.io/zomland-mantle',
}

export const collectionZombiesPath = {
  0: "bafkreigdcymxku7b6o4pcyfqqzf5dieviewhwndrknbggvhk6vokavfxwe",
  1: "bafybeifq6clpc672vcln7l5iv4355ze6ludm7opcpldbgkwa7sfu5inysm",
  2: "bafybeid3p33trzeklhvblet72wmt2rfnfvgii6ezbvhdix4hc7p2uwuotu",
  3: "bafybeifjiplwfr52wvogoxidckgpq2urq66cjrqfdvfsgn2y3kscxjlcu4",
  4: "bafybeico3paszepcemcprmsav47ntzy4cohqiw56m6o7pyi7oslhtf4ro4",
}

const monsterImageBase = 'bafybeievqbihc3hnsd7aw7anmuppu6h5url6a5pwgfgzrykzkoiqexnmru';
export const collectionMonsterPath = {
  0: `${monsterImageBase}/Mummy-1.png`,
  1: `${monsterImageBase}/Pirate-1.png`,
  2: `${monsterImageBase}/Punk-1.png`,
  3: `${monsterImageBase}/Stylish-1.png`,
  4: `${monsterImageBase}/Combat-1.png`,
}

export const landZombieCountMapping = {
  'Micro': 1,
  'Small': 2,
  'Medium': 4,
  'Large': 8,
}