import React from "react";
import {getMedia, maxDiscoveryMapping} from "../../web3/utils";
import {BanIcon, CheckCircleIcon, RefreshIcon, SearchIcon} from '@heroicons/react/outline';
import CardRotate, {Rarity} from "../../assets/styles/cardRotate.style";
import {Button} from "../basic/Button";
import {Counter} from "../Counter";
import {Price} from "../Price";
import {useSelector} from "react-redux";
import {landZombieCountMapping} from "../../web3/config";

export const CardLand = (
  {
    nft,
    size,
    rmFromMarket,
    discoverLand,
    handleBuy,
    handleSelect,
    isSelected,
    noFlip,
    showZombieCount
  }) => {
  const currentUser = useSelector(state => state.user.user);

  const classMapping = {
    xsm: "x-small",
    sm: "small",
    md: "",
  };

  const isDiscovered = () => {
    return nft.discoverEvents >= maxDiscoveryMapping[nft.landType];
  }

  const resetLandEvents = async () => {
    console.log(`resetLandEvents`);
    // await mainContract.landRestoreDiscoveryEvents(nft.tokenId, convertToYocto(resetDiscoveryPriceMap[nft.landType]));
  }

  const discoveryPct = () => {
    if (nft.landType !== "Micro") {
      const max = maxDiscoveryMapping[nft.landType];
      let result = parseInt((nft.discoverEvents / max) * 100);
      if (result > 100) {
        result = 100;
      }
      return result + '%';
    }
    return "No";
  }

  const formatCharacteristicOfCard = (nft) => {
    return [
      {
        title: "Size",
        value: nft.landType,
      },
      {
        title: nft.landType !== "Micro" ? "Discovered" : "Discovery",
        value: discoveryPct(),
      },
    ];
  };

  return (
    <CardRotate.FlipCardModify
      className={`${classMapping[size || "md"]} ${noFlip ? "pointer-events-none" : ""}`}>
      <CardRotate.Front isSelected={isSelected} type={nft.landType}>
        <section className="absolute z-10 left-0 right-0 bottom-0">
          <Rarity
            type={nft.landType}
            className={`${size !== "sm" ? "text-sm pl-2" : "text-xs pl-1"}`}
          >
            {showZombieCount ? (
              <span className={"text-white"}>{landZombieCountMapping[nft.landType]} zombie{landZombieCountMapping[nft.landType] > 1 && "s"} / day</span>
            ) : (
              <span>{nft.landType} {nft.tokenId > 0 ? `#${nft.tokenId}` : ""}</span>
            )}
          </Rarity>
        </section>
        <img
          className={`h-full`}
          src={getMedia(nft.media)}
          alt="Land"
        />
        {nft.salePrice && size !== "sm" && (
          <div className="z-10 absolute right-1 top-1">
            <Price title={nft.salePrice} handleBuy={handleBuy} token={process.env.PAYMENT_TOKEN_SYMBOL}/>
          </div>
        )}
        {nft.landType === "Micro" && nft.tokenId && nft.countMintedZombies > 0 && (
          <div className="z-10 absolute right-2 top-2">
            <Counter from={nft.countMintedZombies} to={30}/>
          </div>
        )}
        {isSelected && (
          <div className="top-0 left-0 right-0 bottom-0 z-10 absolute shadow-[0_0_20px_#f97315a8_inset]"/>
        )}
      </CardRotate.Front>

      <CardRotate.Back isSelected={isSelected} type={nft.landType}>
        {nft.tokenId && (
          <div
            className={`${size !== "sm" ? "items-center justify-center flex gap-2 flex-wrap" : ""}`}>
            {formatCharacteristicOfCard(nft).map((item) => {
              return (
                <div
                  key={item.title}
                  className={`${size !== "sm" && (`border-mainLight/80 bg-mainLight/30`)}
                  ${size !== "sm" ? "flex flex-col items-center w-5/12 px-11 py-5 border-2 rounded-md " : "mt-2"}
                  `}
                >
                  <div className={`h-5 w-5 flex text-center items-center mb-4 ${size !== "sm" ? "justify-center" : "ml-6"}`}>
                    <span className={`text-xl font-semibold`}>
                      {item.value}
                    </span>
                  </div>

                  {size !== "sm" && (
                    <span className={`text-xs uppercase text-indigo-300`}>
                    {item.title}
                  </span>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {nft.tokenId && nft.landType !== "Micro" && size !== "sm" && (
          <section>
            <div className="flex flex-col mt-3 space-y-2">
              <>
                {nft.ownerId.toLowerCase() === currentUser.accountId.toLowerCase() && discoverLand && (
                  <>
                    {isDiscovered() ? (
                      <Button
                        title={"Reset Discovery"}
                        onClick={resetLandEvents}
                        className="w-full bg-red-600"
                        size={"xs"}
                        icon={
                          <RefreshIcon className="h-5 w-5 ml-2 font-semibold"/>
                        }
                      />
                    ) : (
                      <Button
                        title={"Discover Land"}
                        onClick={discoverLand}
                        className="w-full"
                        size={"xs"}
                        icon={
                          <SearchIcon className="h-5 w-5 ml-2 font-semibold"/>
                        }
                      />
                    )}

                    {!nft.salePrice && (
                      <Button
                        title={isSelected ? "Deselect" : "Select"}
                        onClick={() => handleSelect()}
                        className="w-full"
                        secondary
                        size={"xs"}
                        icon={
                          isSelected ? (
                            <BanIcon className="h-5 w-5 ml-2 font-semibold"/>
                          ) : (
                            <CheckCircleIcon className="h-5 w-5 ml-2 font-semibold"/>
                          )
                        }
                      />
                    )}
                  </>
                )}

                {handleBuy ? (
                  <Button
                    title={
                      <>
                        <span className="mr-1">Buy for</span>
                        {nft.salePrice} {process.env.PAYMENT_TOKEN_SYMBOL}
                      </>
                    }
                    size="xs"
                    className="w-full"
                    noIcon
                    onClick={handleBuy}
                  />
                ) : (
                  nft.salePrice && (
                    <Button
                      title="Cancel sell"
                      size="xs"
                      noIcon
                      className="w-full"
                      secondary
                      onClick={rmFromMarket}
                    />
                  )
                )}

              </>
            </div>
          </section>
        )}

      </CardRotate.Back>
    </CardRotate.FlipCardModify>
  );
};
