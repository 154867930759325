import React, {useEffect, useState} from "react";
import {LeaderboardContent} from "../web3/content";
import {shortAddress, uniq} from "../web3/utils";
import {getRequest} from "../web3/api";
import S from "../assets/styles/clanLine.style";
import {
  Col,
  Container,
  InnerPageWrapper,
  Row,
  Wrapper,
} from "../assets/styles/common.style";

import FirstPlace from "../assets/images/1.png";
import SecondPlace from "../assets/images/2.png";
import ThirdPlace from "../assets/images/3.png";
import {transformClan} from "../web3/transform";
import {Header} from "../components/Header";
import {InnerPageHead} from "../components/InnerPageHead";
import {Loader} from "../components/basic/Loader";
import {Footer} from "../components/Footer";

export const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [ratingClans, setRatingClans] = useState([]);
  const [isReady, setIsReady] = useState(true);

  async function fetchLeaderboard() {
    setIsReady(false);
    const allUsers = await getRequest("api/users");

    if (allUsers.data) {
      const clanIds = allUsers.data
        .filter((u) => u.clanId)
        .map((u) => u.clanId)
        .filter(uniq);

      console.log(`clanIds`, clanIds);
      const clansList = await window.contracts.clan.getClansByIdList(clanIds);
      const clans = clansList.filter((clan) => parseInt(clan.id) > 0 && !clan.blocked).map(clan => transformClan(clan, allUsers.data));

      // const clans = await mainContract.getClanByIds(clanIds);
      const users = allUsers.data.map((u) => {
        u.clan = clans.find((c) => u.clanId === c.id);
        return u;
      });
      const _clans = users.reduce((group, user) => {
        const {clan} = user;
        if (!clan) return group;

        group[clan.name] = group[clan.name] ?? [];
        group[clan.name].push(user);

        return group;
      }, {});

      const _ratingClans = Object.entries(_clans)
        .map((arr) => {
          let obj = {};
          obj[arr[0]] = {
            localRating: arr[1]
              .map((u) => u.localRating)
              .reduce((prev, curr) => prev + curr, 0),
            media: arr[0].image,
          };

          return obj;
        })
        .sort(
          (a, b) =>
            Object.values(b)[0].localRating - Object.values(a)[0].localRating
        )
        .slice(0, 3);

      setLeaderboard(users);
      setRatingClans(_ratingClans);
    }

    setIsReady(true);
  }

  const firstUsersReward = (i) => {
    const rewardRates = [30, 25, 15, 10, 5, 5, 3, 3, 2, 2];
    if (rewardRates.length > i) {
      return rewardRates[i] + " " + process.env.PAYMENT_TOKEN_SYMBOL;
    }
    return "";
  };

  const firstUsersSelectClass = (i) => {
    if (i === 0) return "text-orange-600 font-bold";
    if (i === 1) return "text-orange-500 font-bold";
    if (i === 2) return "text-orange-400 font-bold";
    if (i <= 9) return "text-orange-300";
  };

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  return (
    <InnerPageWrapper>
      <Header/>

      <Wrapper>
        <Container className="flex flex-col text-white mt-6 items-center">
          <InnerPageHead
            title={LeaderboardContent.title}
            description={LeaderboardContent.desc}
          />

          {isReady ? (
            <>
              <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                TOP 3 Clans
              </h1>
              {ratingClans && (
                <div className="lg:w-1/3 w-full mt-7">
                  {ratingClans.filter(obj => Object.values(obj)[0].localRating >= 0).map((obj, i) => (
                    <div className="flex flex-row py-2 border-b-2 border-gray-700/30 border-dashed">
                      <Row className="flex ml-2 gap-5 w-full lg:justify-start justify-center">
                        <section>
                          <img
                            className="w-10"
                            src={
                              i === 0
                                ? FirstPlace
                                : i === 1
                                  ? SecondPlace
                                  : ThirdPlace
                            }
                            alt={`${i + 1} place`}
                          />
                        </section>
                        <section className="text-left truncate w-full lg:w-full text-lg font-semibold">
                          {Object.keys(obj)[0]}
                        </section>
                      </Row>
                      <Row className="lg:my-0 my-4 max-w-full sm:text-base text-sm">
                        <S.Rating>
                          <div className="text-lg text-orange-500 font-semibold">
                            {Object.values(obj)[0].localRating}
                          </div>
                        </S.Rating>
                      </Row>
                    </div>
                  ))}
                </div>
              )}

              <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                TOP 20 Players
              </h1>
              {leaderboard && (
                <div className="p-5 mt-10 bg-main lg:w-3/4 w-full">
                  <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
                    <Col className="w-10">#</Col>
                    <Col className="flex-1 mr-5">Account</Col>
                    <Col className="flex-1">Clan</Col>
                    <Col className="md:w-36 w-12">Monthly Rating</Col>
                    <Col className="md:w-36 w-12">Global Rating</Col>
                    <Col className="md:w-28 w-12">Reward</Col>
                  </Row>

                  <Col>
                    {leaderboard.slice(0, 20).map((account, i) => (
                      <Row
                        key={i + 1}
                        className={`${firstUsersSelectClass(
                          i
                        )} py-3 border-b-2 border-gray-700/30 border-dashed"`}
                      >
                        <div className="w-10">{i + 1}</div>
                        <div className="flex-1 w-20 text-sm truncate text-ellipsis mr-5">
                          {shortAddress(account.accountId)}
                        </div>
                        <div className="flex-1">
                          {account.clan?.name || "-"}
                        </div>
                        <div className="md:w-36 w-12">
                          {account.localRating}
                        </div>
                        <div className="md:w-36 w-12">{account.rating}</div>
                        <div className="md:w-28 w-12">
                          {account.localRating >= 100 && firstUsersReward(i)}
                        </div>
                      </Row>
                    ))}
                  </Col>
                </div>
              )}

              {leaderboard.filter((a) => a.monthlyRating > 0).length > 1 && (
                <>
                  <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                    Previous month Statistic
                  </h1>
                  <div className="p-5 mt-10 bg-main lg:w-3/4 w-full">
                    <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
                      <Col className="w-10">#</Col>
                      <Col className="flex-1 mr-5">Account</Col>
                      <Col className="md:w-32">Rating</Col>
                      <Col className="md:w-32">Reward</Col>
                    </Row>

                    <Col>
                      {leaderboard
                        .sort((a, b) => b.monthlyRating - a.monthlyRating)
                        .slice(0, 20)
                        .filter((a) => a.monthlyRating > 0)
                        .map((account, i) => (
                          <Row
                            key={i + 1}
                            className={`${firstUsersSelectClass(
                              i
                            )} py-3 border-b-2 border-gray-700/30 border-dashed"`}
                          >
                            <div className="w-10">{i + 1}</div>
                            <div className="flex-1 text-sm truncate text-ellipsis mr-5">
                              {account.accountId}
                            </div>
                            <div className="md:w-32">{account.monthlyRating}</div>
                            <div className="md:w-32">
                              {account.monthlyRating >= 100 && firstUsersReward(i)}
                            </div>
                          </Row>
                        ))}
                    </Col>
                  </div>
                </>
              )}

            </>
          ) : (
            <Loader/>
          )}
        </Container>
      </Wrapper>

      <Footer/>
    </InnerPageWrapper>
  );
};
