import React, {useContext, useEffect, useState} from "react";
import {Popup} from "../Popup";
import {Input, TextArea} from "../basic/Input";
import {Button} from "../basic/Button";
import {addPendingTransaction, convertFromYocto} from "../../web3/utils";
import {updateUserBalance} from "../../web3/contracts";
import {useDispatch, useSelector} from "react-redux";

export const EditClanPopup = ({clan, setVisible, visible, handleSuccessUpdate}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const [name, setName] = useState(clan.name);
  const [about, setAbout] = useState(clan.about);
  const [image, setImage] = useState(clan.image);
  const [entryFee, setEntryFee] = useState(0);
  const [locked, setLocked] = useState(clan.locked);

  const handleClanSave = () => {
    console.log(`entryFee`, entryFee);
    window.contracts.clan.editClan(clan.id, name, about, entryFee, image, locked).then(transaction => {
      addPendingTransaction(dispatch, transaction, `Edit clan "${name}"...`);

      transaction.wait().then(async (receipt) => {
        if (receipt.status === 1) {
          setVisible(false);
          handleSuccessUpdate();
          await updateUserBalance(dispatch, currentUser.accountId);
        } else {
          console.log(`receipt`, receipt);
        }
      });
    });
  }

  useEffect(() => {
    if (clan.entryFee > 0) {
      setEntryFee(convertFromYocto(clan.entryFee, 0, true))
    }
  }, [clan]);

  return (
    <Popup
      title="Edit Clan"
      popupVisible={visible}
      setPopupVisible={setVisible}
    >
      <div className="gap-5 px-6 mb-5 flex flex-col">
        <Input
          placeholder="Clan Name"
          handleChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextArea
          placeholder="Info about your clan and rules"
          handleChange={(e) => setAbout(e.target.value)}
          value={about}
        />
        <Input
          placeholder="Image URL"
          handleChange={(e) => setImage(e.target.value)}
          value={image}
        />
        <Input
          type="number"
          placeholder={`Entry price (${process.env.ZML_TOKEN})`}
          handleChange={(e) =>
            setEntryFee(e.target.value < 0 ? 0 : e.target.value)
          }
          value={entryFee}
        />
        <Input
          type="checkbox"
          label="Lock clan entrance"
          checked={locked}
          handleChange={(e) => setLocked(e.target.checked)}
          value={locked}
        />
        {/* <Input type="file" onChange={(e) => setMedia(e.target.files[0])} /> */}
      </div>
      <Button
        title="Update"
        disabled={!name}
        onClick={() => handleClanSave()}
      />
    </Popup>
  );
};
