import React, {useEffect, useRef, useState} from "react";
import {
  InnerPageWrapper,
  Wrapper,
  Container,
  Col,
} from "../../assets/styles/common.style";
import {LockClosedIcon} from "@heroicons/react/outline";
import S from "../../assets/styles/clanView.style";
import {useControlUrl} from "../../web3/useControlUrl";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CogIcon, LogoutIcon} from "@heroicons/react/outline";
import {UpgradeClanPopup} from "../../components/clan/UpgradeClanPopup";
import {EditClanPopup} from "../../components/clan/EditClanPopup";
import {RewardButton} from "../../components/clan/clan_line/RewardButton";
import {ClanBattles} from "./ClanBattles";
import {RequestClanBattlePopup} from "../../components/clan/RequestClanBattlePopup";
import {addPendingTransaction, addTransactionError, convertFromYocto, statusColorBorderMap, statusColorTextMap} from "../../web3/utils";
import {
  ArrowUpIcon,
  CheckIcon,
  StarIcon,
  UserIcon,
  UserRemoveIcon,
} from "@heroicons/react/solid";
import clan_image from "../../assets/images/clan_image.png";
import {useDispatch, useSelector} from "react-redux";
import {ClanBattleHistory} from "./ClanBattleHistory";
import {getRequest} from "../../web3/api";
import {Button, iconMapping} from "../../components/basic/Button";
import {Header} from "../../components/Header";
import {InnerPageHead} from "../../components/InnerPageHead";
import {Badge} from "../../components/basic/Badge";
import {Loader} from "../../components/basic/Loader";
import {Footer} from "../../components/Footer";
import {transformClan} from "../../web3/transform";
import {approveTokenSpend, joinClanCall, updateUserBalance} from "../../web3/contracts";
import {BigNumber} from "ethers";

export const Clan = () => {
  const {setUrlSearchParams, getSearchParam} = useControlUrl();

  const {id} = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [clan, setClan] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [battleRequestVisible, setBattleRequestVisible] = useState(false);
  const [alreadyInClan, setAlreadyInClan] = useState(true);
  const [ratingSum, setRatingSum] = useState(0);
  const [createdBattle, setCreatedBattle] = useState();
  const [clanUsers, setClanUsers] = useState([]);
  const [currentTab, setCurrentTab] = useState({label: "Battles", id: "2"});
  const [tabs, setTabs] = useState([]);
  const [battleStats, setBattleStats] = useState([]);
  const refInitialTabParam = useRef(getSearchParam("current_tab"));
  const showBattleResult = getSearchParam("view");

  async function fetchClan() {
    console.log(`currentUser.accountId`, currentUser.accountId);
    const [clanResponse, alreadyInClan] = await window.contracts.clan.getClanById(id, currentUser.accountId);
    const clan = transformClan(clanResponse);

    let battleTabTitle = "Battles";
    if (clan.participants.some((id) => id === currentUser.accountId)) {
      const battles = await getRequest("api/clan-battle/all", {
        clanId: id,
        accountId: currentUser.accountId,
      });
      const activeBattles = battles.data.filter((b) => !b.winnerClanId).length;
      if (activeBattles > 0) {
        battleTabTitle += ` <small class="battleTabCounter">${activeBattles}</small>`;
      }
    }

    const tabs = [
      {
        label: "Battles",
        id: "2",
        enabled: true,
        title: battleTabTitle
      },
      {
        label: "BattleHistory",
        id: "3",
        enabled: true,
        title: "Battle History",
      },
      {
        label: "Players",
        id: "1",
        enabled: true,
        title: "Players"
      },
    ];
    setTabs(tabs);
    setCurrentTab(
      tabs.find(({label}) => label === refInitialTabParam.current) ?? tabs[0]
    );

    setAlreadyInClan(alreadyInClan);
    if (clan.id > 0) {
      setClan(clan);
      getRating(clan);
    }

    setIsReady(true);
  }

  const getRating = async (clan) => {
    const resp = await getRequest(`api/clan/users/${clan.id}`);
    console.log(`resp.data`, resp.data);

    if (resp.data) {
      const users = resp.data.map((u) => {
        return !u.accountId
          ? {
            accountId: u.accountId,
            rating: "-",
          }
          : u;
      });
      console.log(`users`, users);
      setClanUsers(users);

      const ratingSum = users
        .map((u) => u.rating | 0)
        .reduce((prev, curr) => prev + curr, 0);
      setRatingSum(clan.rating + ratingSum);
    }
  };

  const handleLeaveClan = async (clanId) => {
    if (confirm("Are you sure you want to leave the clan?")) {
      window.contracts.clan.leaveClan().then(tx => {
        tx.message = "Leave Clan";
        addPendingTransaction(dispatch, tx, tx.message);

        tx.wait().then(receipt => {
          if (receipt.status === 1) {
            handleSuccessUpdate();
            navigate("/clans");
          }
        });
      }).catch(err => {
        addTransactionError(dispatch, err.message);
      });
    }
  };

  const openMyClanBattles = () => {
    navigate(`/clans/${createdBattle.fromClanId}?current_tab=Battles`);
  };

  const loadCreatedBattle = async () => {
    const battleId = localStorage.getItem("pending_create_battle");
    localStorage.removeItem("pending_create_battle");
    const battle = await getRequest(`api/clan-battle/info/${battleId}`);
    setCreatedBattle(battle.data);
  };

  const fetchClanStatistics = async () => {
    const response = await getRequest("api/clan-battle/all", {
      clanId: id,
      accountId: currentUser.accountId,
    });
    let battles = response.data.filter((b) => b.winnerClanId);
    setBattleStats(battles);
  };

  useEffect(() => {
    fetchClan();
    fetchClanStatistics();

    // show "Battle Created" info
    if (localStorage.getItem("pending_create_battle")) {
      loadCreatedBattle();
    }
  }, [id]);

  const changeCurrentTab = (newCurrentTab) => {
    setCurrentTab(newCurrentTab);
    setUrlSearchParams("current_tab", newCurrentTab.label);
  };

  const isParticipant = () => {
    return clan.participants.some((id) => id === currentUser.accountId);
  };

  const handleSuccessUpdate = () => {
    fetchClan();
  };

  const handleJoinClan = async () => {
    const fee = BigNumber.from(clan.entryFee.toString());
    if (BigNumber.from(currentUser.tokenBalance).lt(fee)) {
      alert(`You don't have enough ${process.env.ZML_TOKEN} to join this clan!`);
      return false;
    }

    const allowed = await window.contracts.token.allowance(
      currentUser.accountId,
      window.contracts.clan.address
    );

    if (allowed.lt(fee)) {
      approveTokenSpend(dispatch, window.contracts.token, window.contracts.clan.address, fee).then(() => {
        joinClanCall(dispatch, clan, () => {
          document.location.reload()
        });
      })
    } else {
      joinClanCall(dispatch, clan, () => {
        document.location.reload()
      });
    }
  }

  const isOwner = () => clan.ownerId === currentUser.accountId;

  const ClanButtons = () => {
    let renderBtns = [];
    console.log(`clan`, clan);

    if (clan.participants.length < clan.size && !alreadyInClan) {
      !clan.locked
        ? (renderBtns = [
          <Button
            title={`JOIN FOR ${convertFromYocto(clan.entryFee, 0, true)} ${process.env.ZML_TOKEN}`}
            size="md"
            key={"join" + clan.id}
            icon={<CheckIcon className={`ml-2 ${iconMapping["md"]}`}/>}
            onClick={() => handleJoinClan()}
          />,
        ])
        : (renderBtns = [
          <div className="flex items-center justify-center">
            <div className="border-2 border-gray-500 flex rounded-lg py-2 px-3 items-center justify-center">
                <span className="text-gray-400 text-sm uppercase font-semibold mr-2">
                  Locked
                </span>
              <LockClosedIcon className="h-5 w-full text-gray-400"/>
            </div>
          </div>,
        ]);
    }

    if (isOwner()) {
      renderBtns.push(
        <RewardButton id={id} clan={clan} key={"rw" + clan.id}/>,
        <Button
          title="UPGRADE CLAN"
          size="md"
          key={"up" + clan.id}
          secondary
          icon={<ArrowUpIcon className={`ml-2 ${iconMapping["md"]}`}/>}
          onClick={() => setVisible(true)}
        />,
        <EditClanPopup
          clan={clan}
          key={"edit-popup" + clan.id}
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          handleSuccessUpdate={handleSuccessUpdate}
        />,
        <Button
          title="Edit"
          size="md"
          key={"edit" + clan.id}
          secondary
          icon={<CogIcon className="ml-2 w-7 h-7"/>}
          onClick={() => setVisibleEdit(true)}
        />
      );
    } else if (isParticipant()) {
      renderBtns = [
        <RewardButton id={id} clan={clan} key={"rw" + clan.id}/>,
        <Button
          title="UPGRADE CLAN"
          size="md"
          key={"up" + clan.id}
          secondary
          icon={<ArrowUpIcon className={`ml-2 ${iconMapping["md"]}`}/>}
          onClick={() => setVisible(true)}
        />,
        <Button
          title={!isReady ? "LEAVING ..." : "LEAVE CLAN"}
          size="md"
          secondary
          key={"leave" + clan.id}
          icon={<LogoutIcon className={`ml-2 ${iconMapping["md"]}`}/>}
          disabled={!isReady}
          onClick={() => handleLeaveClan(clan.id)}
        />,
      ];
    } else if (alreadyInClan) {
      if (createdBattle) {
        renderBtns = [
          <div
            key={"info-battle"}
            onClick={() => openMyClanBattles()}
            className="text-orange-400 px-8 py-6 bg-mainLight hover:text-orange-300 rounded-lg cursor-pointer"
          >
            <b>Battle request successfully created!</b> <br/>
            Check participants and battle results in your clan.
          </div>,
        ];
      } else {
        renderBtns = [
          <Button
            title="REQUEST A BATTLE"
            size="md"
            key={"req" + clan.id}
            noIcon
            onClick={() => setBattleRequestVisible(true)}
          />,
        ];
      }
    }

    return (
      <div className="flex justify-center gap-3 flex-col sm:flex-row">
        {renderBtns}
      </div>
    );
  };

  const bestMatchPoints = () =>
    battleStats
      .map(
        (b) =>
          b.points &&
          (b.fromClan.id === id
            ? [b.points.fromClan.total, b.cardRarity]
            : [b.points.toClan.total, b.cardRarity])
      )
      .sort((a, b) => b[0] - a[0])[0];

  const winningGames = () =>
    clan && battleStats.filter((b) => b.winnerClanId === clan.id).length;

  const totalGames = () =>
    clan &&
    battleStats.filter(
      (b) => b.fromClan.id === clan.id || b.toClan.id === clan.id
    ).length;

  const wonLastGame = (accountId) => {
    const clanBattles = battleStats.filter(
      (b) => b.fromClan.id === clan.id || b.toClan.id === clan.id
    );
    const userLastBattle = clanBattles.filter((b) => {
      const userInFrom = b.fromClan.participants.some(
        (u) => u.accountId === accountId
      );
      const userInTo = b.toClan.participants.some(
        (u) => u.accountId === accountId
      );
      return userInFrom || userInTo;
    });
    if (userLastBattle.length === 0) return;

    const lastBattle = userLastBattle.reverse()[0];
    return lastBattle.winnerClanId === clan.id;
  };

  const successJoinedBattle = () => {
    // show "Battle Created" info
    if (localStorage.getItem("pending_create_battle")) {
      loadCreatedBattle();
    }
  };

  const handleKickUser = (user) => {
    if (confirm('Are you sure you want to kick user from Clan? You will return entry fee.')) {
      window.contracts.clan.getParticipantJoinFee(user).then(entryFee => {
        console.log(`entryFee`, parseInt(entryFee));
        if (entryFee.lt(currentUser.tokenBalance)) {
          setIsReady(false);
          window.contracts.clan.kickUserFromClan(user).then(transaction => {
            addPendingTransaction(dispatch, transaction, `Kick user from clan`);

            transaction.wait().then(receipt => {
              if (receipt.status === 1) {
                fetchClan();
                updateUserBalance(dispatch, currentUser.accountId);
                setIsReady(true);
              } else {
                console.log(`receipt`, receipt);
              }
            });
          }).catch(err => {
            addTransactionError(dispatch, err.message);
          });
        } else {
          alert('Error: Not enough funds to return entry fee.')
        }
      });
    }
  };

  return (
    <InnerPageWrapper>
      <Header/>
      <Wrapper>
        {isReady ? (
          <>
            {clan && clan.id ? (
              <Container className="text-center items-center mt-6">
                <InnerPageHead title={decodeURIComponent(clan.name)}/>
                <div className="md:w-full w-64 mx-auto relative">
                  <Link
                    to={"/clans"}
                    className={`absolute left-0 top-3 text-4xl font-semibold opacity-50 transition hover:opacity-90 cursor-pointer`}
                  >
                    &laquo; <small className={"text-lg align-middle"}>Clans</small>
                  </Link>
                  <ClanButtons/>
                </div>

                <RequestClanBattlePopup
                  title="Request a Battle"
                  btnTitle="Create Battle Request"
                  clan={clan}
                  visible={battleRequestVisible}
                  setVisible={setBattleRequestVisible}
                  isCreate
                  successJoined={() => successJoinedBattle()}
                />
                <UpgradeClanPopup
                  clan={clan}
                  visible={visible}
                  setVisible={setVisible}
                  handleSuccessUpdate={handleSuccessUpdate}
                />

                <S.InfoContainer>
                  <S.SectionWrapper className="flex flex-row w-full">
                    <Col className="w-full relative">
                      <div className="flex flex-col md:flex-row gap-5 items-start">
                        <Col className="w-full md:w-1/3">
                          <img
                            className="object-cover rounded-lg border-2 border-mainLight"
                            src={clan.media ?? clan_image}
                            onError={({currentTarget}) => {
                              currentTarget.onerror = null;
                              currentTarget.src = clan_image;
                            }}
                            alt="Clan"
                          />
                        </Col>
                        <Col className="w-full md:w-2/3">
                          <div className="flex items-center mb-4 md:absolute right-5 top-2">
                        <span className="text-orange-500 flex font-semibold text-2xl items-center">
                          <StarIcon className="h-7 mr-1"/> {ratingSum}
                        </span>
                          </div>
                          <S.HeadingTertiary className={"mt-1"}>
                            Clan Owner:
                            <span className={"ml-1 text-white font-normal"}>
                          {clan.ownerId}
                        </span>
                          </S.HeadingTertiary>
                          <S.HeadingTertiary>
                            Participants:
                            <span className={"ml-1 text-white font-normal"}>
                          {clan.participants.length} / {clan.size}
                        </span>
                          </S.HeadingTertiary>
                          <S.HeadingTertiary>
                            Entry Fee:
                            <span className={"ml-1 text-white font-normal"}>
                          {convertFromYocto(clan.entryFee, 0, true)} {process.env.ZML_TOKEN}
                        </span>
                          </S.HeadingTertiary>

                          {clan.about && (
                            <>
                              <S.HeadingTertiary className={"mt-4"}>
                                About Clan:
                              </S.HeadingTertiary>
                              <S.DetailsTertiary className="whitespace-pre-line text-sm">
                                {decodeURIComponent(clan.about) ?? "-"}
                              </S.DetailsTertiary>
                            </>
                          )}
                        </Col>
                      </div>

                      {battleStats?.length > 0 && (
                        <>
                          <S.HeadingTertiary className="mt-5">
                            Battle History
                          </S.HeadingTertiary>
                          <S.DetailsTertiary>
                            <div className="flex flex-col my-1 gap-x-2">
                              <div className="flex sm:flex-row flex-col w-full ">
                                {[
                                  ...battleStats.slice(0, 5),
                                  ...Array(5 - battleStats.slice(0, 5).length),
                                ].map((battle, index) => (
                                  <div
                                    key={index}
                                    className="flex sm:w-1/5 flex-row gap-2"
                                  >
                                    {battle ? (
                                      <div
                                        key={index}
                                        className={`flex p-1 flex-col w-full border-2 rounded-lg border-mainLight text-white font-bold items-center ${
                                          battle.winnerClanId === id
                                            ? "bg-green-500"
                                            : "bg-red-500"
                                        }`}
                                      >
                                        {battle.cardRarity}
                                      </div>
                                    ) : (
                                      <div
                                        key={index}
                                        className={`flex p-1 flex-col w-full border-2 rounded-lg border-mainLight text-white font-bold items-center bg-gray-600`}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </S.DetailsTertiary>
                          <S.DetailsTertiary>
                            <div className="flex gap-2 sm:flex-row flex-col">
                              <div className="pt-2 pb-4 px-4 bg-main rounded-md flex flex-col sm:w-72 items-center">
                                <div className="my-2 uppercase text-sm  text-gray-500">
                                  Best Match Point
                                </div>
                                <div className=" zombie-font text-6xl text-orange-500 text-center mt-5 mb-2">
                                  {bestMatchPoints()[0].toFixed(1) || "-"}
                                </div>
                                <Badge
                                  text={bestMatchPoints()[1] || "-"}
                                  borderColor={statusColorBorderMap(
                                    bestMatchPoints()[1]
                                  )}
                                  textColor={statusColorTextMap(
                                    bestMatchPoints()[1]
                                  )}
                                />
                              </div>
                              <div className="pt-2 pb-4 px-4 bg-main rounded-md flex flex-col sm:w-72 items-center">
                                <div className="my-2 uppercase text-sm  text-gray-500">
                                  Won / Total games
                                </div>
                                <div className=" zombie-font text-6xl text-center mt-5 mb-2">
                              <span className="text-green-500">
                                {winningGames()}
                              </span>
                                  /<span>{totalGames()}</span>
                                </div>
                                <div className="text-sm text-gray-500">
                                  Win percentage:{" "}
                                  {parseInt((winningGames() / totalGames()) * 100)}%
                                </div>
                              </div>
                              <div className="py-2 px-4 bg-main rounded-md w-full">
                                <div className="flex-row my-2 uppercase text-sm items-center text-gray-500">
                                  TOP 5 Fighters
                                </div>
                                {battleStats.length ? (
                                  <div className=" text-orange-500">
                                    {clanUsers
                                      .sort((a, b) => b.rating - a.rating)
                                      .slice(0, 5)
                                      .map((user, index) => (
                                        <div
                                          key={index}
                                          className="flex justify-between gap-x-1 py-1 text-sm border-b border-gray-700 border-dashed last:border-b-0"
                                        >
                                          <div className=" text-indigo-100 flex flex-row items-center">
                                            <div className="truncate md:w-60 w-full">
                                              {user.accountId}
                                            </div>
                                          </div>
                                          <div className="flex">
                                            <div className="text-indigo-300 w-12">
                                              {user.localRating}
                                            </div>
                                            <div className="text-indigo-300 w-12">
                                              {user.rating}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                ) : (
                                  <div className="font-bold text-orange-500">-</div>
                                )}
                              </div>
                            </div>
                          </S.DetailsTertiary>
                        </>
                      )}
                    </Col>
                  </S.SectionWrapper>
                </S.InfoContainer>

                {isParticipant() && (
                  <section className="w-full">
                    <S.TabContainer>
                      {tabs.map((tab) => (
                        <S.Tab
                          key={tab.id}
                          onClick={() => changeCurrentTab(tab)}
                          selectedTab={tab.id === currentTab.id}
                          enabled={tab.enabled}
                          dangerouslySetInnerHTML={{__html: tab.title}}
                        />
                      ))}
                    </S.TabContainer>

                    {currentTab.id === "1" && (
                      <S.SectionWrapper className="w-full">
                        <S.Table.Header>
                          <S.Table.ItemColumn className="flex">
                            <UserIcon className="h-4 mr-2"/>
                            <b>User Account</b>
                          </S.Table.ItemColumn>
                          <S.Table.ItemColumn className="justify-between w-1/2">
                            <S.Table.ItemColumn className="w-1/4">
                              <b>Monthly Rating</b>
                            </S.Table.ItemColumn>
                            <S.Table.ItemColumn className="w-1/4 lg:mr-24">
                              <b>Global Rating</b>
                            </S.Table.ItemColumn>
                            <S.Table.ItemColumn>
                              <b>Role</b>
                            </S.Table.ItemColumn>
                            {isOwner() && (
                              <S.Table.ItemColumn className={"w-32 justify-end"}>
                                <b>Actions</b>
                              </S.Table.ItemColumn>
                            )}
                          </S.Table.ItemColumn>
                        </S.Table.Header>
                        {clanUsers.map((user, index) => (
                          <S.Table.Content key={index}>
                            <S.Table.ItemColumn>
                              <div className="truncate text-sm text-blue-200">
                                {user.accountId}
                              </div>
                            </S.Table.ItemColumn>
                            <S.Table.ItemColumn className="justify-between w-1/2">
                              <S.Table.ItemColumn className="w-1/4">
                                {user.localRating}
                              </S.Table.ItemColumn>
                              <S.Table.ItemColumn className="w-1/4">
                                {user.rating}
                              </S.Table.ItemColumn>
                              <S.Table.ItemColumn>
                                {clan.ownerId === user.accountId ? (
                                  <Badge text="admin" bgColor="bg-violet-700"/>
                                ) : (
                                  <Badge text="member" bgColor="bg-green-700"/>
                                )}
                              </S.Table.ItemColumn>
                              {isOwner() && (
                                <S.Table.ItemColumn className={"w-32 justify-end"}>
                                  {clan.ownerId !== user.accountId ? (
                                    <Button
                                      title="KICK"
                                      size="xs"
                                      disable={!isReady}
                                      secondary
                                      icon={
                                        <UserRemoveIcon
                                          className={`ml-2 ${iconMapping["sm"]}`}
                                        />
                                      }
                                      className={"hidden md:inline"}
                                      onClick={() => handleKickUser(user.accountId)}
                                    />
                                  ) : (
                                    <div></div>
                                  )}
                                </S.Table.ItemColumn>
                              )}
                            </S.Table.ItemColumn>
                          </S.Table.Content>
                        ))}
                      </S.SectionWrapper>
                    )}

                    {currentTab.id === "2" && (
                      <ClanBattles clan={clan} alreadyInClan={alreadyInClan}/>
                    )}

                    {currentTab.id === "3" && (
                      <ClanBattleHistory
                        clan={clan}
                        showBattleResult={showBattleResult}
                      />
                    )}

                  </section>
                )}
              </Container>
            ) : (
              <p className={"text-center text-lg mt-10"}>
                *Clan not found
              </p>
            )}
          </>
        ) : (
          <Loader/>
        )}
      </Wrapper>
      <Footer/>
    </InnerPageWrapper>
  );
};
