import React from "react";

export function Input(props) {
  const {placeholder, value, handleChange, type, label, checked} = props;

  return (
    <>
      {label && (
        <label className="text-gray-400 font-semibold -mb-3">{label}</label>
      )}
      <input
        type={type ?? "text"}
        checked={checked}
        className="px-4 py-2 w-full rounded-md bg-transparent border-indigo-500 text-indigo-100 border-2"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        min={0}
      />
    </>
  );
}

export function TextArea(props) {
  const {placeholder, value, handleChange} = props;

  return (
    <textarea
      className="px-4 py-2 w-full rounded-md bg-transparent border-indigo-500 text-indigo-100 border-2"
      placeholder={placeholder}
      value={value}
      rows={10}
      onChange={handleChange}
    />
  );
}
