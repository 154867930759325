import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Popup} from "../Popup";
import {Input, TextArea} from "../basic/Input";
import {Button} from "../basic/Button";
import {CREATE_CLAN_FEE} from "../../web3/config";
import {approveTokenSpend, updateUserBalance} from "../../web3/contracts";
import {addPendingTransaction} from "../../web3/utils";

export const CreateClanPopup = ({setVisible, visible, reloadClanList}) => {
  const currentUser = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [entryFee, setEntryFee] = useState();
  const [image, setImage] = useState("");

  const createClanCall = () => {
    window.contracts.clan.createClan(name, about, entryFee, image).then(transaction => {
      addPendingTransaction(dispatch, transaction, `Create clan "${name}"...`);

      transaction.wait().then(async (receipt) => {
        if (receipt.status === 1) {
          setVisible(false);
          reloadClanList();
          await updateUserBalance(dispatch, currentUser.accountId);
        } else {
          console.log(`receipt`, receipt);
        }
      });
    });
  }

  const handleClanCreate = async () => {
    const allowed = await window.contracts.token.allowance(
      currentUser.accountId,
      window.contracts.clan.address
    );

    if (allowed.lt(CREATE_CLAN_FEE)) {
      approveTokenSpend(dispatch, window.contracts.token, window.contracts.clan.address, CREATE_CLAN_FEE).then(() => {
        createClanCall();
      })
    } else {
      createClanCall();
    }
  }

  return (
    <Popup
      title="Create Clan"
      popupVisible={visible}
      setPopupVisible={setVisible}
    >
      <div className="gap-5 px-6 mb-5 flex flex-col">
        <Input
          placeholder="Clan Name*"
          handleChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextArea
          placeholder="Info about your clan and rules"
          handleChange={(e) => setAbout(e.target.value)}
          value={about}
        />
        <Input
          type="number"
          placeholder={`Entry price (${process.env.ZML_TOKEN})*`}
          handleChange={(e) =>
            setEntryFee(e.target.value < 0 ? 0 : e.target.value)
          }
          value={entryFee}
        />
        <Input
          placeholder="Public Image URL"
          handleChange={(e) => setImage(e.target.value)}
          value={image}
        />
        {/* <Input type="file" onChange={(e) => setMedia(e.target.files[0])} /> */}
      </div>
      <Button
        title="Create"
        disabled={!name || !entryFee}
        onClick={() => handleClanCreate()}
      />
    </Popup>
  );
};
