import {ethers} from 'ethers';
import {addTransaction, removeTransaction, updateTransaction} from '../store/transactionSlice';
import {collectionMonsterPath, collectionZombiesPath} from "./config";

export const maxDiscoveryMapping = {
  Small: 59,
  Medium: 139,
  Large: 299,
  Giant: 599,
};

export const landTypeMap = {
  0: "Micro",
  1: "Small",
  2: "Medium",
  3: "Large",
};

export const rarityMap = {
  0: "Common",
  1: "UnCommon",
  2: "Rare",
  3: "Epic",
};

export const nftTypeMap = {
  'Land': "Land",
  1: "Zombie",
  2: "Monster",
  3: "Inventory",
  4: "Modifier",
  5: "MonsterPart",
};

export const inventoryTypes = [
  "Bomb",
  "Bow",
  "Sword",
  "Armor",
  "Shield",
  "Health",
  "Potion",
  "Poison",
];

export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const maxDiscoveryEvents = (land_type) => {
  if (land_type === "Small") {
    return 59;
  } else if (land_type === "Medium") {
    return 139;
  } else if (land_type === "Large") {
    return 299;
  } else if (land_type === "Giant") {
    return 599;
  }
};

export const modifierTypes = [
  "Attack",
  "Health",
  "Brain",
]

export const inventoryOptions = (setFilterType) => {
  let result = [];
  const options = ['All'].concat(inventoryTypes);

  options.map((item, index) => {
    result.push({
      title: item,
      onClick: () => setFilterType(index ? index : 0),
    });
  });
  return result;
};

export const modifierOptions = (setFilterType) => {
  let result = [];
  const options = ["All", "Attack", "Health", "Brain"];
  options.map((item, index) => {
    result.push({
      title: item,
      onClick: () => setFilterType(index ? index : 0),
    });
  });
  return result;
};

export const formatPrice = (price, isNative) => {
  const formatted = convertFromYocto(price, 2, isNative);
  if (formatted >= 100) {
    return convertFromYocto(price, 0, isNative);
  } else if (formatted >= 1) {
    return convertFromYocto(price, 2, isNative);
  } else {
    return convertFromYocto(price, 3, isNative);
  }
};

export const getMedia = (media) => {
  if (media.toLocaleLowerCase().indexOf(".png") === -1) {
    media += `.png`;
  }
  return `${process.env.SPACES_URL}/${media}`;
}

export const convertFromYocto = (amount, digits = 1, isNative = false) => {
  if (!amount) {
    amount = 0;
  }
  return (+ethers.utils.formatUnits(
    amount.toString(),
      isNative? 18 : process.env.PAYMENT_TOKEN_DECIMALS
    )
  ).toFixed(digits);
};

export const convertToYocto = (amount, isNative = false) => {
  return ethers.utils.parseUnits(
    amount.toString(),
    isNative? 18 : process.env.PAYMENT_TOKEN_DECIMALS
  );
};

export const formatLandId = (landType, tokenId, size = "md") => {
  if (size === "sm") {
    return `${landType} #${tokenId}`;
  }
  return `${landType} Land #${tokenId}`;
};

export const statusColorTextMap = (status) => {
  let result = "text-gray-500";
  if (status === "Small" || status === "UnCommon") {
    result = "text-green-500";
  } else if (status === "Medium" || status === "Rare") {
    result = "text-blue-500";
  } else if (status === "Large" || status === "Epic") {
    result = "text-rose-500";
  }
  return result;
};

export const statusColorBorderMap = (status) => {
  let result = "border-gray-500";
  if (status === "Small" || status === "UnCommon") {
    result = "border-green-500";
  } else if (status === "Medium" || status === "Rare") {
    result = "border-blue-500";
  } else if (status === "Large" || status === "Epic") {
    result = "border-rose-500";
  }
  return result;
};


// rating formula per person
// clan rating   0-1000  | 1001-5000 | 5001-10000 | 10001-...
// 0-1000         +8/-8     +8/-6       +8/-4      +8/-2
// 1001-5000      +10/-12   +10/-10     +10/-8     +10/-6
// 5001-10000     +12/-16   +12/-14     +12/-12    +12/-10
// 10001- ...     +14/-20   +14/-18     +14/-16    +14/-14
export const clanRatingMap = [
  [
    [8, -8],
    [8, -6],
    [8, -4],
    [8, -2],
  ],
  [
    [10, -12],
    [10, -10],
    [10, -8],
    [10, -6],
  ],
  [
    [12, -16],
    [12, -14],
    [12, -12],
    [12, -10],
  ],
  [
    [14, -20],
    [14, -18],
    [14, -16],
    [14, -14],
  ],
];


export const shortAddress = (address) => {
  return address.slice(0, 5) + '...' + address.slice(38, 42);
}

export const addPendingTransaction = (dispatch, transaction, message) => {
  const txId = new Date().toISOString();
  dispatch(addTransaction({
    id: txId,
    hash: transaction.hash,
    message,
    status: "pending"
  }));

  transaction.wait().then((receipt) => {
    if (receipt.status === 1) {
      dispatch(updateTransaction({
        id: txId,
        status: "success",
      }));
    } else {
      dispatch(updateTransaction({
        id: txId,
        status: "error",
      }));
    }

    setTimeout(() => {
      dispatch(removeTransaction({
        id: txId,
      }));
    }, 5000);
  });
}

export const addTransactionError = (dispatch, message) => {
  const txId = new Date().toISOString();
  dispatch(addTransaction({
    id: txId,
    message,
    status: "error"
  }));

  setTimeout(() => {
    dispatch(removeTransaction({
      id: txId,
    }));
  }, 5000);
}

export const partition = (input, spacing) => {
  let output = [];
  for (let i = 0; i < input.length; i += spacing) {
    output[output.length] = input.slice(i, i + spacing);
  }
  return output;
};

export const getRatingIndex = (rating) => {
  if (rating <= 1000) return 0;
  if (rating >= 1001 && rating <= 5000) return 1;
  if (rating >= 5001 && rating <= 10000) return 2;
  if (rating >= 10001) return 3;
};


export const statusColorBgMap = (status) => {
  let result = "bg-gray-500";
  if (status === "Medium" || status === "Uncommon") {
    result = "bg-green-500";
  } else if (status === "Large" || status === "Rare") {
    result = "bg-blue-500";
  } else if (status === "Giant" || status === "Epic") {
    result = "bg-rose-500";
  }
  return result;
};

export const rarityOptions = (setFilterRarity) => {
  const result = [];
  const options = ["All Rarities", "Common", "UnCommon", "Rare", "Epic"];

  options.map(option => {
    result.push({
      title: option,
      onClick: () => {
        const optionValue = option === "All Rarities" ? "" : option;
        setFilterRarity(optionValue);
      },
    })
  });

  return result;
};

export const collectionOptions = (allCollections, setFilterCollection) => {
  const collections = Object.keys(allCollections).map((key) => {
    return {
      title: allCollections[key].title,
      onClick: () => {
        const selectedCollection = allCollections[key].id;
        setFilterCollection(selectedCollection);
      },
    };
  });

  return [
    {
      title: "All Collections",
      onClick: () => {
        setFilterCollection("");
      },
    },
    ...collections,
  ];
};

export const isOwner = (currentUser, nftOwner) => {
  return currentUser.accountId === nftOwner.toLowerCase();
}

export const landTypeOptions = (setFilterLandType) => {
  const result = [];
  ["All Types", "Small", "Medium", "Large"].map(option => {
    result.push({
      title: option,
      onClick: () => {
        const optionValue = option === "All Types" ? "" : option;
        setFilterLandType(optionValue);
      },
    })
  });

  return result;
};

export const uniq = (value, index, self) => self.indexOf(value) === index;

export const selectAll = (selectedList, setSelectedList, list) => {
  const newSelected = list.filter((z) => !z.salePrice);
  setSelectedList([...selectedList, ...newSelected].filter(uniq));
};

export const deselectAll = (setSelectedList) => setSelectedList([]);
