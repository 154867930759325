import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CurrencyDollarIcon} from "@heroicons/react/outline";
import {Container} from "../../assets/styles/common.style";
import {addPendingTransaction, addTransactionError, convertFromYocto, formatPrice, nftTypeMap} from "../../web3/utils";
import {Button} from "../basic/Button";
import {approveTokenSpend} from "../../web3/contracts";
import {postRequest} from "../../web3/api";

export const BuyItemsFooter = (
  {
    selectedItems,
    handleSuccessBuy,
    setSelectedItems,
    allItems,
    selectToBuy
  }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [nftType, setNftType] = useState("");

  useEffect(() => {
    let totalItems = 0;
    let total = 0;

    Object.keys(selectedItems).map((itemType) => {
      if (selectedItems[itemType].length > 0) {
        selectedItems[itemType].map((item) => {
          totalItems++;
          total += item.price;
        });
        setNftType(itemType);
      }
    });

    setTotalItems(totalItems);
    setTotalPay(total);
  }, [selectedItems]);

  const selectAll = () => {
    const newSelectItems = [];
    const itemType = nftTypeMap[allItems[0].nftType];

    allItems.map((item) => {
      let addNew = true;
      selectedItems[itemType].map((selectedItem) => {
        if (selectedItem.tokenId === item.tokenId && selectedItem.ownerId !== currentUser.accountId) {
          addNew = false;
        }
      });
      if (addNew) {
        newSelectItems.push(item);
      }
    });

    if (newSelectItems.length) {
      selectToBuy(newSelectItems);
    }
  }

  const deselectAll = () => {
    setSelectedItems({
      Land: [],
      Zombie: [],
      Monster: [],
      MonsterPart: [],
      Inventory: [],
      Modifier: [],
    });
  }

  const buttonText = () => {
    let result = "for ";
    if (totalPay > 0) {
      result += `${formatPrice(totalPay, false)} ${process.env.PAYMENT_TOKEN_SYMBOL}`;
    }
    return result;
  };

  const handleBuy = async () => {
    const balance = await window.contracts.tokenMTRG.balanceOf(currentUser.accountId);
    if (balance.lt(totalPay.toString())) {
      alert(`Not enough ${process.env.PAYMENT_TOKEN_SYMBOL} balance`);
      return;
    }

    const allowed = await window.contracts.tokenMTRG.allowance(
      currentUser.accountId,
      window.contracts.market.address
    );
    const idList = selectedItems[nftType].map((item) => item.tokenId);
    if (allowed.lt(totalPay.toString())) {
      approveTokenSpend(dispatch, window.contracts.tokenMTRG, window.contracts.market.address, totalPay.toString()).then(() => {
        buyTransaction(idList);
      });
    } else {
      buyTransaction(idList);
    }
  };

  const buyTransaction = async (tokensListId) => {
    try {
      await window.contracts.market.buyNFTList(tokensListId, nftType.toLowerCase()).then(transaction => {
        addPendingTransaction(dispatch, transaction, `Buy ${nftType}${tokensListId.length > 1 ? "s" : ""}`);
        transaction.wait().then(receipt => {
          if (receipt.status === 1) {
            postRequest("api/stats/volume/update", {
              amount: convertFromYocto(totalPay, 4, false),
            });
            handleSuccessBuy();
          }
        });
      });
    } catch (e) {
      console.log(`Error:`, e);
      addTransactionError(dispatch, e.message);
    }
  }

  // const handleBuy = async (item) => {
  //   const allowed = await window.contracts.tokenMTRG.allowance(
  //     currentUser.accountId,
  //     window.contracts.market.address
  //   );
  //
  //   if (allowed.lt(BigNumber.from(item.price.toString()))) {
  //     approveTokenSpend(item.tokenId, item.nftType.toLowerCase(), item.price.toString());
  //   } else {
  //     buyTransaction(item.tokenId, item.nftType.toLowerCase());
  //   }
  // };
  //
  // const rmFromMarket = (token) => {
  //   removeFromMarket(dispatch, token.tokenId, token.nftType).then(() => {
  //     setIsReady(false);
  //     showMarket(getCurrentSection(), filterRarity, filterCollection, filterLandType, currentPage);
  //   });
  // }


  return (
    <>
      <div
        className={`bottom-0 right-0 left-0 fixed h-24 px-2 md:py-6 py-2 bg-gray-800 z-30 shadow-3xl border-t-[4px] border-gray-700`}
      >
        <Container className="flex justify-between">
          <div className="text-sm">
            <div className="hidden md:block">
              <div>
                <b>Selected:{" "}</b>
                {selectedItems["Land"].length > 0 && (
                  <span className="mr-3">
                  {selectedItems["Land"].length} Land{selectedItems["Land"].length > 1 ? "s" : ""}
                </span>
                )}
                {selectedItems["Zombie"].length > 0 && (
                  <span className="mr-3">
                  {selectedItems["Zombie"].length} Zombie{selectedItems["Zombie"].length > 1 ? "s" : ""}
                </span>
                )}
                {selectedItems["Monster"].length > 0 && (
                  <span className="mr-3">
                  {selectedItems["Monster"].length} Monster{selectedItems["Monster"].length > 1 ? "s" : ""}
                </span>
                )}
                {selectedItems["MonsterPart"].length > 0 && (
                  <span className="mr-3">
                  {selectedItems["MonsterPart"].length} Monster Part{selectedItems["MonsterPart"].length > 1 ? "s" : ""}
                </span>
                )}
                {selectedItems["Inventory"].length > 0 && (
                  <span className="mr-3">
                  {selectedItems["Inventory"].length} Inventor{selectedItems["Inventory"].length > 1 ? "ies" : "y"}
                </span>
                )}
                {selectedItems["Modifier"].length > 0 && (
                  <span className="mr-3">
                  {selectedItems["Modifier"].length} Modifier{selectedItems["Modifier"].length > 1 ? "s" : ""}
                </span>
                )}
              </div>

              <div>
                <b onClick={() => selectAll()}
                   className="dashed hover:underline cursor-pointer mt-1 inline-block text-orange-400">
                  Select All
                </b>
                <b onClick={() => deselectAll()}
                   className="md:ml-5 dashed hover:underline cursor-pointer mt-1 inline-block text-orange-400">
                  Deselect All
                </b>
              </div>
            </div>
          </div>

          <div className="text-right">
            <Button
              className="ml-4 min-w-[90px]"
              title={`Buy ${buttonText()}`}
              size={"sm"}
              icon={<CurrencyDollarIcon className="h-5 w-5 ml-2"/>}
              onClick={() => handleBuy()}
            />
          </div>
        </Container>
      </div>
    </>
  );
};
