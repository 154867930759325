import styled from "styled-components";
import {statusColorBorderMap} from '../../web3/utils';

export const CardInner = styled.div.attrs(() => ({
  className: `relative h-full w-full text-center`,
}))``;

export const InfoWrapper = styled.div.attrs(() => ({
  className:
    "absolute flex drop-shadow-md justify-center w-full bottom-0 font-semibold py-3 z-10",
}))`
  transform: translateY(${(props) => (props.withBtn ? "222px" : "172px")});
  background: linear-gradient(180deg,
  rgba(12, 6, 53, 0.4) 0%,
  rgba(15, 8, 65, 0.8) 25%,
  rgba(12, 6, 53, 1) 100%);
  transition: 0.3s 2s;
`;

export const CardFront = styled.div.attrs((props) => ({
  className: `
    absolute
    w-full
    h-full
    border-4
    rounded-xl
    overflow-hidden
    ${props.isSelected ? "border-orange-500" : ""}
    ${statusColorBorderMap(props.type)}
  `,
}))`
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

export const FlipCard = styled.div`
  width: 225px;
  height: 328px;
  position: relative;
  overflow: hidden;

  &.x-small-square {
    width: 94px;
    height: 94px;
  }

  &.small {
    width: 140px;
    height: 196px;
  }

  &.square {
    height: 225px;
  }

  &.md-small {
    width: 196px;
    height: 269px;
  }

  &.square-small {
    width: 130px;
    height: 130px;
  }
`;
