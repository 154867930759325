import React from "react";
import {addTransactionError} from "../../web3/utils";
import {Row} from "../../assets/styles/common.style";
import {Button} from "../../components/basic/Button";
import {BigNumber, ethers} from 'ethers';
import {useDispatch, useSelector} from "react-redux";
import {CardLand} from "../../components/cards/CardLand";
import {approveTokenSpend} from "../../web3/contracts";

export const MintLandSection = ({
                                  allLands,
                                  watchMintTransaction,
                                  isMicroLand,
                                  handleSuccessMint
                                }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);

  const mintLandNFT = (landIndex) => {
    window.contracts.land.safeMint(landIndex).then(tx => {
      tx.message = "Minting Land NFT";
      watchMintTransaction(tx);
      tx.wait().then(receipt => {
        if (receipt.status === 1) {
          handleSuccessMint();
        }
      });
    }).catch(err => {
      addTransactionError(dispatch, err.message);
    });
  }

  const getPriceWithDiscount = (land) => {
    return parseFloat(land.price) - land.discount;
  }

  const handleMint = async (landIndex, landInfo) => {
    const allowed = await window.contracts.tokenMTRG.allowance(
      currentUser.accountId,
      window.contracts.land.address
    );

    const price = parseFloat(landInfo.price) - landInfo.discount;
    const digits = await window.contracts.tokenMTRG.decimals();
    const depositPrice = BigNumber.from(ethers.utils.parseUnits(price.toString(), digits));

    if (allowed.lt(depositPrice)) {
      approveTokenSpend(
        dispatch, window.contracts.tokenMTRG, window.contracts.land.address, depositPrice
      ).then(() => {
        mintLandNFT(landIndex);
      });
    } else {
      mintLandNFT(landIndex);
    }
  };

  const MintCard = ({type, index}) => (
    <div className="sm:flex sm:flex-col relative">
      {allLands[type].discount > 0 && (
        <div className={"absolute text-left left-4 top-3 z-20 text-sm opacity-80 leading-4"}>
          <p>
            <small>Price: {parseFloat(allLands[type].price).toFixed(1)} {process.env.PAYMENT_TOKEN_SYMBOL}</small>
          </p>
          <p>
            <small>Discount: {allLands[type].discount} {process.env.PAYMENT_TOKEN_SYMBOL}</small>
          </p>
        </div>
      )}

      <CardLand noFlip nft={allLands[type]} showZombieCount/>

      <div className="mt-4">
        <Button title={`Mint ${type} Land`}
                onClick={() => handleMint(index, allLands[type])}
        />
        <div className="mt-3 font-semibold">
          <b>{getPriceWithDiscount(allLands[type])} </b>
          {process.env.PAYMENT_TOKEN_SYMBOL}
        </div>
      </div>
    </div>
  );

  return (
    <Row className="justify-center gap-6 flex-wrap">
      {
        Object.keys(allLands).map((landType, index) => {
          if (landType !== 'Micro' || !isMicroLand()) {
            return (
              <MintCard key={landType}
                        type={landType}
                        index={index}
              />
            )
          }
        })
      }
    </Row>
  );
};
