import React, {useEffect, useState} from "react";
import {
  addPendingTransaction,
  convertFromYocto,
  landTypeMap,
} from "../../web3/utils";
import {LandContent} from "../../web3/content";
import {Container, InnerPageWrapper, Wrapper,} from "../../assets/styles/common.style";
import {List} from "../../assets/styles/common.style";
import {ListWrapper} from "../../assets/styles/common.style";
import {Header} from "../../components/Header";
import {Button} from "../../components/basic/Button";
import {Footer} from "../../components/Footer";
import {InnerPageHead} from "../../components/InnerPageHead";
import {Loader} from "../../components/basic/Loader";
import {Popup} from "../../components/Popup";
import {MintLandSection} from "./MintLandSection";
import {useDispatch, useSelector} from "react-redux";
import {removeFromMarket, updateUserBalance} from '../../web3/contracts';
import {CardLand} from "../../components/cards/CardLand";
import {SelectedItemsFooter} from "../../components/selectedItemsFooter/SelectedItemsFooter";
import {transformLand} from "../../web3/transform";
import {DiscoverLandPopup} from "./DiscoverLandPopup";

export const Lands = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const [allLands, setAllLands] = useState({});
  const [userLands, setUserLands] = useState([]);
  const [mintPopupVisible, setMintPopupVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [selectedLands, setSelectedLands] = useState([]);
  const [discoverLandPopupVisible, setDiscoverLandPopupVisible] = useState(false);
  const [discoverLandItem, setDiscoverLandItem] = useState();

  const mintedCount = (landType) => new Promise(async resolve => {
    window.contracts.land.landTypeCount(landType).then(result => {
      resolve(parseInt(result));
    });
  });

  useEffect(() => {
    loadAllLands();
    loadUserLands();
  }, [currentUser]);

  const loadAllLands = async () => {
    const allLands = {};
    const allLandsObj = await window.contracts.land.getAllLands();

    const landDiscountAmountMap = {
      1: 0.5,
      2: 1.5,
      3: 3.5
    }

    const landDiscountCountMap = {
      1: 500,
      2: 400,
      3: 300
    }

    Promise.all([mintedCount(1), mintedCount(2), mintedCount(3)]).then(landsCount => {
      allLandsObj.map((land, index) => {

        let discount = landDiscountAmountMap[index] || 0;
        allLands[landTypeMap[index]] = {
          landType: landTypeMap[index],
          totalCount: land.limitCount.toString(),
          price: convertFromYocto(land.price, 2, false),
          zombiePerDay: land.zombiesPerDay.toString(),
          media: land.media,
          discount: discount
        };
      });

      setAllLands(allLands);
    })
  }

  const loadUserLands = async () => {
    const landsObj = await window.contracts.land.userLands();
    const lands = landsObj.map(land => transformLand(land));
    setUserLands(lands || []);
    setIsReady(true);
  }

  const isMicroLand = () => {
    let result = false;
    userLands.map((land) => {
      if (land.landType === "Micro") {
        result = true;
      }
    });
    return result;
  };

  const watchMintTransaction = (tx) => {
    addPendingTransaction(dispatch, tx, tx.message);
    tx.wait().then(receipt => {
      if (receipt.status === 1) {
        loadUserLands();
      }
    });
  }

  const rmFromMarket = (tokenId) => {
    removeFromMarket(dispatch, tokenId, "land").then(() => {
      loadUserLands();
    });
  }

  const selectLand = (land) => {
    const findItem = selectedLands.findIndex(
      (value) => value.tokenId === land.tokenId
    );
    if (findItem < 0) {
      return setSelectedLands((prevState) => [...prevState, land]);
    }

    return setSelectedLands(
      selectedLands.filter((_, ind) => findItem !== ind)
    );
  };

  const isSelected = (landId) =>
    selectedLands.filter((land) => land.tokenId === landId).length > 0;

  const showMintPopup = async () => {
    setMintPopupVisible(true);
  };

  const discoverLand = (land) => {
    setDiscoverLandPopupVisible(true);
    setDiscoverLandItem(land);
  }

  return (
    <InnerPageWrapper>
      <Header/>

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={LandContent.title}
            description={LandContent.description}
          />

          {isReady && (
            <>
              {!userLands.length || (
                <Button
                  title="Buy More Lands"
                  size="lg"
                  animated
                  noIcon
                  onClick={showMintPopup}
                />
              )}
            </>
          )}

          <ListWrapper>
            {isReady ? (
              <List>
                {userLands.length ? (
                  userLands.map((land, index) => (
                    <CardLand
                      nft={land}
                      key={index}
                      handleSelect={() => selectLand(land)}
                      isSelected={isSelected(land.tokenId)}
                      rmFromMarket={() => rmFromMarket(land.tokenId)}
                      discoverLand={() => discoverLand(land)}
                    />
                  ))
                ) : (
                  <div>
                    <div className="mb-7 leading-10">
                      <b className="text-xl">{LandContent.no_lands}.</b> <br/>
                      <p className="text-cyan-200 leading-6 px-4">
                        {LandContent.no_lands_details}:
                      </p>
                    </div>
                    <MintLandSection
                      isMicroLand={isMicroLand}
                      allLands={allLands}
                      handleSuccessMint={loadUserLands}
                      watchMintTransaction={(tx) => watchMintTransaction(tx)}
                    />
                  </div>
                )}
              </List>
            ) : (
              <Loader/>
            )}
          </ListWrapper>
        </Container>

        <DiscoverLandPopup
          setDiscoverLandPopupVisible={setDiscoverLandPopupVisible}
          discoverLandPopupVisible={discoverLandPopupVisible}
          discoverLandItem={discoverLandItem}
          handleDiscoverFinished={() => {
            setIsReady(false);
            loadUserLands();
            updateUserBalance(dispatch, currentUser.accountId);
          }}
        />

        <Popup
          title="Buy More Lands"
          width={`${!isMicroLand() ? "sm:w-[800px] lg:w-[1060px] max-w-full" : "sm:w-[816px]"}`}
          popupVisible={mintPopupVisible}
          setPopupVisible={setMintPopupVisible}
        >
          <div className="mt-2">
            <MintLandSection
              isMicroLand={isMicroLand}
              allLands={allLands}
              handleSuccessMint={loadUserLands}
              watchMintTransaction={(tx) => {
                watchMintTransaction(tx);
                setMintPopupVisible(false);
              }}
            />
          </div>
        </Popup>
      </Wrapper>

      {selectedLands.length > 0 && (
        <SelectedItemsFooter
          selectedItems={selectedLands}
          nftType="Land"
          deselectItem={selectLand}
          setSelectedItems={setSelectedLands}
          items={userLands}
          handleReloadData={() => {
            loadAllLands();
            loadUserLands();
            setSelectedLands([]);
          }}
        />
      )}

      <Footer/>
    </InnerPageWrapper>
  );
};
