import React, {useContext, useState} from "react";
import {ArrowUpIcon} from "@heroicons/react/solid";
import {Col} from "../../assets/styles/common.style";
import {Popup} from "../Popup";
import {Input} from "../basic/Input";
import {Button, iconMapping} from "../basic/Button";
import {BigNumber, ethers} from "ethers";
import {approveTokenSpend} from "../../web3/contracts";
import {useDispatch, useSelector} from "react-redux";
import {addPendingTransaction, addTransactionError} from "../../web3/utils";

export const UpgradeClanPopup = ({clan, setVisible, visible, handleSuccessUpdate}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const [deposit, setDeposit] = useState("");

  const updateCall = () => {
    window.contracts.clan.upgradeRating(deposit).then(tx => {
      tx.message = "Upgrade Clan rating";
      addPendingTransaction(dispatch, tx, tx.message);

      tx.wait().then(receipt => {
        if (receipt.status === 1) {
          handleSuccessUpdate();
          setVisible(false);
        }
      });
    }).catch(err => {
      addTransactionError(dispatch, err.message);
    });
  }

  const handleUpdate = async () => {
    const allowed = await window.contracts.tokenMTRG.allowance(
      currentUser.accountId,
      window.contracts.clan.address
    );

    const digits = await window.contracts.tokenMTRG.decimals();
    const depositWei = ethers.utils.parseUnits(deposit.toString(), digits);
    if (allowed.lt(BigNumber.from(depositWei))) {
      approveTokenSpend(
        dispatch, window.contracts.tokenMTRG, window.contracts.clan.address, depositWei
      ).then(() => {
        updateCall();
      });
    } else {
      updateCall();
    }
  }

  return (
    <Popup
      title="Upgrade Clan"
      popupVisible={visible}
      setPopupVisible={setVisible}
    >
      <div className="gap-5 px-6 mb-5 flex flex-col">
        <Col className="text-left gap-2">
          <label>Enter amount of {process.env.PAYMENT_TOKEN_SYMBOL}</label>
          <Input
            type="number"
            handleChange={(e) => setDeposit(e.target.value)}
            value={deposit}
          />
        </Col>
        <span className="text-orange-500 font-semibold">
          Rating +{deposit * 100}
        </span>
      </div>
      <Button
        title="UPGRADE CLAN"
        size="md"
        secondary
        disabled={deposit <= 0}
        icon={<ArrowUpIcon className={`ml-2 ${iconMapping["md"]}`}/>}
        onClick={() => handleUpdate()}
      />
    </Popup>
  );
};
