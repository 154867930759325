import React from "react";
import {getMedia} from "../../web3/utils";
import CardRotate, {Rarity} from "../../assets/styles/cardRotate.style";

export const CardLandRegion = ({
                                 region,
                                 noInfo,
                                 selectRegion
                               }) => {
  return (
    <CardRotate.FlipCardModify
      className={`mb-2 mx-auto ${!noInfo ? "cursor-pointer" : ""}`}
      onClick={() => {
        if (!noInfo) {
          selectRegion(region.id);
        }
      }}>
      <CardRotate.Front type={region.color_type}>
        <section className="absolute z-10 left-0 right-0 bottom-0">
          <Rarity type={region.color_type} className={`text-sm pl-2`}>
            {region.title}
          </Rarity>
        </section>
        <img
          className={`h-full w-full`}
          src={getMedia(region.media)}
          alt="Land Region"
        />
      </CardRotate.Front>

      <CardRotate.Back type={region.color_type}>
        <img
          className={`h-full absolute left-0 top-0 opacity-20 scale-x-[-1]`}
          src={getMedia(region.media)}
          alt="Land Region"
        />
        <section className="relative z-10">
          <div className="flex flex-col mt-3 space-y-2 font-normal text-xs pt-16">
            <div className="border-b border-gray-800 pb-2 mb-1">
              <p>Find Zombie Modifier: <b>{region.chances[0]}%</b></p>
              <p>Find Inventory Items: <b>{region.chances[1]}%</b></p>
              <p>Find {process.env.ZML_TOKEN}: <b>{region.chances[2]}%</b></p>
              {/*<p>Part of staking Monster: <b>{region.chances[3]}%</b></p>*/}
            </div>
            <div>
              <p>Wounded by Hunters: <b>{region.chances[5]}%</b></p>
              <p>Killed by Hunters: <b>{region.chances[6]}%</b></p>
              <p>Nothing Found: <b>{region.chances[4]}%</b></p>
            </div>
          </div>
        </section>

      </CardRotate.Back>
    </CardRotate.FlipCardModify>
  );
};
