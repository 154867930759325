import React, {useEffect, useState} from "react";
import {ClansContent} from "../../web3/content";
import {convertFromYocto} from "../../web3/utils";
import {List} from "../../assets/styles/common.style";
import {ListWrapper} from "../../assets/styles/common.style";
import {CreateClanPopup} from "../../components/clan/CreateClanPopup";
import {ClanLine} from "../../components/clan/clan_line/ClanLine";
import {CREATE_CLAN_FEE} from "../../web3/config";
import {useDispatch, useSelector} from "react-redux";
import {getRequest} from "../../web3/api";
import {Header} from "../../components/Header";
import {InnerPageHead} from "../../components/InnerPageHead";
import {Button} from "../../components/basic/Button";
import {Loader} from "../../components/basic/Loader";
import {Footer} from "../../components/Footer";
import {transformClan} from "../../web3/transform";
import {updateUserBalance} from "../../web3/contracts";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../../assets/styles/common.style";

export const Clans = () => {
  const currentUser = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const [alreadyInClan, setAlreadyInClan] = useState(false);
  const [clans, setClans] = useState([]);
  const [myClan, setMyClan] = useState([]);
  const [clansLength, setClansLength] = useState(0);
  const [visible, setVisible] = useState(false);
  const [battlesCount, setBattlesCount] = useState({});

  const fetchClans = async () => {
    const usersResponse = await getRequest("api/users");
    const users = usersResponse.data;
    const [clansTotalCount, clansList] = await window.contracts.clan.getClans();
    const clans = clansList.filter((clan) => parseInt(clan.id) > 0 && !clan.blocked).map(clan => transformClan(clan, users));

    let userClan;
    const [userClanResponse, userInClan] = await window.contracts.clan.getClanByAccountId(currentUser.accountId);
    setAlreadyInClan(!!userInClan);
    if (parseInt(userClanResponse.id) > 0) {
      userClan = transformClan(userClanResponse, users);
    }
    setMyClan(userClan);

    setClans(
      clans
        .sort((a, b) => b.participants.length - a.participants.length)
        .sort((a, b) => b.rating - a.rating)
    );
    setClansLength(parseInt(clansTotalCount));
    setIsReady(true);
  };

  const getBattleCount = async () => {
    const battlesCounter = await getRequest("api/clan-battle/count");
    setBattlesCount(battlesCounter.data);
  };

  useEffect(() => {
    fetchClans();
    getBattleCount();
  }, [currentUser]);

  const alreadyOwnedClan = clans.some((clan) => clan.ownerId === currentUser.accountId);
  const canCreate = CREATE_CLAN_FEE.lt(currentUser.tokenBalance)

  // const handleChangePage = async (page) => {
  //   setCurrentPage(page);
  //   await fetchClans(page);
  // };

  return (
    <InnerPageWrapper>
      <Header/>

      <Wrapper>
        <Container className="text-white text-center flex flex-col items-center mt-6">
          <InnerPageHead
            title={ClansContent.title}
            description={ClansContent.description}
          />

          {!alreadyOwnedClan && !alreadyInClan && (
            <>
              <div className="mb-4">
                <Button
                  disabled={!isReady || !canCreate}
                  title="Create a Clan"
                  noIcon
                  onClick={() => setVisible(true)}
                />
              </div>
              {!canCreate && (
                <p className="text-red-400 mb-2">
                  {`You need ${convertFromYocto(CREATE_CLAN_FEE, 0, true)} ${process.env.ZML_TOKEN} to create a clan`}
                </p>
              )}
            </>
          )}

          {isReady ? (
            <>
              <ListWrapper>
                <div className="mb-10 mx-3">
                  {myClan && (
                    <>
                      <section className="mb-3">
                        <div className="flex flex-row justify-start text-white font-bold">
                          <div className="text-orange-500 text-xl">My Clan</div>
                        </div>
                      </section>
                      <ClanLine
                        current
                        clan={myClan}
                        alreadyInClan={true}
                        battleCount={
                          battlesCount[myClan.id] ? battlesCount[myClan.id] : 0
                        }
                        reloadClanList={() => {
                          fetchClans();
                          updateUserBalance(dispatch, currentUser.accountId);
                        }}
                      />
                    </>
                  )}
                </div>

                <section className="mb-3 mx-3">
                  <div className="flex flex-row justify-between text-white font-bold">
                    <div className="text-orange-500 text-xl">Game Clans</div>
                    <div className="flex md:gap-6 gap-4 pt-1">
                      <p className="text-sm md:text-base">
                        Total Clans: <b>{clansLength}</b>
                      </p>
                      {/*<p className="text-sm md:text-base">*/}
                      {/*  Participants: <b>{participantsLength}</b>*/}
                      {/*</p>*/}
                    </div>
                  </div>
                </section>

                {clans?.length > 0 ? (
                  <List>
                    {clans
                      .filter((clan) => !clan.blocked)
                      .map((clan, index) => {
                        return (
                          <div className="container w-full" key={clan.id}>
                            <ClanLine
                              alreadyInClan={alreadyInClan}
                              clan={clan}
                              index={index}
                              battleCount={
                                battlesCount[clan.id]
                                  ? battlesCount[clan.id]
                                  : 0
                              }
                              reloadClanList={() => {
                                fetchClans();
                                updateUserBalance(dispatch, currentUser.accountId);
                              }}
                            />
                          </div>
                        );
                      })}
                  </List>
                ) : (
                  <p className={"opacity-70"}>*No Clans</p>
                )}
              </ListWrapper>
            </>
          ) : (
            <div className="mt-4">
              <Loader/>
            </div>
          )}
        </Container>
      </Wrapper>

      <CreateClanPopup
        visible={visible}
        setVisible={setVisible}
        reloadClanList={() => fetchClans()}
      />

      <Footer/>
    </InnerPageWrapper>
  );
};
