import React, {useContext, useEffect, useRef} from "react";
import {Link} from "../../assets/styles/common.style";
import {animateScroll, Link as ScrollLink} from "react-scroll";

export const MobileNavLinks = ({
                                 setIsMobileOpened,
                                 onClickOutside,
                                 currentUser,
                                 chainStatus
                               }) => {
  const ref = useRef(null);

  const toggleHome = () => {
    animateScroll.scrollToTop();
  };

  const scrollProps = {
    activeClass: "text-sky-600",
    smooth: true,
    duration: 500,
    spy: true,
    exact: "true",
    offset: -100,
  };

  const menuItem =
    "cursor-pointer border-b font-semibold my-0.5 pb-1 flex justify-between text-sky-800";
  const menuLink = "block hover:text-sky-600";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <>
      {currentUser.accountId && !chainStatus.isError ? (
        <ul ref={ref}>
          <li className="text-center font-semibold mt-2 text-gray-800">
            My Cards
          </li>
          <li className={menuItem}>
            <Link
              className={menuLink}
              to="/lands"
              onClick={() => setIsMobileOpened(false)}
            >
              Lands
            </Link>
            <Link
              className={menuLink}
              to="/zombies"
              onClick={() => setIsMobileOpened(false)}
            >
              Zombies
            </Link>
          </li>
          <li className={menuItem}>
            <Link
              className={menuLink}
              to="/monsters"
              onClick={() => setIsMobileOpened(false)}
            >
              Monsters
            </Link>
            <Link
              className={menuLink}
              to="/monster_parts"
              onClick={() => setIsMobileOpened(false)}
            >
              Monster Parts
            </Link>
          </li>
          <li
            className={`${menuItem} border-b-2 border-dashed border-gray-400 mb-4 pb-3`}
          >
            <Link
              className={menuLink}
              to="/inventory"
              onClick={() => setIsMobileOpened(false)}
            >
              Inventory
            </Link>
            <Link
              className={menuLink}
              to="/modifiers"
              onClick={() => setIsMobileOpened(false)}
            >
              Modifiers
            </Link>
          </li>

          <li className="text-center font-semibold mt-2 text-gray-800">
            Market
          </li>
          <li className={menuItem}>
            <Link
              className={menuLink}
              to="/market/lands"
              onClick={() => setIsMobileOpened(false)}
            >
              Lands
            </Link>
            <Link
              className={menuLink}
              to="/market/zombies"
              onClick={() => setIsMobileOpened(false)}
            >
              Zombies
            </Link>
          </li>
          <li className={menuItem}>
            <Link
              className={menuLink}
              to="/market/monsters"
              onClick={() => setIsMobileOpened(false)}
            >
              Monsters
            </Link>
            <Link
              className={menuLink}
              to="/market/monster_parts"
              onClick={() => setIsMobileOpened(false)}
            >
              Monster Parts
            </Link>
          </li>
          <li
            className={`${menuItem} border-b-2 border-dashed border-gray-400 mb-4 pb-3`}
          >
            <Link
              className={menuLink}
              to="/market/inventory"
              onClick={() => setIsMobileOpened(false)}
            >
              Inventory
            </Link>
            <Link
              className={menuLink}
              to="/market/modifiers"
              onClick={() => setIsMobileOpened(false)}
            >
              Modifiers
            </Link>
          </li>

          <li className="text-center font-semibold mt-2 text-gray-800">Game</li>
          <li className={`${menuItem} pt-2`}>
            <Link
              className={menuLink}
              to="/arena"
              onClick={() => setIsMobileOpened(false)}
            >
              Battle Arena
            </Link>
          </li>
          <li
            className={`${menuItem} border-b-2 border-dashed border-gray-400 mb-4 pb-3`}
          >
            <Link
              className={menuLink}
              to="/clans"
              onClick={() => setIsMobileOpened(false)}
            >
              Clans
            </Link>
            <Link
              className={menuLink}
              to="/roulette"
              onClick={() => setIsMobileOpened(false)}
            >
              Roulette
            </Link>
          </li>
          <li className={`${menuItem} pt-2`}>
            <Link
              className={menuLink}
              to="/token"
              onClick={() => setIsMobileOpened(false)}
            >
              Staking
            </Link>
          </li>
          <li className="text-center font-semibold mt-2 text-gray-800">More</li>
          <li className={menuItem}>
            <Link
              className={menuLink}
              to="/token"
              onClick={() => setIsMobileOpened(false)}
            >
              Staking
            </Link>
            <Link
              className={menuLink}
              to="/store/inventory"
              onClick={() => setIsMobileOpened(false)}
            >
              Inventory Store
            </Link>
          </li>
          <li
            className={`${menuItem} border-b-2 border-dashed border-gray-400 mb-4 pb-3`}
          >
            <Link
              className={menuLink}
              to="/leaderboard"
              onClick={() => setIsMobileOpened(false)}
            >
              Leaderboard
            </Link>
            <Link
              className={menuLink}
              to="/stats"
              onClick={() => setIsMobileOpened(false)}
            >
              Statistics
            </Link>
          </li>
          <li className={menuItem}>
            <Link
              className={menuLink}
              to="/faq"
              onClick={() => setIsMobileOpened(false)}
            >
              FAQ
            </Link>
          </li>
        </ul>
      ) : (
        <ul ref={ref}>
          <li className={menuItem}>
            <div className={menuLink} onClick={() => window.web3Login()}>
              Login
            </div>
          </li>

          <li className={menuItem}>
            <ScrollLink
              className={menuLink}
              to="/"
              onClick={() => {
                setIsMobileOpened(false);
                toggleHome();
              }}
            >
              Home
            </ScrollLink>
          </li>
          <li className={menuItem}>
            <ScrollLink
              className={menuLink}
              to="how_to_play"
              {...scrollProps}
              onClick={() => setIsMobileOpened(false)}
            >
              How to Play
            </ScrollLink>
          </li>
          <li className={menuItem}>
            <ScrollLink
              className={menuLink}
              to="tokenomic"
              {...scrollProps}
              onClick={() => setIsMobileOpened(false)}
            >
              Tokenomic
            </ScrollLink>
          </li>
          <li className={menuItem}>
            <ScrollLink
              className={menuLink}
              to="roadmap"
              {...scrollProps}
              onClick={() => setIsMobileOpened(false)}
            >
              Roadmap
            </ScrollLink>
          </li>
          <li className={menuItem}>
            <ScrollLink
              className={menuLink}
              to="partners"
              {...scrollProps}
              onClick={() => setIsMobileOpened(false)}
            >
              Partners
            </ScrollLink>
          </li>
          <li className={menuItem}>
            <ScrollLink
              className={menuLink}
              to="contact_us"
              {...scrollProps}
              onClick={() => setIsMobileOpened(false)}
            >
              Contact Us
            </ScrollLink>
          </li>
        </ul>
      )}
    </>
  );
};
