import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  Market,
  Monsters,
  Faq,
  Landing,
  Lands,
  Zombies,
  Terms,
  Privacy,
  Token,
  Collections,
  OneCollection,
} from "./pages";
import {
  web3Handler,
  isMetamaskInstalled,
  updateUserAccount,
  checkNetwork,
} from "./web3/contracts";
import { TransactionList } from "./components/TransactionList";
import { useDispatch } from "react-redux";
import { addTransactionError } from './web3/utils';
import { setIsChainError } from './store/chainSlice';
import { Leaderboard } from './pages/Leaderboard';
import { MigrateNFT } from "./pages/Migrate";
import { Clans } from "./pages/clans/Clans";
import { Clan } from "./pages/clans/Clan";
import { Inventory } from "./pages/inventory/Inventory";
import { Modifiers } from "./pages/modifiers/Modifiers";
import { updateUserRating } from "./web3/api";
import { Stats } from "./pages/Stats";
import { BattleNotifications } from "./components/BattleNotifications";
import { Roulette } from "./pages/Roulette";

const localStorageKey = "zomland:is-auto-login"

export default function App() {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = React.useState(false);

  const loadUserData = async (account) => {
    await updateUserAccount(dispatch, account);
    await updateUserRating(dispatch, account);
  }

  window.web3Login = () => {
    if (window.location.pathname === '/') {
      setIsReady(true);
    }

    // Check network
    if (isMetamaskInstalled()) {
      checkNetwork().then(isCorrect => {
        dispatch(setIsChainError({isError: !isCorrect}));
      });

      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
    } else {
      setIsReady(true);
    }

    if (window.location.pathname === "/") {
      setIsReady(true);
    }

    web3Handler().then(({account}) => {
        setIsReady(true);

        loadUserData(account)
          .then(() => {
            window.ethereum.on("accountsChanged", (accounts) => {
              updateUserAccount(dispatch, accounts[0]);
              updateUserRating(dispatch, accounts[0]);
            });

            localStorage.setItem(localStorageKey, "true");

          })
          .catch((e) => {
            console.log(`Error x1:`, e);
          });
      }
    ).catch((e) => {
      console.log(`Error x2`, e);

      if (!isMetamaskInstalled()) {
        addTransactionError(dispatch, "Please install Metamask.")
      }

      let allowPathList = [
        "/",
        "/terms-conditions",
        "/privacy-policy",
        "/faq",
      ];
      if (allowPathList.indexOf(window.location.pathname) === -1) {
        window.location.href = "/";
      } else {
        setIsReady(true);
      }
    });
  };

  React.useEffect(() => {
    // if (localStorage.getItem(localStorageKey)) {
    // }
    window.web3Login();
  }, []);

  return (
    <BrowserRouter>
      {isReady && (
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Landing />
            }
          />
          <Route exact path="/lands" element={<Lands />} />
          <Route exact path="/zombies" element={<Zombies />} />
          <Route exact path="/collections" element={<Collections />} />
          <Route exact path="/collections/:collection_id" element={<OneCollection />} />
          <Route exact path="/monsters" element={<Monsters />} />
          <Route exact path="/inventory" element={<Inventory />} />
          <Route exact path="/modifiers" element={<Modifiers />} />
          <Route exact path="/market" element={<Market />} />
          <Route exact path="/market/:section" element={<Market />} />

          <Route exact path="/clans" element={<Clans />} />
          <Route exact path="/clans/:id" element={<Clan />} />
          <Route exact path="/stats" element={<Stats />} />
          <Route exact path="/roulette" element={<Roulette />} />

          <Route exact path="/token" element={<Token />} />
          <Route exact path="/leaderboard" element={<Leaderboard />} />
          <Route exact path="/migrate" element={<MigrateNFT />} />

          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/terms-conditions" element={<Terms />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      <TransactionList />
      <BattleNotifications />
    </BrowserRouter>
  );
}
