import {useContext, useEffect, useState} from "react";
import {getRequest} from '../web3/api';
import {XIcon} from '@heroicons/react/solid';
import {useNavigate} from 'react-router-dom';
import {Badge} from "./basic/Badge";
import {useSelector} from "react-redux";

export const BattleNotifications = () => {
    const navigate = useNavigate();
    const currentUser = useSelector(state => state.user.user);
    const [battleNotifications, setBattleNotifications] = useState([]);
    const [userClan, setUserClan] = useState();

    const checkBattleResults = async () => {
      const notifications = await getRequest(`api/clan-battle/notifications/${currentUser.accountId}`);

      let uniqueNotification = {};
      if (notifications) {
        notifications.data.notifications.map(notify => {
          if (!uniqueNotification[notify.clanBattleId]) {
            uniqueNotification[notify.clanBattleId] = notify;
          } else {
            uniqueNotification[notify.clanBattleId].clanBattle.entryFee += notify.clanBattle.entryFee;
          }
        });

        setBattleNotifications(Object.values(uniqueNotification));
        setUserClan(notifications.data.userClan);
      }
    }

    const closeNotification = (id) => {
      setBattleNotifications(battleNotifications.filter(n => n.id !== id));
    }

    const openBattle = (notificationId, id) => {
      navigate(`/clans/${userClan.id}?current_tab=BattleHistory&view=${id}`);
      closeNotification(notificationId);
    }

    useEffect(() => {
      if (currentUser.accountId) {
        checkBattleResults();

        // Check battle results each 3 minutes
        setInterval(() => {
          checkBattleResults();
        }, 3 * 60000);
      }
    }, [currentUser.accountId]);

    const isWinner = (battle) => {
      return battle.clanBattle.winnerClanId === userClan.id;
    }

    const getBattleSize = (notification) => {
      const half = notification.clanBattle.requiredSize / 2;
      return `${half}x${half}`;
    }

    const getBattleResultZML = (notification) => {
      const fee = notification.clanBattle.entryFee;
      if (!isWinner(notification)) {
        return `-${fee} ${process.env.ZML_TOKEN}`;
      }
      return `+${fee + fee * 0.95} ${process.env.ZML_TOKEN}`;
    }


    return (
      <>
        <div className={`top-4 right-4 fixed px-2 md:pb-2 py-2 z-40`}>
          {battleNotifications.map(notification => (
            <div key={notification.id}
                 className="bg-gray-800 border-2 border-gray-600/30 text-white rounded-md px-6 py-4 mb-2 w-[400px] shadow-2xl
                 text-sm relative hover:bg-gray-700 cursor-pointer transition">
              <XIcon
                className="h-5 w-5 absolute right-6 top-4 hover:opacity-70 transition duration-200"
                onClick={() => closeNotification(notification.id)}
              />
              <div onClick={() => openBattle(notification.id, notification.clanBattleId)}>
                <p className="mb-1 mt-1 font-semibold">Battle #{notification.clanBattleId} completed:</p>
                <b className={`text-base ${isWinner(notification) ? "text-orange-400" : "text-red-400"}`}>
                  Clan "{userClan.name}" {isWinner(notification) ? "won!" : "lost."}
                </b>
                <Badge
                  text={getBattleResultZML(notification)}
                  bgColor={isWinner(notification) ? "bg-orange-500" : "bg-red-500"}
                  customClassName="text-xxs truncate text-white mt-3"
                />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
;
