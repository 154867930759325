import React, {useEffect, useState} from "react";
import {InventoryContent} from "../../web3/content";
import {inventoryOptions, inventoryTypes} from "../../web3/utils";
import {ListWrapper} from "../../assets/styles/common.style";
import {useNavigate} from "react-router-dom";
import {
  Container,
  InnerPageWrapper,
  ListSmall,
  Wrapper,
} from "../../assets/styles/common.style";
import {getRequest} from "../../web3/api";
import {useDispatch, useSelector} from "react-redux";
import {transformItem} from "../../web3/transform";
import {removeFromMarket, updateUserBalance} from "../../web3/contracts";
import {Header} from "../../components/Header";
import {InnerPageHead} from "../../components/InnerPageHead";
import {Dropdown} from "../../components/basic/Dropdown";
import {CardItem} from "../../components/cards/CardItem";
import {Pagination} from "../../components/Pagination";
import {SelectedItemsFooter} from "../../components/selectedItemsFooter/SelectedItemsFooter";
import {Loader} from "../../components/basic/Loader";
import {Footer} from "../../components/Footer";

const PAGE_LIMIT = 8;

export const Inventory = () => {
  const currentUser = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const [userInventory, setUserInventory] = useState([0, []]); // [<count>, [<arrayOfItems>]]
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  async function fetchUserInventory(currentPage, itemType) {
    const itemsResponse = await window.contracts.inventory.userItems(currentPage, PAGE_LIMIT, itemType);
    let inventory = itemsResponse[1].filter(item => item.nftType > 0).map(item => transformItem(item)).reverse();

    const itemsResp = await getRequest("api/items", {
      ids: inventory.map((item) => item.tokenId),
    });

    if (itemsResp.data) {
      // Convert price from Yocto NEAR and add durability
      inventory = inventory.map((item) => {
        let itemDB = itemsResp.data.find((i) => parseInt(i.itemId) === parseInt(item.tokenId));

        // let transformedItem = transformItem(item);
        item.durabilityMin = itemDB?.durabilityMin;
        item.durabilityMax = itemDB?.durabilityMax;
        item.level = itemDB?.level;

        return item;
      });

      setUserInventory([parseInt(itemsResponse[0]), inventory]);
    }

    setIsReady(true);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const page = JSON.parse(searchParams.has("page"))
      ? parseInt(searchParams.get("page"))
      : currentPage;
    const itemType = JSON.parse(searchParams.has("type"))
      ? searchParams.get("type")
      : filterType;

    setCurrentPage(page);
    setFilterType(itemType);
    fetchUserInventory(page, itemType);
  }, []);

  useEffect(() => {
    if (isReady) {
      setCurrentPage(1);
      fetchUserInventory(1, filterType);
      navigate(buildUrl());
    }
  }, [filterType]);

  useEffect(() => navigate(buildUrl()), [currentPage]);

  const buildUrl = () => {
    let url = `/inventory?page=${currentPage}`;
    if (filterType) url = `${url}&type=${filterType}`;
    return url;
  };

  const onPageChanged = (page) => {
    window.scrollTo({top: 0, behavior: "smooth"});
    setCurrentPage(page);
    fetchUserInventory(page, filterType);
  };

  const rmFromMarket = async (item) => {
    removeFromMarket(dispatch, item.tokenId, "inventory").then(() => {
      fetchUserInventory(currentPage, filterType);
    });
  };

  const handleReloadData = () => {
    setSelectedItems([]);
    fetchUserInventory(currentPage, filterType);
    updateUserBalance(dispatch, currentUser.accountId);
  };

  const selectItem = (item) => {
    const findItem = selectedItems.findIndex(
      (value) => value.tokenId === item.tokenId
    );
    if (findItem < 0)
      return setSelectedItems((prevState) => [...prevState, item]);
    return setSelectedItems(selectedItems.filter((_, ind) => findItem !== ind));
  };

  const isSelected = (itemId) =>
    selectedItems.filter((item) => item.tokenId === itemId).length > 0;

  return (
    <InnerPageWrapper>
      <Header/>

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={InventoryContent.title}
            description={InventoryContent.description}
          />

          {isReady ? (
            <>
              <div className="flex justify-between mt-8 z-30 relative">
                <div className="basis-1/2 text-lg text-left pt-2 pl-1">
                  Available:
                  <span className="ml-2 font-semibold text-orange-500">
                    {userInventory[0]} item{userInventory[0] !== 1 ? "s" : ""}
                  </span>
                </div>

                <div className="basis-1/2 z-10 text-right">
                  <div className="inline-block mr-1">
                    <Dropdown
                      title="Item Type"
                      selected={inventoryTypes[filterType - 1]}
                      options={inventoryOptions(setFilterType)}
                    />
                  </div>
                </div>
              </div>

              <ListWrapper>
                {userInventory[0] > 0 ? (
                  <ListSmall>
                    {userInventory[1]?.map((item) => (
                      <CardItem
                        isItem
                        item={item}
                        key={item.tokenId}
                        rmFromMarket={() => rmFromMarket(item)}
                        handleSelect={() => selectItem(item)}
                        isSelected={isSelected(item.tokenId)}
                      />
                    ))}
                  </ListSmall>
                ) : (
                  <div>You don't have {filterType > 0 && inventoryTypes[filterType - 1]} Inventory items.</div>
                )}
              </ListWrapper>

              {filterType > 0 && (
                <div className="mt-10">
                  <a
                    className="link cursor-pointer"
                    onClick={() => setFilterType(null)}
                  >
                    Reset Filters
                  </a>
                </div>
              )}

              <div className="mb-8">
                <Pagination
                  total={userInventory[0]}
                  limit={PAGE_LIMIT}
                  selectedPage={currentPage}
                  onPageChanged={onPageChanged}
                />
              </div>
            </>
          ) : (
            <Loader/>
          )}
        </Container>
      </Wrapper>

      {selectedItems.length > 0 && (
        <SelectedItemsFooter
          upgradable
          selectedItems={selectedItems}
          nftType="Inventory"
          deselectItem={selectItem}
          setSelectedItems={setSelectedItems}
          items={userInventory[1]}
          handleReloadData={handleReloadData}
        />
      )}

      <Footer/>
    </InnerPageWrapper>
  );
};
