import React, {useEffect, useState} from "react";
import {
  NavLink,
  ScrollLink,
} from "../../assets/styles/common.style";
import {animateScroll} from "react-scroll";
import {useLocation, useNavigate} from "react-router-dom";
import {Dropdown} from "../basic/Dropdown";

export const NavLinks = ({currentUser, chainStatus}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isMore = () => {
    return ["/leaderboard", "/stats", "/token", "/faq"].includes(
      location.pathname
    );
  };
  const isGame = () => {
    return ["/clans", "/arena", "/roulette"].includes(location.pathname);
  };

  const isMarket = () => {
    return location.pathname.includes("/market/");
  };

  const isStore = () => {
    return location.pathname.includes("/shop/");
  };

  const isMyNFT = () => {
    return (
      !isMarket() &&
      !isStore() &&
      [
        "/lands",
        "/zombies",
        "/monsters",
        "/monster_parts",
        "/inventory",
        "/modifiers",
      ].includes(location.pathname)
    );
  };

  const currentTitle = (requireIsMarket) => {
    if (isMarket() === requireIsMarket) {
      if (location.pathname.includes("lands")) {
        return "Lands";
      } else if (location.pathname.includes("zombies")) {
        return "Zombies";
      } else if (location.pathname.includes("monsters")) {
        return "Monsters";
      } else if (location.pathname.includes("monster_parts")) {
        return "Monster Parts";
      } else if (location.pathname.includes("inventory")) {
        return "Inventory";
      } else if (location.pathname.includes("modifiers")) {
        return "Modifiers";
      }
    }
  };

  const toggleHome = () => {
    animateScroll.scrollToTop();
  };

  const scrollProps = {
    activeClass: "border-solid border-2 border-purple-500 text-purple-500",
    smooth: true,
    duration: 500,
    spy: true,
    exact: "true",
    offset: -100,
  };

  return (
    <>
      {currentUser.accountId && !chainStatus.isError ? (
        <>
          <Dropdown
            title="My Cards"
            noBorder={!isMyNFT()}
            textColor={`${isMyNFT() ? "text-purple-500" : ""}`}
            selected={currentTitle(false)}
            showTitle={true}
            options={[
              {
                onClick: () => navigate("/lands"),
                title: "Lands",
              },
              {
                onClick: () => navigate("/zombies"),
                title: "Zombies",
              },
              {
                onClick: () => navigate("/monsters"),
                title: "Monsters",
              },
              // {
              //   onClick: () => navigate("/monster_parts"),
              //   title: "Monster Parts",
              // },
              {
                onClick: () => navigate("/inventory"),
                title: "Inventory",
              },
              {
                onClick: () => navigate("/modifiers"),
                title: "Modifiers",
              },
            ]}
          />
          <Dropdown
            title="Market"
            noBorder={!isMarket()}
            textColor={`${isMarket() ? "text-purple-500" : ""}`}
            selected={currentTitle(true)}
            showTitle={true}
            options={[
              {
                onClick: () => navigate("/market/lands"),
                title: "Lands",
              },
              {
                onClick: () => navigate("/market/zombies"),
                title: "Zombies",
              },
              {
                onClick: () => navigate("/market/monsters"),
                title: "Monsters",
              },
              // {
              //   onClick: () => navigate("/market/monster_parts"),
              //   title: "Monster Parts",
              // },
              {
                onClick: () => navigate("/market/inventory"),
                title: "Inventories",
              },
              {
                onClick: () => navigate("/market/modifiers"),
                title: "Modifiers",
              },
            ]}
          />
          <Dropdown
            title="Game"
            noBorder={!isGame()}
            textColor={`${isGame() ? "text-purple-500" : ""}`}
            options={[
              {
                onClick: () => navigate("/clans"),
                title: "Clans",
              },
              // {
              //   onClick: () => navigate("/arena"),
              //   title: "Battle Arena",
              // },
              {
                onClick: () => navigate("/roulette"),
                title: "Roulette",
              },
            ]}
          />
          <NavLink to="/collections">Collections</NavLink>
          <Dropdown
            title="More"
            noBorder={!isMore()}
            textColor={`${isMore() ? "text-purple-500" : ""}`}
            options={[
              // {
              //   onClick: () => navigate("/store/inventory"),
              //   title: "Inventory Store",
              // },
              {
                onClick: () => navigate("/leaderboard"),
                title: "Leaderboard",
              },
              {
                onClick: () => navigate("/stats"),
                title: "Statistics",
              },
              {
                onClick: () => navigate("/token"),
                title: "Staking",
              },
              {
                onClick: () => navigate("/faq"),
                title: "FAQ",
              },
            ]}
          />
        </>
      ) : (
        <>
          <ScrollLink to="/" onClick={toggleHome}>
            Home
          </ScrollLink>
          <ScrollLink to="how_to_play" {...scrollProps}>
            How to play
          </ScrollLink>
          <ScrollLink to="tokenomic" {...scrollProps}>
            Tokenomic
          </ScrollLink>
          <ScrollLink to="roadmap" {...scrollProps}>
            Roadmap
          </ScrollLink>
          <ScrollLink to="contact_us" {...scrollProps}>
            Contact Us
          </ScrollLink>
        </>
      )}
    </>
  );
};
