import React, {useContext, useState} from "react";
import {Popup} from "../Popup";
import {Button} from "../basic/Button";
import {Input} from "../basic/Input";
import {addPendingTransaction, addTransactionError} from "../../web3/utils";
import {useDispatch, useSelector} from "react-redux";

export const TransferNftPopup = (
  {
    transferPopupVisible,
    setTransferPopupVisible,
    nftList,
    nftType,
    handleReloadData,
  }) => {
  const currentUser = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const [transferAddress, setTransferAddress] = useState("");

  const transferNFTList = async (tokenIdList) => {
    return new Promise(async (resolve) => {
      try {
        console.log(`nftType.toLowerCase()`, nftType.toLowerCase());
        await window.contracts[nftType.toLowerCase()].batchTransferToWallet(
          transferAddress,
          tokenIdList
        ).then(transaction => {
          addPendingTransaction(dispatch, transaction, `Transfer ${nftType} NFT`);
          transaction.wait().then(receipt => {
            if (receipt.status === 1) {
              resolve();
            }
          });
        });
      } catch (e) {
        addTransactionError(dispatch, e.message);
      }
    });
  }

  const handleTransfer = async () => {
    if (transferAddress.length > 30 && transferAddress !== currentUser.accountId) {
      try {
        const idList = nftList.map((nft) => nft.tokenId);
        transferNFTList(idList).finally(() => {
          setTransferPopupVisible(false);
          handleReloadData();
        });
      } catch (e) {
        console.error(`Error`, e);
      }
    } else {
      alert("Error: Wrong NEAR Transfer Address");
    }
  };

  return (
    <Popup
      title={`Transfer ${nftType}${nftList.length > 1 ? "s" : ""}`}
      popupVisible={transferPopupVisible}
      setPopupVisible={setTransferPopupVisible}
    >
      <div className="mt-2 px-6 mx-auto md:w-2/3 text-center">
        <p className="mt-4 mb-6">
          You can transfer this {nftType}{nftList.length > 1 ? "s" : ""} to any wallet.
        </p>
        {/*<p className="mb-6">Transfer fee: 0.005 {process.env.PAYMENT_TOKEN_SYMBOL}/{nftType}.</p>*/}
        <p className="mb-6">
          <Input
            placeholder="Wallet address"
            value={transferAddress}
            handleChange={(e) => setTransferAddress(e.target.value)}
          />
        </p>
        <Button title={`Transfer ${nftList.length} ${nftType}${nftList.length > 1 ? "s" : ""}`}
                onClick={handleTransfer}/>
      </div>
    </Popup>
  );
};
