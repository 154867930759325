import React, {useEffect, useState} from "react";
import {
  collectionOptions, inventoryOptions, inventoryTypes,
  isOwner,
  landTypeOptions, modifierOptions, modifierTypes,
  nftTypeMap,
  rarityOptions,
} from "../../web3/utils";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
  ListWrapper,
  List
} from "../../assets/styles/common.style";
import history from "../../assets/images/history.png";
import {MarketContent} from "../../web3/content";
import {InnerPageHead} from "../../components/InnerPageHead";
import {Header} from "../../components/Header";
import {Footer} from "../../components/Footer";
import {Loader} from "../../components/basic/Loader";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Pagination} from '../../components/Pagination';
import MarketHistoryPopup from './MarketHistoryPopup';
import {Dropdown} from "../../components/basic/Dropdown";
import {CardLand} from "../../components/cards/CardLand";
import {CardRotate, formatCharacteristicOfCard} from "../../components/cards/CardRotate";
import {BuyItemsFooter} from "../../components/selectedItemsFooter/BuyItemsFooter";
import {removeFromMarket} from "../../web3/contracts";
import {transformCollections, transformItem, transformLand, transformModifier, transformMonster, transformZombie} from "../../web3/transform";
import {CardItem} from "../../components/cards/CardItem";
import {BoltIcon} from "../../components/basic/BoltIcon";
import {Button} from "../../components/basic/Button";

export const Market = () => {
  const {section} = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);

  const [isReady, setIsReady] = useState(false);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterRarity, setFilterRarity] = useState(null);
  const [filterCollection, setFilterCollection] = useState(null);
  const [filterLandType, setFilterLandType] = useState(null);
  const [filterType, setFilterType] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);
  const [reverse, setReverse] = useState(true);
  const [allCollections, setAllCollections] = useState([]);
  const [marketHistoryVisible, setMarketHistoryVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    Land: [],
    Zombie: [],
    Monster: [],
    MonsterPart: [],
    Inventory: [],
    Modifier: [],
  });

  const navigate = useNavigate();
  const PAGE_LIMIT = 8;

  const showMarket = async (
    currentSection, rarity, collection, itemType, page = 1
  ) => {
    setIsReady(false);

    let saleItems;
    let saleItemsCount;
    const callContract = currentSection === 'inventory' ? currentSection : currentSection.slice(0, -1);
    const start_index = (page - 1) * PAGE_LIMIT;

    if (currentSection === "lands") {
      let lands = await window.contracts[callContract].getMarketItems(
        start_index,
        PAGE_LIMIT,
        itemType || "",
      );
      saleItems = lands[1].filter(ln => ln.landType).map(land => transformLand(land));
      saleItemsCount = lands[0];
    } else if (currentSection === "inventory" || currentSection === "modifiers") {
      let items = await window.contracts[callContract].getMarketItems(
        start_index,
        PAGE_LIMIT,
        itemType || 0,
      );
      if (currentSection === "inventory") {
        saleItems = items[1].filter(i => i.nftType).map(item => transformItem(item));
      } else if (currentSection === "modifiers") {
        saleItems = items[1].filter(md => md.nftType).map(modifier => transformModifier(modifier));
      } else {
        console.error('No Transformer')
      }
      saleItemsCount = items[0];
    } else {
      let items = await window.contracts[callContract].getMarketItems(
        start_index,
        PAGE_LIMIT,
        rarity || "",
        collection || "",
      );
      if (currentSection === "zombies") {
        saleItems = items[1].filter(zm => zm.nftType).map(zombie => transformZombie(zombie));
      } else if (currentSection === "monsters") {
        saleItems = items[1].filter(mn => mn.nftType).map(monster => transformMonster(monster));
      } else {
        console.error('No Transformer')
      }
      saleItemsCount = items[0];
    }

    setItems(saleItems);
    setItemsCount(parseInt(saleItemsCount));
    navigate(buildUrl(currentSection, rarity, collection, itemType, page));
    setIsReady(true);
  };

  const getCurrentSection = () => {
    return section || "zombies";
  };

  useEffect(() => {
    if (isReady) {
      setCurrentPage(1);
      showMarket(getCurrentSection(), filterRarity, filterCollection, section === "lands" ? filterLandType : filterType, 1);
      navigate(buildUrl(getCurrentSection(), filterRarity, filterCollection, section === "lands" ? filterLandType : filterType, 1));
    }
  }, [filterRarity, filterCollection, filterLandType, filterType]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = JSON.parse(searchParams.has("page"))
      ? searchParams.get("page")
      : currentPage;
    const rarity = JSON.parse(searchParams.has("rarity"))
      ? searchParams.get("rarity")
      : filterRarity;
    const collection = JSON.parse(searchParams.has("collection"))
      ? searchParams.get("collection")
      : filterCollection;
    const landType = JSON.parse(searchParams.has("land_type"))
      ? searchParams.get("land_type")
      : filterRarity;
    let itemType = JSON.parse(searchParams.has("item_type"))
      ? parseInt(searchParams.get("item_type"))
      : filterType;

    setCurrentPage(page);
    setFilterRarity(rarity);
    setFilterCollection(collection);
    setFilterLandType(landType);
    setFilterType(itemType);

    resetSelectedItems();
    fetchCollections();
    showMarket(getCurrentSection(), rarity, collection, landType, page);
  }, [section]);

  async function fetchCollections() {
    const collectionsObj = await window.contracts.collection.getAllCollections();
    const collections = collectionsObj[1].map((collection, index) => transformCollections(collection, index));
    setAllCollections(collections);
  }

  const buildUrl = (section, rarity, collection, filterType, page = 1) => {
    let url = `/market/${section}?page=${page}`;
    if (section === "lands") {
      if (filterType) url = `${url}&land_type=${filterType}`;
    } else if (section === "inventory" || section === "modifiers") {
      if (filterType) url = `${url}&item_type=${filterType}`;
    } else {
      if (rarity) url = `${url}&rarity=${rarity}`;
      if (collection) url = `${url}&collection=${collection}`;
    }
    return url;
  };

  const onPageChanged = (page) => {
    window.scrollTo({top: 0, behavior: "smooth"});

    setCurrentPage(page);
    if (isReady) {
      showMarket(getCurrentSection(), filterRarity, filterCollection, section === "lands" ? filterLandType : filterType, page);
    }
  };

  const resetFilters = () => {
    setCurrentPage(1);
    setFilterRarity(null);
    setFilterCollection(null);
    setFilterLandType(null);
    setFilterType(0);
  };

  const isSelectedAny = () => {
    let isAnySelected = false;
    Object.keys(selectedItems).map((itemType) => {
      if (selectedItems[itemType].length > 0) {
        isAnySelected = true;
      }
    });
    return isAnySelected;
  };

  const selectToBuy = async (itemList, isAlert = true) => {
    itemList.map((item) => {
      if (item.ownerId !== currentUser.accountId) {
        const nftTypeStr = nftTypeMap[item.nftType];
        const findItem = selectedItems[nftTypeStr].findIndex(
          (value) => value.tokenId === item.tokenId
        );

        if (findItem < 0) {
          let totalSelected = 0;
          Object.keys(selectedItems).map((itemType) => {
            totalSelected += selectedItems[itemType].length;
          });
          if (totalSelected >= 100) {
            if (isAlert) {
              alert(
                "Sorry, purchase is limited to 100 Items per transaction"
              );
            }
            return false;
          }

          selectedItems[nftTypeStr].push(item);
        } else {
          selectedItems[nftTypeStr] = selectedItems[nftTypeStr].filter(
            (_, ind) => findItem !== ind
          );
        }
      }
    });

    setSelectedItems({...selectedItems});
  };

  const isSelected = (item) => {
    return selectedItems[nftTypeMap[item.nftType]]?.filter(
      (value) => value.tokenId === item.tokenId
    ).length > 0
  }

  const handleSuccessBuy = async () => {
    showMarket(getCurrentSection(), filterRarity, filterCollection, section === "lands" ? filterLandType : filterType, 1);
    navigate(buildUrl(getCurrentSection(), filterRarity, filterCollection, section === "lands" ? filterLandType : filterType, 1));

    resetSelectedItems();
  };

  const resetSelectedItems = () => {
    setSelectedItems({
      Land: [],
      Zombie: [],
      Monster: [],
      MonsterPart: [],
      Inventory: [],
      Modifier: [],
    });
  }

  const rmFromMarketHandle = (item) => {
    removeFromMarket(dispatch, item.tokenId, nftTypeMap[item.nftType]).then(() => {
      setIsReady(false);
      
      // document.location.reload();
      if (section === "lands") {
        showMarket(
          section,
          filterRarity,
          filterCollection,
          filterLandType,
          currentPage
        );
      } else if (section === "zombies") {
        showMarket(
          section,
          filterRarity,
          filterCollection,
          filterType,
          currentPage
        );
      } else if (section === "monsters") {
        showMarket(
          section,
          filterRarity,
          filterCollection,
          filterType,
          currentPage
        );
      } else if (section === "inventory") {
        showMarket(
          section,
          filterRarity,
          filterCollection,
          filterType,
          currentPage
        );
      } else if (section === "modifiers") {
        showMarket(
          section,
          filterRarity,
          filterCollection,
          filterType,
          currentPage
        );
      } else if (section === "monster_parts") {
        showMarket(
          section,
          filterRarity,
          filterCollection,
          filterType,
          currentPage
        );
      }
    });
  }

  const power = (nft) =>
    formatCharacteristicOfCard(nft)
      .map((item) => item.value)
      .reduce((prev, curr) => prev + curr, 0);

  const sortByPower = () => {
    const _items = items.sort((a, b) =>
      reverse ? power(b) - power(a) : power(a) - power(b)
    );
    setReverse(!reverse);
    setItems(_items);
  }

  return (
    <InnerPageWrapper>
      <Header/>

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={`${getCurrentSection().replace("_", " ")} ${MarketContent.title}`}
            description={MarketContent.description}
          />

          <div className="mb-10 w-full">
            <div className="md:flex justify-center relative z-20">

              <div className="flex justify-start align-middle items-center lg:w-1/2">
                <div className="flex align-middle items-center">
                  <div
                    className="flex px-3 justify-around items-center border-2 border-orange-500 rounded-lg
                      pt-0.5 h-12 w-32 cursor-pointer text-center hover:text-orange-400 transition"
                    onClick={() => setMarketHistoryVisible(true)}>
                    <img
                      src={history}
                      alt="Market History"
                      title="Market History"
                      className="h-7 mb-1"
                    />
                    <span className="inline-block font-semibold">History</span>
                  </div>

                  <div className="pt-1 ml-6">
                    <span>Total:</span>
                    <span className="ml-2 font-semibold text-orange-500">
                        {itemsCount} items
                      </span>
                  </div>
                </div>
              </div>

              {/*<div className="flex justify-end align-middle lg:w-1/2 my-5">*/}
              {/*  <div className="flex z-10 justify-center md:text-right">*/}
              {/*    {*/}
              {/*      section === "lands" ? (*/}
              {/*        <div className="inline-block mx-3">*/}
              {/*          <Dropdown*/}
              {/*            title="Land Type"*/}
              {/*            selected={filterLandType}*/}
              {/*            options={landTypeOptions(setFilterLandType)}*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*      ) : (*/}
              {/*        <>*/}
              {/*          <div className="inline-block mx-3">*/}
              {/*            <Dropdown*/}
              {/*              title="Rarity"*/}
              {/*              selected={filterRarity}*/}
              {/*              options={rarityOptions(setFilterRarity)}*/}
              {/*            />*/}
              {/*          </div>*/}

              {/*          <div className="inline-block">*/}
              {/*            <Dropdown*/}
              {/*              title="Collection"*/}
              {/*              selected={*/}
              {/*                filterCollection*/}
              {/*                  ? allCollections[filterCollection]?.title*/}
              {/*                  : null*/}
              {/*              }*/}
              {/*              options={collectionOptions(allCollections, setFilterCollection)}*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*        </>*/}
              {/*      )*/}
              {/*    }*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="flex justify-end align-middle lg:w-1/2 my-5">
                <div className="md:flex z-10 justify-end text-right">

                  {section === "lands" && (
                    <div className="inline-block mx-3">
                      <Dropdown
                        title="Land Type"
                        selected={filterLandType}
                        options={landTypeOptions(setFilterLandType)}
                      />
                    </div>
                  )}

                  {(section === "zombies" ||
                    section === "monsters" ||
                    section === "monster_parts") && (
                    <>
                      <div className="inline-block">
                        <Button
                          title=""
                          secondary
                          size="sm"
                          icon={
                            <div className="py-1">
                              <BoltIcon/>
                            </div>
                          }
                          onClick={sortByPower}
                        />
                      </div>
                      <div className="relative inline-block md:mx-3 md:mb-0 mb-1 z-10">
                        <Dropdown
                          title="Rarity"
                          selected={filterRarity}
                          options={rarityOptions(setFilterRarity)}
                        />
                      </div>
                      {section !== "monster_parts" ? (
                        <div className="relative inline-block">
                          <Dropdown
                            title="Collection"
                            selected={
                              filterCollection
                                ? allCollections[filterCollection]?.title
                                : null
                            }
                            options={collectionOptions(allCollections, setFilterCollection)}
                          />
                        </div>
                      ) : (
                        <></>
                        // <div className="inline-block">
                        //   <Dropdown
                        //     title="Part #"
                        //     selected={filterType ? `Part #${filterType}` : null}
                        //     options={monsterPartOptions()}
                        //   />
                        // </div>
                      )}
                    </>
                  )}

                  {section === "inventory" && (
                    <div className="inline-block">
                      <Dropdown
                        title="Item Type"
                        selected={inventoryTypes[filterType - 1]}
                        options={inventoryOptions(setFilterType)}
                      />
                    </div>
                  )}

                  {section === "modifiers" && (
                    <div className="inline-block">
                      <Dropdown
                        title="Item Type"
                        selected={modifierTypes[filterType - 1]}
                        options={modifierOptions(setFilterType)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <ListWrapper>
              {isReady ? (
                <>
                  <List>
                    {section === "lands" && (
                      <>
                        {items.length > 0 ?
                          items.map((item, index) => (
                            <div key={index}>
                              {isOwner(currentUser, item.ownerId) ? (
                                <CardLand
                                  nft={item}
                                  rmFromMarket={() => rmFromMarketHandle(item)}
                                />
                              ) : (
                                <CardLand
                                  nft={item}
                                  isSelected={isSelected(item)}
                                  handleBuy={() => selectToBuy([item])}
                                />
                              )}
                            </div>
                          )) : (
                            <div>
                              No {filterRarity} {section} on sale.
                            </div>
                          )}
                      </>
                    )}

                    {(section === "zombies" || section === "monsters") && (
                      <>
                        {items.length > 0 ? (
                          <>
                            {items.map((item, index) => (
                              <div key={index}>
                                {isOwner(currentUser, item.ownerId) ? (
                                  <CardRotate
                                    nft={item}
                                    key={index}
                                    rmFromMarket={() => rmFromMarketHandle(item)}
                                  />
                                ) : (
                                  <CardRotate
                                    nft={item}
                                    key={index}
                                    isSelected={isSelected(item)}
                                    handleBuy={() => selectToBuy([item])}
                                  />
                                )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <div>
                            No {filterRarity} {section} on sale.
                          </div>
                        )}
                      </>
                    )}

                    {/*{section === "monster_parts" && (*/}
                    {/*  <List>*/}
                    {/*    {items.length > 0 ? (*/}
                    {/*      <>*/}
                    {/*        {items.map((item, index) => (*/}
                    {/*          <div key={index}>*/}
                    {/*            {currentUser === item.owner_id ? (*/}
                    {/*              <PartOfMonster*/}
                    {/*                item={item}*/}
                    {/*                key={index}*/}
                    {/*                rmFromMarket={() => rmFromMarketHandle(item)}*/}
                    {/*              />*/}
                    {/*            ) : (*/}
                    {/*              <PartOfMonster*/}
                    {/*                item={item}*/}
                    {/*                key={index}*/}
                    {/*                isSelected={isSelected(item)}*/}
                    {/*                handleBuy={() => selectToBuy([item])}*/}
                    {/*              />*/}
                    {/*            )}*/}
                    {/*          </div>*/}
                    {/*        ))}*/}
                    {/*      </>*/}
                    {/*    ) : (*/}
                    {/*      <div>No {filterRarity} Monster parts on sale.</div>*/}
                    {/*    )}*/}
                    {/*  </List>*/}
                    {/*)}*/}

                    {(section === "inventory" || section === "modifiers") && (
                      <>
                        {items.length > 0 ? (
                          <>
                            {items.map((item, index) => (
                              <div key={index}>
                                {isOwner(currentUser, item.ownerId) ? (
                                  <CardItem
                                    isItem={section === "inventory"}
                                    item={item}
                                    key={index}
                                    rmFromMarket={() => rmFromMarketHandle(item)}
                                  />
                                ) : (
                                  <CardItem
                                    isItem={section === "inventory"}
                                    item={item}
                                    key={index}
                                    isSelected={isSelected(item)}
                                    handleBuy={() => selectToBuy([item])}
                                  />
                                )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <div>
                            No {section} on sale.
                          </div>
                        )}
                      </>
                    )}
                  </List>

                  {(filterRarity || filterCollection || filterType > 0) && (
                    <div className="mt-10">
                      {section !== "lands" && (
                        <a
                          className="link cursor-pointer"
                          onClick={() => resetFilters()}
                        >
                          Reset Filters
                        </a>
                      )}
                    </div>
                  )}

                  <Pagination
                    total={parseInt(itemsCount)}
                    limit={PAGE_LIMIT}
                    selectedPage={currentPage}
                    onPageChanged={onPageChanged}
                  />
                </>
              ) : (
                <Loader/>
              )}

            </ListWrapper>
          </div>
        </Container>

        <MarketHistoryPopup
          marketHistoryVisible={marketHistoryVisible}
          setMarketHistoryVisible={setMarketHistoryVisible}
        />

        {isSelectedAny() && (
          <BuyItemsFooter
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            allItems={items}
            selectToBuy={selectToBuy}
            handleSuccessBuy={handleSuccessBuy}
          />
        )}

      </Wrapper>

      <Footer/>
    </InnerPageWrapper>
  );
};
