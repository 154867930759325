import React, {useEffect, useState} from "react";
import {transformItem, transformModifier} from "../../web3/transform";
import {postRequest} from "../../web3/api";
import {useDispatch, useSelector} from "react-redux";
import {Popup} from "../Popup";
import {CardItem} from "../cards/CardItem";
import {Loader} from "../basic/Loader";
import {Button} from "../basic/Button";
import {convertFromYocto} from "../../web3/utils";
import {updateUserBalance} from "../../web3/contracts";

export const ClanDailyRewardResult = (
  {
    popupVisible,
    setPopupVisible,
    rewardType,
    rewardValue,
    itemDurability,
  }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const [isReady, setIsReady] = useState(false);
  const [reward, setReward] = useState([]);

  const loadRewardDetails = async () => {
    if (rewardType === 1) {
      let foundModifiers = [];
      const modifiersResponse = await window.contracts.modifier.userItems(1, 10, 0);
      const userModifiers = modifiersResponse[1].map(item => transformModifier(item));
      userModifiers
        .filter((item) => rewardValue.includes(item.tokenId))
        .map((item) => {
          foundModifiers.push(item);
        });

      setReward(foundModifiers);
    } else if (rewardType === 2) {
      let foundItems = [];
      const inventoryItemResponse = await window.contracts.inventory.userItems(1, 10, 0);
      const inventoryItems = inventoryItemResponse[1].map(item => transformItem(item));

      inventoryItems
        .filter((item) => rewardValue.includes(item.tokenId))
        .map((item) => {
          item.durabilityMin = itemDurability;
          item.durabilityMax = itemDurability;
          foundItems.push(item);
        });
      setReward(foundItems);
      if (foundItems.length > 0) {
        await postRequest("api/items/create", {items: foundItems});
      }

    } else {
      setReward([rewardValue]);
      updateUserBalance(dispatch, currentUser.accountId);
    }
  };

  useEffect(() => {
    setIsReady(false);
    if (popupVisible) {
      loadRewardDetails().then(() => {
        setIsReady(true);
      });
    }
  }, [popupVisible]);

  return (
    <>
      <Popup
        title="Clan Daily Reward"
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
      >
        <div className="gap-5 px-6 mb-5 flex flex-col">
          {isReady ? (
            <>
              <div className="text-center pt-4">
                {rewardType === 1 && (
                  <>
                    <p className="mb-4">
                      Your daily reward:{" "}
                      <span className="text-lg font-semibold">
                        Zombie Modifier
                      </span>
                      .
                    </p>
                    <div>
                      {reward.length > 0 ? (
                        <>
                          <div className="flex flex-wrap gap-3 justify-center">
                            {reward.map((item) => (
                              <CardItem
                                item={item}
                                key={item.tokenId}
                                size="sm"
                                noFlip
                              />
                            ))}
                          </div>
                        </>
                      ) : (
                        <Loader/>
                      )}
                    </div>
                  </>
                )}

                {rewardType === 2 && (
                  <>
                    <p className="mb-4">
                      Your daily reward:{" "}
                      <span className="text-lg font-semibold">
                        Inventory Item
                      </span>
                      .
                    </p>
                    {reward.length > 0 ? (
                      <>
                        <div className="flex flex-wrap gap-3 justify-center">
                          {reward.map((item) => (
                            <CardItem
                              isItem
                              item={item}
                              key={item.tokenId}
                              size="sm"
                              noFlip
                            />
                          ))}
                        </div>
                      </>
                    ) : (
                      <Loader/>
                    )}
                  </>
                )}

                {rewardType === 3 && (
                  <>
                    <p className="mb-4">
                      Your daily reward:{" "}
                      <span className="text-lg font-semibold">
                        +{convertFromYocto(reward[0], 0, true)} {process.env.ZML_TOKEN}
                      </span>
                    </p>
                  </>
                )}

                <div className="mt-6">
                  <Button
                    title="Close Popup"
                    onClick={() => setPopupVisible(false)}
                    secondary
                    noIcon
                  />
                </div>
              </div>
            </>
          ) : (
            <Loader/>
          )}
        </div>
      </Popup>
    </>
  );
};
