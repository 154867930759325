export const useControlUrl = () => {
  const params = new URLSearchParams(window.location.search);

  const setUrlSearchParams = (paramName, value) => {
    if (value) {
      params.set(paramName, value);
    } else {
      params.delete(paramName);
    }
    window.history.replaceState(
      {},
      "",
      decodeURIComponent(`${window.location.pathname}?${params}`)
    );
  };

  const getSearchParam = paramName => params.get(paramName);

  return {setUrlSearchParams, getSearchParam};
}
