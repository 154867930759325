import {ethers} from "ethers";
import MainAddress from "../contractsData/MainContract-address.json";
import MainAbi from "../contractsData/MainContract.json";
import LandNFTAddress from "../contractsData/LandNFTContract-address.json";
import LandNFTAbi from "../contractsData/LandNFTContract.json";
import ZombieNFTAddress from "../contractsData/ZombieNFTContract-address.json";
import ZombieNFTAbi from "../contractsData/ZombieNFTContract.json";
import MonsterNFTAddress from "../contractsData/MonsterNFTContract-address.json";
import MonsterNFTAbi from "../contractsData/MonsterNFTContract.json";
import TokenFTAddress from "../contractsData/TokenFTContract-address.json";
import TokenFTAbi from "../contractsData/TokenFTContract.json";
import CollectionAddress from "../contractsData/CollectionContract-address.json";
import CollectionAbi from "../contractsData/CollectionContract.json";
import MarketAddress from "../contractsData/MarketContract-address.json";
import MarketAbi from "../contractsData/MarketContract.json";
import ClanAddress from "../contractsData/ClanContract-address.json";
import ClanAbi from "../contractsData/ClanContract.json";
import InventoryAddress from "../contractsData/InventoryContract-address.json";
import InventoryAbi from "../contractsData/InventoryContract.json";
import ModifierAddress from "../contractsData/ModifierContract-address.json";
import ModifierAbi from "../contractsData/ModifierContract.json";
import {setUserAccountId, setUserBalance} from '../store/userSlice';
import {addPendingTransaction, addTransactionError} from './utils';

export const web3Handler = () => {
  return new Promise(async (resolve, reject) => {
    if (isMetamaskInstalled()) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        window.contracts = {
          main: new ethers.Contract(
            MainAddress.address,
            MainAbi.abi,
            signer
          ),
          land: new ethers.Contract(
            LandNFTAddress.address,
            LandNFTAbi.abi,
            signer
          ),
          zombie: new ethers.Contract(
            ZombieNFTAddress.address,
            ZombieNFTAbi.abi,
            signer
          ),
          monster: new ethers.Contract(
            MonsterNFTAddress.address,
            MonsterNFTAbi.abi,
            signer
          ),
          tokenMTRG: new ethers.Contract(
            process.env.MTRG_TOKEN_ADDRESS,
            TokenFTAbi.abi,
            signer
          ),
          token: new ethers.Contract(
            TokenFTAddress.address,
            TokenFTAbi.abi,
            signer
          ),
          collection: new ethers.Contract(
            CollectionAddress.address,
            CollectionAbi.abi,
            signer
          ),
          market: new ethers.Contract(
            MarketAddress.address,
            MarketAbi.abi,
            signer
          ),
          clan: new ethers.Contract(
            ClanAddress.address,
            ClanAbi.abi,
            signer
          ),
          inventory: new ethers.Contract(
            InventoryAddress.address,
            InventoryAbi.abi,
            signer
          ),
          modifier: new ethers.Contract(
            ModifierAddress.address,
            ModifierAbi.abi,
            signer
          )
        };

        resolve({
          account: accounts[0]
        });
      } catch (e) {
        reject("Metamask connection error", e);
      }
    } else {
      reject("Metamask not installed");
    }
  });
};

export const checkNetwork = async () => {
  const chainId = await window.ethereum.request({method: 'eth_chainId'});
  return chainId === process.env.CHAIN_ID;
}


export const isMetamaskInstalled = () => {
  return typeof window.ethereum !== "undefined";
};

export const updateUserBalance = async (dispatch, accountId) => {
  const balance = await window.contracts.token.balanceOf(accountId);
  dispatch(setUserBalance({
    balance: balance.toString()
  }));
}

export const updateUserAccount = async (dispatch, account) => {
  dispatch(setUserAccountId({account}));
  await updateUserBalance(dispatch, account);
}

export const removeFromMarket = async (dispatch, tokenId, nftType) => {
  return new Promise(async (resolve) => {
    try {
      await window.contracts.market.removeFromMarket(tokenId, nftType.toLowerCase()).then(transaction => {
        addPendingTransaction(dispatch, transaction, `Remove ${nftType} from market`);
        transaction.wait().then(receipt => {
          if (receipt.status === 1) {
            resolve();
          }
        });
      });
    } catch (e) {
      addTransactionError(dispatch, e.message);
    }
  });
}

export const approveTokenSpend = (dispatch, contract, spender, amount) => new Promise((resolve, reject) => {
  contract.approve(spender, amount).then(transaction => {
    addPendingTransaction(dispatch, transaction, "Approve Token");
    transaction.wait().then(receipt => {
      if (receipt.status === 1) {
        resolve();
      }
    });
  }).catch(err => {
    addTransactionError(dispatch, err.message);
    reject(err);
  });
});

export const joinClanCall = (dispatch, clan, callback) => {
  window.contracts.clan.joinClan(clan.id).then(transaction => {
    addPendingTransaction(dispatch, transaction, `Join Clan "${clan.name}"`);

    transaction.wait().then(receipt => {
      if (receipt.status === 1) {
        callback();
      } else {
        console.log(`receipt`, receipt);
      }
    });
  }).catch(err => {
    addTransactionError(dispatch, err.message);
  });
}