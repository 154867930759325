import React from "react";
import {TrendingUpIcon} from "@heroicons/react/solid";
import {BanIcon, CheckCircleIcon} from "@heroicons/react/outline";
import S, {Rarity} from "../../assets/styles/cardRotate.style";

import attack_icon from "../../assets/images/attack_icon.png";
import health_icon from "../../assets/images/health_icon.png";
import intellect_icon from "../../assets/images/intelect_icon.png";
import speed_icon from "../../assets/images/speed_icon.png";
import {getMedia} from '../../web3/utils';
import {BoltIcon} from "../basic/BoltIcon";
import {Price} from "../Price";
import {Button} from "../basic/Button";

export const formatCharacteristicOfCard = (nft) => {
  return [
    {
      icon: attack_icon,
      title: "Attack",
      value: nft.attack,
      mods: nft.modifierItems,
    },
    {
      icon: health_icon,
      title: "Health",
      value: nft.health,
      mods: nft.modifierItems,
    },
    {
      icon: intellect_icon,
      title: "Brain",
      value: nft.brain,
      mods: nft.modifierItems,
    },
    {
      icon: speed_icon,
      title: "Speed",
      value: nft.speed || 1,
      mods: nft.modifierItems,
    },
  ];
};

export const CardRotate = ({
                             nft,
                             size,
                             isSelected,
                             rmFromMarket,
                             handleModify,
                             handleBuy,
                             handleSelect,
                             height,
                             noFlip,
                             reversed,
                           }) => {
  const classMapping = {
    xsm: "x-small",
    sm: "small",
    mds: "md-small",
    md: "",
    square: "square",
  };

  const characteristicSizeMapping = {
    xsm: "",
    sm: "mt-2",
    mds: "flex flex-col items-center w-1/3 px-9 py-4 border-2 rounded-md",
    md: "flex flex-col items-center w-5/12 px-11 py-4 border-2 rounded-md",
    square: "flex flex-col items-center w-5/12 px-11 py-2 border-2 rounded-md",
  };

  let heightParam = "h-80";
  if (size === "sm" || height) {
    heightParam = height || "h-[11.9rem]";
  } else if (size === "mds") {
    heightParam = "h-[270px]";
  }

  const hasMod = (stat) => {
    if (nft.modifierItems) {
      return Object.keys(nft.modifierItems).includes(stat.toLowerCase());
    }
  };

  return (
    <S.FlipCardModify
      className={`${classMapping[size || "md"]} ${
        noFlip ? "pointer-events-none" : ""
      }`}
    >
      <S.Front
        isSelected={isSelected}
        type={nft.cardRarity}
        reversed={reversed}
      >
        <section className="absolute z-10 left-0 right-0 bottom-0">
          <Rarity
            type={nft.cardRarity}
            className={`${size !== "sm" ? "text-sm pl-2" : "text-xs pl-1"}`}
          >
            {nft.cardRarity} #{nft.tokenId}
          </Rarity>
        </section>
        <img
          className={`absolute ${heightParam ? heightParam : "h-full"}`}
          src={getMedia(nft.media)}
          alt={nft.tokenId ? nft.tokenId : ""}
        />

        {size !== "square" && (
          <div className="flex items-center text-base font-semibold text-gray-500 absolute z-10 m-2">
            <BoltIcon/>
            <span className="ml-1">
            {formatCharacteristicOfCard(nft)
              .map((item) => item.value)
              .reduce((prev, curr) => prev + curr, 0)}
          </span>
          </div>
        )}

        {nft.salePrice && (
          <div className="z-10 absolute right-1 top-1">
            <Price title={nft.salePrice} handleBuy={handleBuy} size={size} token={process.env.PAYMENT_TOKEN_SYMBOL}/>
          </div>
        )}
        {isSelected && (
          <div className="top-0 left-0 right-0 bottom-0 z-10 absolute shadow-[0_0_20px_#f97315a8_inset]"/>
        )}
      </S.Front>

      <S.Back
        isSelected={isSelected}
        type={nft.cardRarity}
        reversed={reversed}
      >
        {nft.tokenId && (
          <div
            className={`${
              size !== "sm"
                ? "items-center justify-between flex gap-2 flex-wrap"
                : "mt-4 ml-1"
            }`}
          >
            {formatCharacteristicOfCard(nft).map((item) => (
              <div
                key={item.title}
                className={`${
                  size !== "sm" &&
                  `${
                    hasMod(item.title)
                      ? "border-orange-500/80 bg-orange-400/10"
                      : "border-mainLight/80 bg-mainLight/30"
                  }`
                }
                ${characteristicSizeMapping[size || "md"]}`}
              >
                <div
                  className={`h-5 w-5 flex text-center items-center mb-3 ${
                    size !== "sm" ? "justify-center" : "ml-6"
                  }`}
                >
                  <img alt="icon" className="h-full mr-2" src={item.icon}/>
                  <span
                    className={`text-xl font-semibold ${
                      hasMod(item.title) ? "text-orange-500" : "text-indigo-300"
                    }`}
                  >
                    {item.value}
                  </span>
                </div>

                {size !== "sm" && (
                  <span
                    className={`text-xs uppercase text-indigo-300 ${
                      hasMod(item.title) ? "text-orange-500" : "text-indigo-300"
                    }`}
                  >
                    {item.title}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}

        {size !== "sm" && (
          <div>
            <p className="text-xs">
              Total Power:{" "}
              {formatCharacteristicOfCard(nft)
                .map((item) => item.value)
                .reduce((prev, curr) => prev + curr, 0)}
            </p>
          </div>
        )}

        {size !== "sm" && size !== "mds" && (
          <div className="flex flex-col space-y-2">
            {nft.tokenId && !nft.salePrice && (
              <>
                {handleModify && (
                  <Button
                    title={"Upgrade"}
                    onClick={handleModify}
                    className="w-full"
                    size={"xs"}
                    icon={
                      <TrendingUpIcon className="h-5 w-5 ml-2 font-semibold"/>
                    }
                  />
                )}
                <Button
                  title={isSelected ? "Deselect" : "Select"}
                  className="w-full"
                  secondary
                  size="xs"
                  icon={
                    isSelected ? (
                      <BanIcon className="h-5 w-5 ml-2 font-semibold"/>
                    ) : (
                      <CheckCircleIcon className="h-5 w-5 ml-2 font-semibold"/>
                    )
                  }
                  onClick={handleSelect}
                />
              </>
            )}

            {handleBuy ? (
              <Button
                title={
                  <>
                    <span className="mr-1">Buy for</span>
                    {nft.salePrice} {process.env.PAYMENT_TOKEN_SYMBOL}
                  </>
                }
                size="xs"
                className="w-full"
                noIcon
                onClick={handleBuy}
              />
            ) : (
              nft.salePrice && (
                <Button
                  title="Cancel sell"
                  size="xs"
                  noIcon
                  className="w-full"
                  secondary
                  onClick={rmFromMarket}
                />
              )
            )}
          </div>
        )}
      </S.Back>
    </S.FlipCardModify>
  );
};
