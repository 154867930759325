import React, {useEffect, useState} from "react";
import {addPendingTransaction, addTransactionError, convertFromYocto, getMedia, statusColorBorderMap} from "../../web3/utils";
import {XIcon} from "@heroicons/react/outline";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "../basic/Button";
import {Loader} from "../basic/Loader";
import {Popup} from "../Popup";
import {updateUserBalance} from "../../web3/contracts";

const KILL_LIMIT = 100;

export const KillNftPopup = (
  {
    killPopupVisible,
    setKillPopupVisible,
    nftList,
    nftType,
    cancelKill,
    handleReloadData,
  }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const [isReady, setIsReady] = useState(false);
  const [idList, setIdList] = useState([]);

  useEffect(() => {
    if (killPopupVisible) {
      setIsReady(false);
      const idList = nftList.filter((_, index) => index < KILL_LIMIT).map((item) => item.tokenId);
      setIdList(idList);
      setIsReady(true);
    }
  }, [killPopupVisible]);

  const handleKillBtn = async () => {
    const idList = nftList.map(item => item.tokenId);
    if (nftType === 'Zombie') {
      handleKillItems(idList, "zombie");
    } else {
      handleKillItems(idList, "monster");
    }
  };

  const handleKillItems = async (killObject, nftType) => {
    setIsReady(false);
    await window.contracts[nftType].killNftList(killObject).then(transaction => {
      addPendingTransaction(dispatch, transaction, `Kill ${nftType}s to get ${process.env.ZML_TOKEN} tokens`);

      transaction.wait().then(async receipt => {
        if (receipt.status === 1) {
          handleReloadData();
          // Update user balance
          await updateUserBalance(dispatch, currentUser.accountId);
          setIsReady(true);
        } else {
          alert('Kill error');
        }
      });
    }).catch(err => {
      addTransactionError(dispatch, err.message);
      setIsReady(true);
    });
  };

  const totalZML = () => {
    let total = 0;
    nftList.filter((_, index) => index < KILL_LIMIT).map((nft) => {
      total += parseFloat(convertFromYocto(nft.killTokens, 2, true));
    });
    return total.toFixed(1);
  };

  const removeFromKillList = (nft) => {
    setIdList(idList.filter(id => nft.tokenId !== id));
    cancelKill(nft);
  }

  const KillItem = ({nft}) => (
    <div
      className="border-mainSkyBlue/80 bg-mainSkyBlue/30 flex justify-between items-start px-3 py-2 border-2 rounded-md">
      <img
        className={`w-16 border-2 rounded-md mr-4 ${statusColorBorderMap(
          nft.cardRarity
        )}`}
        src={getMedia(nft.media)}
        alt={nft.tokenId}
      />
      <div className="text-left w-40">
        <div className="font-semibold mb-1">
          {nft.cardRarity} #{nft.tokenId}
        </div>
        <div>{convertFromYocto(nft.killTokens, 1, true)} {process.env.ZML_TOKEN}</div>
      </div>
      <XIcon
        className="h-6 w-6 hover:text-red-700 cursor-pointer transition duration-200"
        onClick={() => removeFromKillList(nft)}
      />
    </div>
  );

  return (
    <Popup
      title={`Kill ${nftType}${nftList.length > 1 ? "s" : ""}`}
      popupVisible={killPopupVisible}
      setPopupVisible={setKillPopupVisible}
    >
      {isReady ? (
        <>
          <div className="mt-2 md:px-6 mx-auto text-center">
            <div className="flex flex-wrap w-full justify-center mb-6 gap-4">
              {nftList.filter((_, index) => index < KILL_LIMIT).map((nft) => (
                <KillItem nft={nft} key={nft.tokenId}/>
              ))}
            </div>
          </div>
          <p className="pb-4 text-gray-400">
            You will receive <b>{totalZML()} {process.env.ZML_TOKEN}</b>.
          </p>
          {nftList.length > 100 && (
            <p className="text-red-400 pb-4">*NOTE: Kill limited by {KILL_LIMIT} items per transaction.</p>
          )}
          <Button
            title={`Kill ${nftList.length} ${nftType}${
              nftList.length > 1 ? "s" : ""
            }`}
            onClick={handleKillBtn}
          />
        </>
      ) : (
        <Loader/>
      )}
    </Popup>
  );
};
