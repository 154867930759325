import React from "react";

export const Price = ({title, size, handleBuy, token, className}) => {
  return (
    <div
      className={`${className} flex items-center font-semibold py-1 px-2 bg-main/50 rounded-md
      ${size === "sm" ? "text-sm" : ""} ${
        handleBuy ? "text-orange-500" : "text-[#8f8f8f]"
      } font-${size || "base"}`}
    >
      {title && (
        <>
          {title}
          {token ? (
            <span className="ml-1">{token}</span>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
