import React, {useEffect, useState} from "react";
import {Popup} from "../../components/Popup";
import {Loader} from '../../components/basic/Loader';
import {formatLandId} from '../../web3/utils';
import {CardRotate} from "../../components/cards/CardRotate";
import {CardLand} from "../../components/cards/CardLand";
import {transformItem, transformLand, transformMarketHistory, transformMonster, transformZombie} from "../../web3/transform";
import {CardItem} from "../../components/cards/CardItem";

export default function MarketHistoryPopup(
  {
    marketHistoryVisible,
    setMarketHistoryVisible,
  }) {
  const [zombiesById, setZombiesById] = useState({});
  const [monstersById, setMonstersById] = useState({});
  const [inventoryById, setInventoryById] = useState({});
  const [modifiersById, setModifiersById] = useState({});
  const [landsById, setLandsById] = useState({});
  // const [monsterPartsById, setMonsterPartsById] = useState({});
  const [marketHistory, setMarketHistory] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const loadMarketHistory = async () => {
    const historyObject = await window.contracts.market.getSaleHistory();
    let history = historyObject[1].filter(history => history.nftType).map(history => transformMarketHistory(history));
    const sliceIndex = parseInt(historyObject[0]);
    history = history.slice(sliceIndex).concat(history.slice(0, sliceIndex))

    let zombiesList = history.filter(item => item.nftType === "zombie").map(item => item.tokenId);
    let monstersList = history.filter(item => item.nftType === "monster").map(item => item.tokenId);
    let landsList = history.filter(item => item.nftType === "land").map(item => item.tokenId);
    let inventoryList = history.filter(item => item.nftType === "inventory").map(item => item.tokenId);

    let zombiesPromise = new Promise(async (resolve) => {
      let result = await window.contracts.zombie.getListById(zombiesList);
      resolve(result);
    });
    let monstersPromise = new Promise(async (resolve) => {
      let result = await window.contracts.monster.getListById(monstersList);
      resolve(result);
    });
    let landsPromise = new Promise(async (resolve) => {
      let result = await window.contracts.land.getListById(landsList);
      resolve(result);
    });
    let inventoryPromise = new Promise(async (resolve) => {
      let result = await window.contracts.inventory.getListById(inventoryList);
      resolve(result);
    });

    Promise.all([zombiesPromise, monstersPromise, landsPromise, inventoryPromise]).then(result => {
      let zombies = {};
      result[0].map(zombie => {
        zombies[parseInt(zombie.tokenId)] = transformZombie(zombie);
      });
      setZombiesById(zombies);

      let monsters = {};
      result[1].map(monster => {
        monsters[parseInt(monster.tokenId)] = transformMonster(monster);
      });
      setMonstersById(monsters);

      let lands = {};
      result[2].map(land => {
        lands[parseInt(land.tokenId)] = transformLand(land);
      });
      setLandsById(lands);

      let inventory = {};
      result[3].map(item => {
        inventory[parseInt(item.tokenId)] = transformItem(item);
      });
      setInventoryById(inventory);

      setMarketHistory(history.reverse());
      setIsReady(true);
    });
  };

  const timestampToDate = (timestamp) => {
    const dateTime = new Date(timestamp * 1000);
    const options = {month: "long", day: "numeric", hour: 'numeric', minute: 'numeric', hourCycle: 'h23'};
    return new Intl.DateTimeFormat('en-US', options).format(dateTime);
  }

  const formatTitle = (item) => {
    if (item.nftType === "land") {
      return formatLandId(landsById[item.tokenId].landType, item.tokenId);
    } else if (item.nftType === "inventory") {
      return `${inventoryById[item.tokenId].itemType} #${item.tokenId}`;
    } else {
      return `${item.nftType} #${item.tokenId}`;
    }
  }

  useEffect(() => {
    if (marketHistoryVisible) {
      loadMarketHistory();
    }
  }, [marketHistoryVisible]);

  return (
    <Popup
      title="Market History - Last Trades"
      popupVisible={marketHistoryVisible}
      setPopupVisible={setMarketHistoryVisible}
      width="lg:w-[840px]"
    >
      <div className="mt-2 text-left">
        {isReady ? (
          <div className="text-sm">
            <div className="flex flex-row justify-between border-b border-sky-200 text-sky-200 opacity-70 font-semibold py-2 mb-1">
              <div className="w-14 text-center">Card</div>
              <div className="w-1/6">Date</div>
              <div className="w-1/6 hidden lg:block">NFT</div>
              <div className="w-24">Price</div>
              <div className="w-1/6 hidden lg:block">Seller</div>
              <div className="w-1/6 hidden lg:block">Buyer</div>
            </div>

            {marketHistory.map((item, index) => (
              <div
                key={`${item.timestamp}-${index}`}
                className="flex flex-row justify-between py-1"
              >
                <div className="w-14">
                  {item.nftType === "land" && (
                    landsById[item.tokenId] ? (
                      <div style={{zoom: "0.33"}}>
                        <CardLand nft={landsById[item.tokenId]} size="sm" noFlip/>
                      </div>
                    ) : (
                      <>-</>
                    )
                  )}

                  {item.nftType === "zombie" &&
                    (zombiesById[item.tokenId] ? (
                      <div style={{zoom: "0.33"}}>
                        <CardRotate nft={zombiesById[item.tokenId]} size="sm" noFlip/>
                      </div>
                    ) : (
                      <div className="text-sm opacity-70 h-20 pt-4">
                        Zombie Killed
                      </div>
                    ))
                  }

                  {item.nftType === "monster" &&
                    (monstersById[item.tokenId] ? (
                      <div style={{zoom: "0.33"}}>
                        <CardRotate nft={monstersById[item.tokenId]} size="sm" noFlip/>
                      </div>
                    ) : (
                      <div className="text-sm opacity-70 h-20 pt-4">
                        Monster Killed
                      </div>
                    ))
                  }
                  {/*{item.nftType === "MonsterPart" &&*/}
                  {/*  (monsterPartsById[item.tokenId] ? (*/}
                  {/*    <div style={{zoom: "0.25"}}>*/}
                  {/*      <PartOfMonster*/}
                  {/*        item={monsterPartsById[item.tokenId]}*/}
                  {/*        noFlip*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*  ) : (*/}
                  {/*    <div className="text-sm opacity-70 h-20 pt-4">*/}
                  {/*      Part Burned*/}
                  {/*    </div>*/}
                  {/*  ))}*/}

                  {item.nftType === "inventory" &&
                    (inventoryById[item.tokenId] ? (
                      <div style={{zoom: "0.4"}}>
                        <CardItem isItem item={inventoryById[item.tokenId]} noFlip size="sm"/>
                      </div>
                    ) : (
                      <div className="text-sm opacity-70 h-20 pt-4">
                        Inventory used
                      </div>
                    ))
                  }

                  {item.nftType === "modifier" &&
                    (modifiersById[item.tokenId] ? (
                      <div style={{zoom: "0.4"}}>
                        <CardItem item={modifiersById[item.tokenId]} noFlip size="sm"/>
                      </div>
                    ) : (
                      <div className="text-sm opacity-70 h-20 pt-4">
                        Modifier used
                      </div>
                    ))
                  }
                </div>
                <div className="w-1/6">
                  <p className="lg:pt-6">{timestampToDate(item.timestamp)}</p>
                </div>
                <div className="w-1/6 hidden lg:block">
                  <p className="lg:pt-6">{formatTitle(item)}</p>
                </div>
                <div className="w-24">
                  <p className="lg:pt-6 pt-4">
                    <b>
                      {item.price} {process.env.PAYMENT_TOKEN_SYMBOL}
                    </b>
                  </p>
                </div>
                <div className="w-1/6 hidden lg:block">
                  <p className="w-32 lg:pt-6 overflow-hidden text-ellipsis">
                    {item.fromUser}
                  </p>
                </div>
                <div className="w-1/6 hidden lg:block">
                  <p className="w-32 lg:pt-6 overflow-hidden text-ellipsis">
                    {item.toUser}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Loader/>
        )}
      </div>
    </Popup>
  );
}
