import React from "react";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../assets/styles/common.style";
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {InnerPageHead} from "../components/InnerPageHead";
import {useSelector} from 'react-redux';

export const MigrateNFT = () => {
  const currentUser = useSelector(state => state.user.user);

  return (
    <InnerPageWrapper>
      <Header currentUser={currentUser}/>

      <Wrapper>
        <Container className="flex flex-col text-white mt-6 items-center">
          <InnerPageHead title={`Migrate Zombies & Monsters`}
                         description={`You receive bonus ${process.env.ZML_TOKEN} tokens airdrop for each migrated zombie and monster!`}/>
          <div className="mt-10 text-center">
            All Zombies and Monsters will be automatically migrated to new smart-contracts. Migration date: Sep 02, 2023.
          </div>
        </Container>
      </Wrapper>

      <Footer/>
    </InnerPageWrapper>
  );
};
