import React, {useEffect, useState} from "react";
import {
  ArrowRightIcon,
  CurrencyDollarIcon,
  XIcon,
} from "@heroicons/react/outline";
import {Container} from "../../assets/styles/common.style";
import {SellNftPopup} from "./SellNftPopup";
import {deselectAll, selectAll} from "../../web3/utils";
import {ArrowUpIcon} from "@heroicons/react/solid";
// import {UpgradeItemPopup} from "./UpgradeItemPopup";
import {Button} from "../basic/Button";
import {KillNftPopup} from "./KillNftPopup";
import {TransferNftPopup} from "./TransferNftPopup";

export const SelectedItemsFooter = (
  {
    upgradable,
    selectedItems,
    setSelectedItems,
    items,
    deselectItem,
    nftType,
    handleReloadData,
  }) => {
  const [transferPopupVisible, setTransferPopupVisible] = useState(false);
  const [upgradePopupVisible, setUpgradePopupVisible] = useState(false);
  const [sellPopupVisible, setSellPopupVisible] = useState(false);
  const [killPopupVisible, setKillPopupVisible] = useState(false);

  useEffect(() => {
    console.log(`selectedItems`, selectedItems);
  }, [selectedItems])

  const buttonText = () => {
    if (window.innerWidth > 600) {
      let typeText = nftType;
      if (nftType === "MonsterPart") {
        typeText = "Monster Part";
      }
      if (nftType === "Inventory") {
        typeText = "Item";
      }
      return `${typeText}${selectedItems.length > 1 ? "s" : ""}`;
    }
    return "";
  };

  return (
    <>
      <div
        className={`bottom-0 right-0 left-0 fixed h-24 px-2 md:py-6 py-2 bg-gray-800 z-30 shadow-3xl border-t-[4px] border-gray-700`}
      >
        <Container className="flex justify-between">
          <div className="text-sm">
            <p className="font-semibold hidden md:block">
              Selected {buttonText()}: {selectedItems.length}
            </p>
            <b
              className="dashed hover:underline cursor-pointer md:mt-1 mt-4 inline-block text-orange-400"
              onClick={() => selectAll(selectedItems, setSelectedItems, items)}
            >
              Select All
            </b>
            {selectedItems.length >= 1 && (
              <b
                className="md:ml-5 dashed hover:underline cursor-pointer mt-1 inline-block text-orange-400"
                onClick={() => deselectAll(setSelectedItems)}
              >
                Deselect All
              </b>
            )}
          </div>

          <div className="text-right">
            {/*{upgradable && (*/}
            {/*  <Button*/}
            {/*    secondary*/}
            {/*    className="ml-4 mb-1 md:mb-0"*/}
            {/*    title={`Upgrade ${buttonText()}`}*/}
            {/*    size={"sm"}*/}
            {/*    icon={<ArrowUpIcon className="h-5 w-4 ml-2"/>}*/}
            {/*    onClick={() => setUpgradePopupVisible(true)}*/}
            {/*  />*/}
            {/*)}*/}

            <Button
              secondary
              className="ml-4 mb-1 md:mb-0"
              title={`Transfer ${buttonText()}`}
              size={"sm"}
              icon={<ArrowRightIcon className="h-5 w-4 ml-2"/>}
              onClick={() => setTransferPopupVisible(true)}
            />

            {(nftType === "Zombie" || nftType === "Monster") && (
              <Button
                secondary
                title={`Kill ${buttonText()}`}
                size="sm"
                className="ml-4 mb-1 md:mb-0 min-w-[90px]"
                icon={<XIcon className="h-5 ml-2"/>}
                onClick={() => setKillPopupVisible(true)}
              />
            )}

            <Button
              secondary
              className="ml-4 min-w-[90px]"
              title={`Sell ${buttonText()}`}
              size={"sm"}
              icon={<CurrencyDollarIcon className="h-5 w-5 ml-2"/>}
              onClick={() => setSellPopupVisible(true)}
            />
          </div>
        </Container>
      </div>

      <TransferNftPopup
        nftType={nftType}
        transferPopupVisible={transferPopupVisible}
        setTransferPopupVisible={setTransferPopupVisible}
        nftList={selectedItems}
        handleReloadData={handleReloadData}
      />

      {/*<UpgradeItemPopup*/}
      {/*  upgradePopupVisible={upgradePopupVisible}*/}
      {/*  setUpgradePopupVisible={setUpgradePopupVisible}*/}
      {/*  items={selectedItems}*/}
      {/*  successUpdated={handleReloadData}*/}
      {/*/>*/}

      {(nftType === "Zombie" || nftType === "Monster") && (
        <KillNftPopup
          nftType={nftType}
          killPopupVisible={killPopupVisible}
          setKillPopupVisible={setKillPopupVisible}
          nftList={selectedItems}
          cancelKill={deselectItem}
          handleReloadData={handleReloadData}
        />
      )}

      <SellNftPopup
        nftType={nftType}
        sellPopupVisible={sellPopupVisible}
        setSellPopupVisible={setSellPopupVisible}
        nftList={selectedItems}
        cancelSell={deselectItem}
        handleReloadData={handleReloadData}
      />
    </>
  );
};
