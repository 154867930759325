import React, {useEffect, useState} from "react";
import S from "../../assets/styles/clanView.style";
import {statusColorBgMap} from "../../web3/utils";
import {LEVEL_MAP} from "../../web3/config";
import {BattleResultsPopup} from "../../components/clan/BattleResultsPopup";
import {
  ExternalLinkIcon,
  TrendingUpIcon,
  TrendingDownIcon,
} from "@heroicons/react/outline";
import {BattleZombiesPopup} from "../../components/clan/BattleZombiesPopup";
import clan_image from "../../assets/images/clan_image.png";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getRequest} from "../../web3/api";
import {Loader} from "../../components/basic/Loader";
import {BoltIcon} from "../../components/basic/BoltIcon";
import {Button} from "../../components/basic/Button";
import {Badge} from "../../components/basic/Badge";

export const ClanBattleHistory = ({clan, showBattleResult}) => {
  const currentUser = useSelector(state => state.user.user);
  const [battles, setBattles] = useState([]);
  const [viewBattleDetails, setViewBattleDetails] = useState();
  const [isReady, setIsReady] = useState(false);
  const [showResultsPopup, setShowResultsPopup] = useState(false);
  const [myZombiesBattle, setMyZombiesBattle] = useState();
  const [showMyZombiesPopup, setShowMyZombiesPopup] = useState(false);

  const currentMonthBattle = (battle) => {
    const date = new Date();
    const firstMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    return Date.parse(battle.createdAt) > firstMonth;
  };

  const fetchBattles = async () => {
    const response = await getRequest("api/clan-battle/all", {
      clanId: clan.id,
      accountId: currentUser.accountId,
    });
    if (response.error) {
      alert(response.error);
    }

    setBattles(response.data);
    setIsReady(true);
  };

  useEffect(() => {
    if (showBattleResult && battles.length) {
      const battle = battles
        .filter((b) => b.id === parseInt(showBattleResult))
        .pop();
      setShowResultsPopup(true);
      setViewBattleDetails(battle);
    }
  }, [battles, showBattleResult]);

  useEffect(() => {
    fetchBattles();
  }, []);

  const isJoined = (battle) => {
    return (
      battle.fromClan.participants.some(
        (user) => user.accountId === currentUser.accountId
      ) ||
      battle.toClan.participants.some((user) => user.accountId === currentUser.accountId)
    );
  };

  const otherClanImage = (battle) => {
    if (clan.id === battle.fromClan.id) {
      return battle.toClan.media ?? clan_image;
    }
    return battle.fromClan.media ?? clan_image;
  };

  if (!isReady) {
    return <Loader/>;
  }

  return (
    <>
      <div className="mt-10">
        <S.SectionWrapper className="w-full">
          <S.Table.Header className="hidden md:flex px-3">
            <S.Table.ItemColumn className="flex w-24">
              <b>ID</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="flex w-1/2 justify-start">
              <b>Clan vs Clan</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="flex w-1/2 justify-center">
              <S.Table.ItemColumn className="w-28">
                <b>Rarity</b>
              </S.Table.ItemColumn>
              <S.Table.ItemColumn className="w-40">
                <b>Power limit</b>
              </S.Table.ItemColumn>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="flex w-1/2 justify-end">
              <b>Battle Result</b>
            </S.Table.ItemColumn>
          </S.Table.Header>

          {battles
            ?.filter((b) => b.winnerClanId)
            ?.map((battle, index) => (
              <S.Table.Content
                key={index}
                className={`relative one-battle rounded-lg py-1 pl-1 pr-3 ${
                  !currentMonthBattle(battle) && "opacity-50"
                }`}
              >
                <S.Table.ItemColumn className="w-24 hidden md:flex text-sm">
                  #{battle.id}
                </S.Table.ItemColumn>
                <S.Table.ItemColumn className="flex w-full md:w-1/2 justify-start">
                  <div
                    className={`hidden md:flex flex-row items-center w-32 relative`}
                  >
                    <img
                      alt="our clan"
                      src={clan.media ?? clan_image}
                      onError={({currentTarget}) => {
                        currentTarget.onerror = null;
                        currentTarget.src = clan_image;
                      }}
                      className="h-14 w-14 mr-1 rounded-xl object-cover absolute -left-1 -top-1 z-0 opacity-50"
                    />
                    <img
                      alt="opponent clan"
                      src={otherClanImage(battle)}
                      onError={({currentTarget}) => {
                        currentTarget.onerror = null;
                        currentTarget.src = clan_image;
                      }}
                      className="h-14 w-14 mr-1 rounded-xl object-cover relative z-10 border-2 border-mainLight"
                    />
                  </div>
                  <div className="flex w-full flex-col gap-y-2 items-center leading-3 md:leading-5">
                    <div className="w-full">
                      <div className="font-semibold flex flex-row w-full justify-between">
                        <div>
                          <Link className="text-lg" to={`/clans/${battle.fromClan.id}`}>
                            {battle.fromClan.name}
                          </Link>
                        </div>
                        <div
                          className={`text-sm items-center flex gap-x-1 ${
                            battle.fromClan.participants.length ===
                            battle.requiredSize / 2
                              ? "text-green-500"
                              : "text-indigo-300"
                          }`}
                        ></div>
                      </div>
                      <div className="font-semibold flex flex-row w-full justify-between">
                        <div>
                          <Link className="text-lg" to={`/clans/${battle.toClan.id}`}>
                            {battle.toClan.name}
                          </Link>
                        </div>

                        <div
                          className={`text-sm items-center flex gap-x-1 ${
                            battle.toClan.participants.length ===
                            battle.requiredSize / 2
                              ? "text-green-500"
                              : "text-indigo-300"
                          }`}
                        ></div>
                      </div>
                    </div>
                  </div>
                </S.Table.ItemColumn>
                <S.Table.ItemColumn className="hidden md:flex w-1/3 justify-center">
                  <S.Table.ItemColumn className={`w-40`}>
                    <Badge
                      text={`${battle.requiredSize / 2}x${
                        battle.requiredSize / 2
                      } ${battle.cardRarity}`}
                      bgColor={statusColorBgMap(battle.cardRarity)}
                    />
                  </S.Table.ItemColumn>
                  <S.Table.ItemColumn className="w-40">
                    <div className="flex flex-row items-center font-bold md:text-lg">
                      <BoltIcon className="h-5"/>
                      <span className="ml-1">
                        {LEVEL_MAP[battle.cardRarity][battle.level]}
                      </span>
                    </div>
                  </S.Table.ItemColumn>
                </S.Table.ItemColumn>

                <S.Table.ItemColumn className="flex w-1/2 pr-28 justify-end">
                  <Button
                    secondary
                    size="sm"
                    className="hidden md:inline"
                    title="Show results"
                    onClick={() => {
                      setShowResultsPopup(true);
                      setViewBattleDetails(battle);
                    }}
                  />
                  <Button
                    noIcon
                    size="sm"
                    className="md:hidden inline ml-4"
                    title=""
                    onClick={() => {
                      setShowResultsPopup(true);
                      setViewBattleDetails(battle);
                    }}
                  >
                    <ExternalLinkIcon className="w-4 h-4"/>
                  </Button>
                </S.Table.ItemColumn>

                <div
                  className={`flex flex-col justify-center items-center absolute top-0 bottom-0 right-0 pr-5 rounded-r-lg font-semibold
                  ${
                    battle.winnerClanId === clan.id
                      ? "bg-green-500"
                      : "bg-red-500"
                  }`}
                >
                  <div className={"win-lose"}></div>
                  <div>
                    {battle.winnerClanId === clan.id ? (
                      <TrendingUpIcon className={"w-5 h-5"}/>
                    ) : (
                      <TrendingDownIcon className={"w-5 h-5"}/>
                    )}
                  </div>
                  <div className={"w-10 text-center"}>
                    {battle.winnerClanId === clan.id ? "WON" : "LOST"}
                  </div>
                </div>
              </S.Table.Content>
            ))}
        </S.SectionWrapper>
      </div>

      <BattleResultsPopup
        battle={viewBattleDetails}
        visible={showResultsPopup}
        setVisible={setShowResultsPopup}
      />

      {myZombiesBattle && (
        <BattleZombiesPopup
          battle={myZombiesBattle}
          visible={showMyZombiesPopup}
          setVisible={setShowMyZombiesPopup}
        />
      )}
    </>
  );
};
