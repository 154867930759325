import React, {useEffect, useRef, useState} from "react";
import {Col, InnerPageWrapper, Row} from "../assets/styles/common.style";
import {RouletteTimer} from "../components/RouletteTimer";
import {getRequest} from "../web3/api";
import {addPendingTransaction, arrayEquals, convertToYocto, shortAddress} from "../web3/utils";
import S from "../assets/styles/clanView.style";
import {useControlUrl} from "../web3/useControlUrl";
import {useDispatch, useSelector} from "react-redux";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {approveTokenSpend, updateUserBalance} from "../web3/contracts";
import {Button} from "../components/basic/Button";
import {Header} from "../components/Header";
import {InnerPageHead} from "../components/InnerPageHead";
import {Input} from "../components/basic/Input";
import {Footer} from "../components/Footer";
import {CREATE_CLAN_FEE} from "../web3/config";
import {ethers} from "ethers";

// numbers
const firstRow = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36];
const secondRow = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
const thirdRow = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];
const firstCol = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const secondCol = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
const thirdCol = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
const red = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
const black = [
  2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
];
const even = [
  2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
];
const odd = [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35];
const firstHalf = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
];
const secondHalf = [
  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
];

// combinations
// 37 is a doulbe zero (00)
const zeroLines = [
  [0, 37],
  [37, 3],
  [37, 2, 3],
  [37, 2],
  [0, 37, 2],
  [0, 2],
  [0, 1, 2],
  [0, 1],
  [0, 1, 2, 3, 37],
];

const spiltsHorizontal = [
  [
    [3, 6],
    [6, 9],
    [9, 12],
    [12, 15],
    [15, 18],
    [18, 21],
    [21, 24],
    [24, 27],
    [27, 30],
    [30, 33],
    [33, 36],
  ],
  [
    [2, 5],
    [5, 8],
    [8, 11],
    [11, 14],
    [14, 17],
    [17, 20],
    [20, 23],
    [23, 26],
    [26, 29],
    [29, 32],
    [32, 35],
    [32, 35],
  ],
  [
    [1, 4],
    [4, 7],
    [7, 10],
    [10, 13],
    [13, 16],
    [16, 19],
    [19, 22],
    [22, 25],
    [25, 28],
    [28, 31],
    [31, 34],
    [31, 34],
  ],
];

const spiltsVertical = [
  [
    [2, 3],
    [5, 6],
    [8, 9],
    [11, 12],
    [14, 15],
    [17, 18],
    [20, 21],
    [23, 24],
    [26, 27],
    [29, 30],
    [32, 33],
    [35, 36],
  ],
  [
    [1, 2],
    [4, 5],
    [7, 8],
    [10, 11],
    [13, 14],
    [16, 17],
    [19, 20],
    [22, 23],
    [25, 26],
    [28, 29],
    [31, 32],
    [34, 35],
  ],
];

const threeLines = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  [10, 11, 12],
  [13, 14, 15],
  [16, 17, 18],
  [19, 20, 21],
  [22, 23, 24],
  [25, 26, 27],
  [28, 29, 30],
  [31, 32, 33],
  [34, 35, 36],
];

const corners = [
  [
    [2, 3, 5, 6],
    [5, 6, 8, 9],
    [8, 9, 11, 12],
    [11, 12, 14, 15],
    [14, 15, 17, 18],
    [17, 18, 20, 21],
    [20, 21, 23, 24],
    [23, 24, 26, 27],
    [26, 27, 29, 30],
    [29, 30, 32, 33],
    [32, 33, 35, 36],
    [32, 33, 35, 36],
  ],
  [
    [1, 2, 4, 5],
    [4, 5, 7, 8],
    [7, 8, 10, 11],
    [10, 11, 13, 14],
    [13, 14, 16, 17],
    [16, 17, 19, 20],
    [19, 20, 22, 23],
    [22, 23, 25, 26],
    [25, 26, 28, 29],
    [28, 29, 31, 32],
    [31, 32, 34, 35],
    [31, 32, 34, 35],
  ],
];

const sixLines = [
  [1, 2, 3, 4, 5, 6],
  [4, 5, 6, 7, 8, 9],
  [7, 8, 9, 10, 11, 12],
  [10, 11, 12, 13, 14, 15],
  [13, 14, 15, 16, 17, 18],
  [16, 17, 18, 19, 20, 21],
  [19, 20, 21, 22, 23, 24],
  [22, 23, 24, 25, 26, 27],
  [25, 26, 27, 28, 29, 30],
  [28, 29, 30, 31, 32, 33],
  [31, 32, 33, 34, 35, 36],
  [31, 32, 33, 34, 35, 36],
];

export const Roulette = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const {setUrlSearchParams, getSearchParam} = useControlUrl();
  const refInitialTabParam = useRef(getSearchParam("tab"));
  const [currentTab, setCurrentTab] = useState({
    label: "current_stakes",
    id: "1",
  });

  const [values, setValues] = useState([]);
  const [amount, setAmount] = useState(0);
  const [results, setResults] = useState([]);
  const [stakes, setStakes] = useState([]);
  const [currentStakes, setCurrentStakes] = useState([]);
  const [showStakesHistory, setShowStakesHistory] = useState(false);
  const [spin, setSpin] = useState();
  const [refreshTimer, setRefreshTimer] = useState(false);
  const [refreshNumber, setRefreshNumber] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);

  const getStakeName = (numbers) => {
    switch (true) {
      case arrayEquals(numbers, firstRow):
        return "1st Row";
      case arrayEquals(numbers, secondRow):
        return "2st Row";
      case arrayEquals(numbers, thirdRow):
        return "3st Row";
      case arrayEquals(numbers, firstCol):
        return "1st 12";
      case arrayEquals(numbers, secondCol):
        return "2st 12";
      case arrayEquals(numbers, thirdCol):
        return "3st 12";
      case arrayEquals(numbers, even):
        return "EVEN";
      case arrayEquals(numbers, odd):
        return "ODD";
      case arrayEquals(numbers, firstHalf):
        return "1-18";
      case arrayEquals(numbers, secondHalf):
        return "19-36";
      case arrayEquals(numbers, red):
        return "RED";
      case arrayEquals(numbers, black):
        return "BLACK";
      default:
        return numbers.map(formatNumber).join(", ");
    }
  };

  const sumOfStakes = () => {
    const commitedAmount = currentStakes
      .filter((s) => s.accountId === currentUser.accountId)
      .map((s) => parseInt(s.amount))
      .reduce((p, n) => p + n, 0);

    return commitedAmount + amount;
  };

  const betAccept = async (amountWei) => {
    const options = {value: ethers.utils.parseEther("0.005")}
    contracts.main.acceptBet(amountWei, options).then(transaction => {
      addPendingTransaction(dispatch, transaction, `Bet ${amount} ${process.env.ZML_TOKEN}`);

      transaction.wait().then(async receipt => {
        if (receipt.status === 1) {
          const betResult = await getRequest("api/roulette/stakes/create", {
            hash: transaction.hash,
            accountId: currentUser.accountId,
            numbers: values,
            amount: amountWei,
          });

          if (betResult.data) {
            await updateUserBalance(dispatch, currentUser.accountId);
            setRefreshTimer(true);
          } else {
            alert(`Something went wrong with your bet. ${betResult.message}`);
          }
        } else {
          console.log(`receipt`, receipt);
        }
      });
    });
  }

  const handleStake = async () => {
    if (
      parseInt(amount) < 10 ||
      parseInt(amount) > 500 ||
      sumOfStakes() > 500
    ) {
      alert(`Total amount should be from 10 to 500 ${process.env.ZML_TOKEN}`);
      return;
    }

    if (!values.length) {
      alert(`Please set your bet`);
      return;
    }

    const amountWei = convertToYocto(amount, true);
    const allowed = await window.contracts.token.allowance(
      currentUser.accountId,
      window.contracts.main.address
    );

    if (allowed.lt(amountWei)) {
      approveTokenSpend(dispatch, window.contracts.token, window.contracts.main.address, amountWei).then(() => {
        betAccept(amountWei);
      })
    } else {
      betAccept(amountWei);
    }
  };

  const getResult = async () => {
    const resp = await getRequest("api/roulette/rolls");
    const rolls = resp.data;

    if (rolls) setResults(rolls);
  };

  const getCurrentStakes = async () => {
    const resp = await getRequest("api/roulette/current_stakes");
    if (resp.data) setCurrentStakes(resp.data);
  };

  const getAllStakes = async () => {
    const resp = await getRequest("api/roulette/stakes", {id: spin.id});
    if (resp.data) setStakes(resp.data);
  };

  const getLeaderboard = async () => {
    const resp = await getRequest("api/roulette/all_stakes");
    if (resp.data) {
      const rolls = resp.data.rolls;
      const stakes = resp.data.stakes;

      const res = Object.entries(stakes).map(([accountId, obj]) => {
        const totalAmount = obj
          .map((s) => parseInt(s.amount))
          .reduce((p, n) => p + n, 0);
        const winAmount = rolls
          .map((roll) =>
            obj
              .filter(
                (s) =>
                  s.rouletteRollId === roll.id &&
                  s.numbers.includes(roll.number)
              )
              .map((s) => (s.amount * 36) / s.numbers.length)
              .reduce((p, n) => p + n, 0)
          )
          .reduce((p, n) => p + n, 0);

        return [accountId, winAmount - totalAmount];
      });

      setLeaderboard(res);
    }
  };

  const initTabs = () => {
    const tabs = [
      {label: "current_stakes", id: "1", title: "Current stakes"},
      {label: "rolls", id: "2", title: "History"},
      // {label: "leaderboard", id: "3", title: "King of Roulette"},
    ];
    setTabs(tabs);
    setCurrentTab(
      tabs.find(({label}) => label === refInitialTabParam.current) ?? tabs[0]
    );
  };

  useEffect(() => {
    spin && getAllStakes();
  }, [spin]);

  useEffect(() => {
    getResult();
    getCurrentStakes();
    initTabs();
  }, [refreshTimer]);

  useEffect(() => {
    if (currentTab?.label === "leaderboard") getLeaderboard();
  }, [currentTab?.label === "leaderboard"]);

  const changeCurrentTab = (newCurrentTab) => {
    setCurrentTab(newCurrentTab);
    setUrlSearchParams("tab", newCurrentTab.label);
  };

  const handleClick = (e, v) => {
    e.stopPropagation();
    if (!v?.length) return;

    setValues(v.sort((a, b) => a - b));
  };

  const calculateWin = (values, amount) => {
    const parsedAmount = parseInt(amount);
    const win = parseInt((parsedAmount * 36) / values.length);

    return win < parsedAmount ? parsedAmount : win;
  };

  const rollTime = (roll) => {
    let [_t, hours, minutes] = roll.time.match(/(\d+):(\d+)/);
    hours = hours.length === 2 ? hours : `0${hours}`;
    minutes = minutes.length === 2 ? minutes : `0${minutes}`;

    return `${hours}:${minutes}`;
  };

  const resultNumbers = results.map((obj) => obj.number);

  const Edge = ({position, numbers}) => (
    <div
      onClick={(e) => handleClick(e, numbers)}
      className={`${position} hover:bg-orange-500  hover:border-2 border-white cursor-pointer rounded-full absolute w-4 h-4 z-10`}
    />
  );

  const Number = ({
                    topRightCorner,
                    bottomRightCorner,
                    top,
                    bottom,
                    right,
                    number,
                    color,
                  }) => (
    <div
      className={`relative border border-indigo-100  ${color} cursor-pointer flex justify-center items-center w-14 h-14`}
    >
      <Edge numbers={topRightCorner} position="-top-2 -right-2"/>
      <Edge numbers={bottomRightCorner} position="-bottom-2 -right-2"/>
      <Edge numbers={top} position="-top-2 left-5"/>
      <Edge numbers={bottom} position="-bottom-2 left-5"/>
      <Edge numbers={right} position="top-5 -right-2"/>

      <div
        onClick={(e) => handleClick(e, [number])}
        className={`${
          values.indexOf(number) !== -1
            ? "bg-green-500 text-black border-2 border-green-600 shadow-sm"
            : "text-white"
        } -rotate-90 md:rotate-0 rounded-full text-base p-3 w-8 h-8 flex justify-center items-center`}
      >
        {number}
      </div>
    </div>
  );

  const ZeroNumbers = () => (
    <div className="flex flex-col w-14 h-42 ">
      <div
        onClick={(e) => handleClick(e, [37])}
        className={`relative border border-indigo-100 bg-green-500 cursor-pointer flex justify-center items-center w-14 h-full`}
      >
        <Edge numbers={zeroLines[8]} position="-top-2 -right-2"/>
        <Edge numbers={zeroLines[1]} position="top-5 -right-2"/>
        <Edge numbers={zeroLines[2]} position="top-12 -right-2"/>
        <Edge numbers={zeroLines[3]} position="bottom-1 -right-2"/>
        <Edge numbers={zeroLines[4]} position="-bottom-2 -right-2"/>
        <Edge numbers={zeroLines[0]} position="-bottom-2 right-5"/>

        <div
          className={`${
            values.indexOf(37) !== -1
              ? "bg-green-600 text-black border-2 border-green-700 shadow-sm"
              : "text-white"
          } -rotate-90 md:rotate-0 w-7 h-7 flex rounded-full justify-center items-center`}
        >
          00
        </div>
      </div>
      <div
        onClick={(e) => handleClick(e, [0])}
        className={`relative border border-indigo-100 bg-green-500 cursor-pointer flex justify-center items-center w-14 h-full`}
      >
        <Edge numbers={zeroLines[0]} position="-top-2 right-5"/>
        <Edge numbers={zeroLines[4]} position="-top-2 -right-2"/>
        <Edge numbers={zeroLines[5]} position="top-1 -right-2"/>
        <Edge numbers={zeroLines[6]} position="top-5 -right-2"/>
        <Edge numbers={zeroLines[7]} position="top-12 -right-2"/>
        <Edge numbers={zeroLines[8]} position="-bottom-2 -right-2"/>

        <div
          className={`${
            values.indexOf(0) !== -1
              ? "bg-green-600 text-black border-2 border-green-700 shadow-sm"
              : "text-white"
          } -rotate-90 md:rotate-0 w-7 h-7 flex rounded-full justify-center items-center`}
        >
          0
        </div>
      </div>
    </div>
  );

  const Section = ({title, styles, numbers}) => (
    <div
      onClick={(e) => handleClick(e, numbers)}
      className={`${styles} border border-indigo-100 cursor-pointer flex justify-center items-center`}
    >
      <span className="-rotate-90 md:rotate-0">{title}</span>
    </div>
  );

  const Board = () => (
    <div className="h-[900px] flex items-center md:justify-center md:w-full md:h-full">
      <div className="flex flex-col font-semibold rotate-90 md:rotate-0 p-5 rounded-lg">
        <div className="flex flex-row bg-mainLight">
          <ZeroNumbers/>
          <div className="flex flex-col border border-indigo-100">
            <div className="flex flex-row">
              {firstRow.map((number, i) => (
                <Number
                  key={'f' + i}
                  color={red.indexOf(number) === -1 ? "bg-black" : "bg-red-600"}
                  number={number}
                  top={threeLines[i]}
                  topRightCorner={sixLines[i]}
                  right={spiltsHorizontal[0][i]}
                  bottom={spiltsVertical[0][i]}
                  bottomRightCorner={corners[0][i]}
                />
              ))}
            </div>
            <div className="flex flex-row">
              {secondRow.map((number, i) => (
                <Number
                  color={red.indexOf(number) === -1 ? "bg-black" : "bg-red-600"}
                  number={number}
                  key={'s' + i}
                  top={spiltsVertical[0][i]}
                  topRightCorner={corners[0][i]}
                  right={spiltsHorizontal[1][i]}
                  bottom={spiltsVertical[1][i]}
                  bottomRightCorner={corners[1][i]}
                />
              ))}
            </div>
            <div className="flex flex-row">
              {thirdRow.map((number, i) => (
                <Number
                  key={'th' + i}
                  color={red.indexOf(number) === -1 ? "bg-black" : "bg-red-600"}
                  number={number}
                  top={spiltsVertical[1][i]}
                  topRightCorner={corners[1][i]}
                  right={spiltsHorizontal[2][i]}
                  bottom={threeLines[i]}
                  bottomRightCorner={sixLines[i]}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col w-28">
            <Section numbers={firstRow} styles="h-1/3" title="2 to 1"/>
            <Section numbers={secondRow} styles="h-1/3" title="2 to 1"/>
            <Section numbers={thirdRow} styles="h-1/3" title="2 to 1"/>
          </div>
        </div>
        <div className="flex flex-col ml-14 mr-28 bg-mainLight">
          <div className="flex flex-row h-14">
            <Section numbers={firstCol} styles="w-1/3" title="1st 12"/>
            <Section numbers={secondCol} styles="w-1/3" title="2nd 12"/>
            <Section numbers={thirdCol} styles="w-1/3" title="3rd 12"/>
          </div>
          <div className="flex flex-row h-14">
            <Section numbers={firstHalf} styles="w-1/6" title="1-18"/>
            <Section numbers={even} styles="w-1/6" title="EVEN"/>
            <Section numbers={red} styles="w-1/6 bg-red-600" title="RED"/>
            <Section numbers={black} styles="w-1/6 bg-black" title="BLACK"/>
            <Section numbers={odd} styles="w-1/6" title="ODD"/>
            <Section numbers={secondHalf} styles="w-1/6" title="19-36"/>
          </div>
        </div>
      </div>
    </div>
  );

  const formatNumber = (num) => (num === 37 ? "00" : num);

  const Numbers = ({values, animate}) => (
    <div className="text-base font-semibold flex-wrap  flex items-center gap-x-2 gap-y-2">
      {values.map((number, i) => (
        <div
          key={i}
          className={`flex relative rounded-full w-7 h-7 text-white justify-center items-center  ${
            number === 0 || number === 37
              ? "bg-green-500"
              : number === "-"
                ? "bg-main"
                : red.indexOf(number) === -1
                  ? "bg-black"
                  : "bg-red-600"
          }`}
        >
          {animate && i === 0 ? (
            <>
              <span className="animate-ping absolute z-0 w-5 h-5 rounded-full bg-violet-500"></span>
              <div className="z-10 absolute">{formatNumber(number)}</div>
            </>
          ) : (
            <div>{formatNumber(number)}</div>
          )}
        </div>
      ))}
    </div>
  );

  const Stakes = ({stakes, spin}) => (
    <div className="p-5 bg-main lg:w-3/4 w-full">
      <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
        <Col className="w-1/4 md:flex-1 mr-5">Account</Col>
        <Col className="w-1/4 md:w-1/2 mr-5">Numbers</Col>
        <Col className="w-1/4 md:w-32 mr-5">Stake</Col>
        <Col className="w-1/4 md:w-32 ">Win</Col>
      </Row>

      <Col>
        {stakes.map((stake, i) => (
          <Row
            key={i + 1}
            className={`${
              stake.numbers.includes(spin?.number) && "text-orange-600"
            } py-1`}
          >
            <div className="w-1/4 md:flex-1 text-sm truncate text-ellipsis mr-5">
              {shortAddress(stake.accountId)}
            </div>
            <div className="w-1/4 md:w-1/2 mr-5">
              {getStakeName(stake.numbers)}
            </div>
            <div className="w-1/4 md:w-32 mr-5">{stake.amount} {process.env.ZML_TOKEN}</div>

            {stake.numbers.includes(spin?.number) ? (
              <div className="w-1/4 md:w-32 font-semibold">
                {calculateWin(stake.numbers, stake.amount)}
              </div>
            ) : (
              <div className="w-1/4 md:w-32 text-white/40">0</div>
            )}
          </Row>
        ))}
      </Col>
    </div>
  );

  const RollHistory = () => (
    <>
      {showStakesHistory ? (
        <>
          <Button
            title="Back"
            secondary
            icon={<ArrowLeftIcon className={`ml-2 hidden md:inline h-4`}/>}
            onClick={() => setShowStakesHistory(false)}
          />
          <Stakes stakes={stakes} spin={spin}/>
        </>
      ) : (
        <div className="p-5 bg-main lg:w-1/3 w-full">
          <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
            <Col className="w-1/3">Date</Col>
            <Col className="w-1/3">Number</Col>
            <Col className="w-1/3">Total Win</Col>
          </Row>
          <Col>
            {results.map((spin, i) => (
              <Row
                key={i + 1}
                className="py-1 cursor-pointer hover:bg-mainLight"
                onClick={() => {
                  setSpin(spin);
                  setShowStakesHistory(true);
                }}
              >
                <div className="w-1/3 text-indigo-200">{rollTime(spin)}</div>
                <div className="w-1/3">
                  <Numbers values={[spin.number]}/>
                </div>
                <div
                  className={`w-1/3 ${
                    spin.totalWin > 0
                      ? "text-orange-600 font-semibold"
                      : "text-white/40"
                  }`}
                >
                  {spin.totalWin}
                </div>
              </Row>
            ))}
          </Col>
        </div>
      )}
    </>
  );

  const currentUserStats = () =>
    leaderboard.find(([accountId, amount]) => accountId === currentUser.accountId);

  const getRemainingDays = () => {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);

    return time.getDate() > date.getDate()
      ? time.getDate() - date.getDate()
      : 0;
  };

  const Leaderboard = () => (
    <div className="p-5 bg-main lg:w-1/3 w-full ">
      {getRemainingDays() >= 0 && (
        <div className="flex flex-col bg-mainLight justify-center mb-10 rounded-lg px-3 py-5">
          <div className="text-lg mb-3 text-center">
            Current Event:{" "}
            <span className="font-semibold text-orange-500">
              {getRemainingDays()} days left
            </span>
          </div>
          <div className="text-sm text-center ">
            <p className="text-lg mb-3 font-semibold text-indigo-200">
              TOP-5 success users will receive:
            </p>
            <p>
              ✨ 1 epic monster for <b>10000+</b> income
            </p>
            <p>
              ✨ 1 rare monster for <b>5000+</b> income
            </p>
            <p>
              ✨ 1 uncommon monster for <b>3000+</b> income
            </p>
          </div>
        </div>
      )}
      {currentUserStats() && currentUser.accountId === currentUserStats()[0] && (
        <div className="flex text-lg flex-row font-bold bg-white/5 justify-center mb-10 rounded-lg px-5 py-5">
          <div>Your statistic:</div>
          <div
            className={`ml-3 font-semibold ${
              currentUserStats()[1] >= 3000
                ? "text-green-600"
                : currentUserStats()[1] >= 0
                  ? "text-indigo-200"
                  : "text-red-500"
            }`}
          >
            {currentUserStats()[1]}
          </div>
        </div>
      )}
      <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
        <Col className="w-2/3 mr-5">Account</Col>
        <Col className="w-1/3">Income</Col>
      </Row>
      <Col>
        {leaderboard
          .sort((a, b) => b[1] - a[1])
          .slice(0, 5)
          .map(([accountId, amount], i) => (
            <Row key={i + 1} className="py-1">
              <div className="w-2/3 truncate mr-5">{accountId}</div>
              <div
                className={`w-1/3 font-semibold ${
                  amount >= 3000
                    ? "text-green-600"
                    : amount >= 0
                      ? "text-indigo-200"
                      : "text-red-500"
                }`}
              >
                {amount > 0 ? `+${amount}` : amount}
              </div>
            </Row>
          ))}
      </Col>
    </div>
  );

  return (
    <InnerPageWrapper>
      <Header/>

      <div className="mb-auto relative">
        <div className="flex flex-col text-white mt-6 items-center">
          <InnerPageHead title="Roulette"/>

          <RouletteTimer
            setRefreshTimer={setRefreshTimer}
            setRefreshNumber={setRefreshNumber}
          />

          <div className="bg-mainLight flex flex-col rounded-lg p-3 mt-5">
            <Numbers
              values={
                refreshNumber
                  ? ["-", ...resultNumbers.slice(0, 14)]
                  : resultNumbers
              }
              animate
            />
          </div>

          <Board/>

          <div className="flex gap-x-3 mb-5">
            <Input
              type="number"
              placeholder="Enter your stake"
              handleChange={(e) =>
                setAmount(
                  e.target.value === "" || parseInt(e.target.value) < 0
                    ? 0
                    : parseInt(e.target.value)
                )
              }
              value={amount}
            />
            <Button title="Submit" onClick={handleStake}/>
          </div>

          {values.length > 0 && (
            <div className="bg-mainLight p-3 md:w-1/2 rounded-lg justify-center items-center flex flex-col">
              <b>
                Your numbers:{" "}
                {values.length > 0 && (
                  <a
                    className="ml-2 font-semibold cursor-pointer text-orange-600"
                    onClick={() => setValues([])}
                  >
                    Clear selection
                  </a>
                )}
              </b>

              <div className="mt-3">
                <Numbers values={values}/>
              </div>

              {values.length > 0 && (
                <div className="mt-3">
                  <p>
                    Total win:{" "}
                    <span className="font-semibold text-green-500">
                      {calculateWin(values, amount).toFixed(2)} ZML
                    </span>
                  </p>
                </div>
              )}
            </div>
          )}

          <S.TabContainer>
            {tabs.map((tab) => (
              <S.Tab
                key={tab.id}
                onClick={() => changeCurrentTab(tab)}
                selectedTab={tab.id === currentTab.id}
                enabled
                dangerouslySetInnerHTML={{__html: tab.title}}
              />
            ))}
          </S.TabContainer>

          {currentTab.id === "1" ? (
            <Stakes stakes={currentStakes}/>
          ) : currentTab.id === "2" ? (
            <RollHistory/>
          ) : (
            // <Leaderboard />
            <></>
          )}
        </div>
      </div>

      <Footer/>
    </InnerPageWrapper>
  );
};
