import React, {useEffect, useState} from "react";
import {addPendingTransaction, addTransactionError, convertToYocto} from "../../web3/utils";
import {Col, Row} from "../../assets/styles/common.style";
import {PlusIcon} from "@heroicons/react/solid";
import {BASIC_STAKE, LEVEL_MAP} from "../../web3/config";
import {useDispatch, useSelector} from "react-redux";
import {getRequest, postRequest} from "../../web3/api";
import {transformZombie} from "../../web3/transform";
import {Popup} from "../Popup";
import {Dropdown} from "../basic/Dropdown";
import {CardRotate} from "../cards/CardRotate";
import {CardItem} from "../cards/CardItem";
import {Button} from "../basic/Button";
import {Loader} from "../basic/Loader";
import {SelectInventoryPopup} from "../../pages/inventory/SelectInventoryPopup";
import {SelectZombiesPopup} from "./SelectZombiesPopup";
import {BigNumber} from "ethers";
import {approveTokenSpend, joinClanCall, updateUserBalance} from "../../web3/contracts";

const ZOMBIES_PAGE_LIMIT = 1000;

export const RequestClanBattlePopup = (
  {
    clan,
    visible,
    setVisible,
    title,
    isCreate,
    battleId,
    btnTitle,
    successJoined,
    levelParam,
  }) => {
  const currentUser = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const [userZombies, setUserZombies] = useState([0, []]);
  const [currentZombiePage, setCurrentZombiePage] = useState(1);
  const [zombiesPopupVisible, setZombiesPopupVisible] = useState(false);
  const [inventoryPopupVisible, setInventoryPopupVisible] = useState(false);

  const [currentZombiePosition, setCurrentZombiePosition] = useState(0);
  const [currentInventoryPosition, setCurrentInventoryPosition] = useState(0);
  const [selectedZombies, setSelectedZombies] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState([]);

  // fields
  const [playersSize, setPlayersSize] = useState(0);
  const [rarity, setRarity] = useState("Common");
  const [level, setLevel] = useState(levelParam || 0);

  const power = (zombie) =>
    zombie.health + zombie.brain + zombie.attack + zombie.speed;

  async function fetchUserZombies(currentPage, rarity) {
    const zombiesRequest = await contracts.zombie.userZombies(currentPage, ZOMBIES_PAGE_LIMIT, 0, rarity);
    const zombies = zombiesRequest[1]
      .map((zombie) => transformZombie(zombie))
      .filter((zombie) => power(zombie) <= LEVEL_MAP[rarity][level])
      .sort((a, b) => power(b) - power(a));
    setUserZombies([zombiesRequest[0], zombies]);
  }

  const loadPopupData = async () => {
    setIsReady(false);

    if (!isCreate) {
      const battle = await getRequest(`api/clan-battle/info/${battleId}`);
      if (battle.data?.cardRarity) {
        setRarity(battle.data.cardRarity);
        setPlayersSize(battle.data.requiredSize);
        await fetchUserZombies(currentZombiePage, battle.data.cardRarity);
      }
    } else {
      setPlayersSize(playersSize || 10);
      await fetchUserZombies(currentZombiePage, rarity);
    }
  };

  useEffect(() => {
    if (isCreate) {
      let limitPlaces = playersSize === 6 ? 1 : playersSize / 2;
      generateEmptyZombiesList(limitPlaces);
    } else {
      getRequest(`api/clan-battle/joined-count/${battleId}`, {
        clanId: clan.id,
      }).then((battleJoined) => {
        let limitPlaces =
          playersSize === 6 ? 1 : playersSize / 2 - battleJoined.data;
        generateEmptyZombiesList(limitPlaces);
      });
    }
  }, [playersSize, rarity, level]);

  const generateEmptyZombiesList = (limitPlaces) => {
    let emptyList = [];
    for (let i = 0; i < limitPlaces; i++) {
      emptyList.push(null);
    }
    setSelectedZombies([...emptyList]);
    setSelectedInventory([...emptyList]);
  };

  useEffect(() => {
    if (visible && rarity) {
      loadPopupData().then(() => {
        setIsReady(true);
      });
    } else {
      // reset on close
      setPlayersSize(0);
      setIsReady(false);
    }
  }, [rarity, visible, level]);

  const onZombiePageChanged = (page) => {
    window.scrollTo({top: 0, behavior: "smooth"});
    setCurrentZombiePage(page);
    fetchUserZombies(page, rarity);
  };

  const selectZombie = (zombie) => {
    selectedZombies[currentZombiePosition] = zombie;
    setSelectedZombies([...selectedZombies]);
    setZombiesPopupVisible(false);
    setIsReady(true);
  };

  const selectInventory = (item) => {
    selectedInventory[currentInventoryPosition] = item;
    setSelectedInventory([...selectedInventory]);
    setInventoryPopupVisible(false);
    setIsReady(true);
  };

  // TODO: useMemo
  const zombiesInPopup = () => {
    if (zombiesPopupVisible && userZombies) {
      return userZombies[1].filter((zombie) => {
        let exists = false;
        selectedZombies.map((innerZombie) => {
          if (innerZombie && innerZombie.tokenId === zombie.tokenId) {
            exists = true;
          }
        });
        return !exists;
      });
    }
    return [];
  };

  const totalBattleZML = (rarity) => {
    const zombieCount = selectedZombies.filter((zm) => zm).length;
    return BASIC_STAKE[rarity].stake * zombieCount;
  };

  const winBattleZML = () => {
    const zombieCount = selectedZombies.filter((zm) => zm).length;
    return BASIC_STAKE[rarity].prize[level] * zombieCount;
  };

  const canParticipateInBattle = (rarity) => {
    const battleZML = totalBattleZML(rarity);
    const battleZMLYocto = convertToYocto(battleZML, true);
    const balance = currentUser.tokenBalance || 0;

    if (BigNumber.from(balance.toString()).lt(battleZMLYocto)) {
      alert(`Not enough ${process.env.ZML_TOKEN} balance to participate in this battle`);
      return false;
    }
    if (!selectedZombies.filter((zm) => zm).length) {
      alert("Please select zombie");
      return false;
    }
    return battleZML;
  };

  const createBattle = async () => {
    const battleZML = BASIC_STAKE[rarity].stake;
    if (battleZML) {
      setIsReady(false);
      try {
        const battleResponse = await postRequest("api/clan-battle/create", {
          accountId: currentUser.accountId,
          cardRarity: rarity,
          toClanId: clan.id,
          requiredSize: playersSize,
          entryFee: battleZML,
          level: level,
        });

        if (!battleResponse.error) {
          // save temporary variable to show "Battle Created" popup
          localStorage.setItem("pending_create_battle", battleResponse.data.id);
          await joinBattle(battleResponse.data.id);
        } else {
          alert(battleResponse.error);
          setIsReady(true);
          setVisible(false);
        }
      } catch (error) {
        console.error(error);
        alert("Blockchain error, please try one more time in few minutes.");
        setIsReady(true);
        setVisible(false);
      }
    }
  };

  const resetPopupData = () => {
    setIsReady(true);
    setVisible(false);
    setSelectedZombies([]);
    setSelectedInventory([]);
  }

  const handleJoinBattle = async (inventoryIdList, zombieIdList, battleBet, battleId) => {
    setIsReady(false);
    const joinResponse = await postRequest("api/clan-battle/join", {
      accountId: currentUser.accountId,
      clanBattleId: battleId,
      inventoryIdList,
      zombieIdList,
    });

    if (!joinResponse.error) {
      try {
        await window.contracts.clan.joinBattleTransfers(
          battleBet, zombieIdList, inventoryIdList
        ).then(transaction => {
          addPendingTransaction(dispatch, transaction, `Join clan battle`);
          transaction.wait().then(receipt => {
            if (receipt.status === 1) {
              successJoined();
              updateUserBalance(dispatch, currentUser.accountId);
            }

            resetPopupData();
          });
        });
      } catch (e) {
        addTransactionError(dispatch, e.message);
        resetPopupData();
      }
    } else {
      alert(`Can't join this battle. ${joinResponse.error}`);
      setIsReady(true);
      setVisible(false);
    }
  }

  const joinBattle = async (battleId) => {
    const battle = await getRequest(`api/clan-battle/info/${battleId}`);
    const battleBet = canParticipateInBattle(battle.data.cardRarity);
    if (battleBet) {
      const inventoryIdList = selectedInventory
        .filter((i) => i)
        .map((item) => item.tokenId.toString());
      const zombieIdList = selectedZombies
        .filter((zm) => zm)
        .map((item) => item.tokenId.toString());

      const allowed = await window.contracts.token.allowance(
        currentUser.accountId,
        window.contracts.clan.address
      );

      const betWei = convertToYocto(battleBet, true);
      if (allowed.lt(betWei)) {
        approveTokenSpend(dispatch, window.contracts.token, window.contracts.clan.address, betWei).then(() => {
          handleJoinBattle(inventoryIdList, zombieIdList, battleBet, battleId);
        });
      } else {
        handleJoinBattle(inventoryIdList, zombieIdList, battleBet, battleId);
      }
    } else {
      setIsReady(true);
    }
  };

  return (
    <Popup
      title={title}
      popupVisible={visible}
      setPopupVisible={setVisible}
      width={"sm:w-[920px]"}
    >
      {isReady ? (
        <>
          <div>
            {isCreate && (
              <div className="flex flex-row justify-center gap-8 mb-10 -mt-1">
                <Col className="gap-1 text-center w-32">
                  <label className={"text-sm font-semibold"}>
                    Battle Format
                  </label>
                  <Dropdown
                    zindex="z-30"
                    options={[
                      {title: "3x3", onClick: () => setPlayersSize(6)},
                      {title: "5x5", onClick: () => setPlayersSize(10)},
                      {title: "7x7", onClick: () => setPlayersSize(14)},
                      {title: "9x9", onClick: () => setPlayersSize(18)},
                    ]}
                    selected={`${playersSize / 2} x ${playersSize / 2}`}
                  />
                </Col>

                <Col className="gap-1 text-center w-48">
                  <label className={"text-sm font-semibold"}>Card Type</label>
                  <Dropdown
                    zindex="z-20"
                    options={[
                      {
                        title: "Common",
                        onClick: () => {
                          setRarity("Common");
                          setPlayersSize(playersSize);
                        },
                      },
                      {
                        title: "Uncommon",
                        onClick: () => {
                          setRarity("UnCommon");
                          setPlayersSize(playersSize);
                        },
                      },
                      {
                        title: "Rare",
                        onClick: () => {
                          setRarity("Rare");
                          setPlayersSize(playersSize);
                        },
                      },
                      {
                        title: "Epic",
                        onClick: () => {
                          setRarity("Epic");
                          setPlayersSize(playersSize);
                        },
                      },
                    ]}
                    selected={rarity}
                  />
                </Col>

                {rarity && (
                  <Col className="text-center gap-1 w-32">
                    <label className={"text-sm font-semibold"}>
                      Power Limit
                    </label>
                    <Dropdown
                      zindex="z-10"
                      options={[
                        {
                          title: `Max ${LEVEL_MAP[rarity][0]}`,
                          onClick: () => {
                            setLevel(0);
                            setPlayersSize(playersSize);
                          },
                        },
                        {
                          title: `Max ${LEVEL_MAP[rarity][1]}`,
                          onClick: () => {
                            setLevel(1);
                            setPlayersSize(playersSize);
                          },
                        },
                        {
                          title: `Max ${LEVEL_MAP[rarity][2]}`,
                          onClick: () => {
                            setLevel(2);
                            setPlayersSize(playersSize);
                          },
                        },
                        {
                          title: `Max ${LEVEL_MAP[rarity][3]}`,
                          onClick: () => {
                            setLevel(3);
                            setPlayersSize(playersSize);
                          },
                        },
                      ]}
                      selected={`Max ${LEVEL_MAP[rarity][level]}`}
                    />
                  </Col>
                )}
              </div>
            )}

            <p className={"mb-6 w-2/3 mx-auto"}>
              Choose
              {selectedZombies.length > 1
                ? " one or more zombies "
                : " zombie "}
              for clan battle, and you have the option to utilize your inventory
              to boost zombie power.
            </p>

            <div className="flex flex-row flex-wrap gap-6 justify-center">
              {selectedZombies.map((zombie, index) => (
                <div
                  className={`flex gap-2 ${
                    selectedZombies.length > 1 ? "flex-col" : "place-items-end"
                  }`}
                  key={index}
                >
                  <div
                    className={`w-[140px] h-[196px] rounded-xl transition duration-200 cursor-pointer 
                    ${zombie ? "" : "bg-main hover:bg-main/50"}`}
                    onClick={() => {
                      setZombiesPopupVisible(true);
                      setCurrentZombiePosition(index);
                    }}
                  >
                    {zombie ? (
                      <CardRotate nft={zombie} size="sm"/>
                    ) : (
                      <>
                        <PlusIcon className="w-8 h-8 mx-auto mt-20"/>
                        <div className="text-center text-sm mt-12">
                          Select Zombie
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={`w-[140px] min-h-[100px] rounded-xl transition duration-200 cursor-pointer
                    ${selectedInventory[index] ? "" : "bg-main"}
                    ${zombie ? "" : "pointer-events-none opacity-40"}`}
                    onClick={() => {
                      setInventoryPopupVisible(true);
                      setCurrentInventoryPosition(index);
                    }}
                  >
                    {selectedInventory[index] ? (
                      <div className={"ml-1"}>
                        <CardItem
                          isItem
                          item={selectedInventory[index]}
                          noFlip
                          size="sm"
                        />
                      </div>
                    ) : (
                      <>
                        <PlusIcon className="w-6 h-6 mx-auto mt-6"/>
                        <div className="text-center text-sm mt-4">
                          Add Inventory
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="my-6 bg-main/30 py-3 gap-2 text-cyan-200">
            <div>
              <span>Your bet: </span>
              <span className="text-orange-500 font-semibold">
                {totalBattleZML(rarity)} ZML
              </span>
            </div>
            <div>
              <span>On win you will receive: </span>
              <span className="text-orange-500 font-bold">
                {totalBattleZML(rarity) + winBattleZML()} ZML
              </span>
            </div>
          </div>

          <Button
            title={btnTitle}
            size="md"
            onClick={() => (isCreate ? createBattle() : joinBattle(battleId))}
            disabled={!isReady || !selectedZombies.filter((zm) => zm).length}
          />

          <SelectZombiesPopup
            title="Select Zombie for Battle"
            zombiesPopupVisible={zombiesPopupVisible}
            setZombiesPopupVisible={setZombiesPopupVisible}
            zombiesInPopup={zombiesInPopup}
            userCollectionZombies={userZombies}
            selectZombie={selectZombie}
            currentPage={currentZombiePage}
            pageLimit={ZOMBIES_PAGE_LIMIT}
            onPageChanged={onZombiePageChanged}
            nftType={"zombie"}
          />

          <SelectInventoryPopup
            title="Select Inventory Item"
            inventoryPopupVisible={inventoryPopupVisible}
            setInventoryPopupVisible={setInventoryPopupVisible}
            selectInventory={selectInventory}
            selectedInventory={selectedInventory}
          />
        </>
      ) : (
        <Loader/>
      )}
    </Popup>
  );
};
