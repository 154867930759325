import React, {useEffect, useState, useRef} from "react";
import {LandRegions, resetDiscoveryPriceMap} from "../../web3/content";
import {
  diffDays,
  secondsToStringShort,
  timeDiffSeconds,
} from "../../web3/timeFormat";
import {RefreshIcon} from "@heroicons/react/outline";
import {addPendingTransaction, addTransactionError, convertFromYocto, maxDiscoveryEvents} from "../../web3/utils";

import discoveryIcon from "../../assets/images/discovery-icon.png";
import brokenIcon from "../../assets/images/broken.png";
import ripIcon from "../../assets/images/rip.png";
import {postRequest} from "../../web3/api";
import {useDispatch, useSelector} from "react-redux";
import {transformItem, transformModifier, transformMonster} from "../../web3/transform";
import {Popup} from "../../components/Popup";
import {Button} from "../../components/basic/Button";
import {CardRotate} from "../../components/cards/CardRotate";
import {Pagination} from "../../components/Pagination";
import {CardLandRegion} from "../../components/cards/CardLandRegion";
import {CardItem} from "../../components/cards/CardItem";
import {Loader} from "../../components/basic/Loader";
import {PartOfMonster} from "../../components/cards/PartOfMonster";
import {updateUserBalance} from "../../web3/contracts";

const PAGE_LIMIT = 8;

export const DiscoverLandPopup = (
  {
    setDiscoverLandPopupVisible,
    discoverLandPopupVisible,
    discoverLandItem,
    handleDiscoverFinished,
  }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.user);
  const funRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(Date.now());
  const [currentStep, setCurrentStep] = useState(1);
  const [landRegion, setLandRegion] = useState(null);
  const [selectedMonster, setSelectedMonster] = useState();
  const [userMonsters, setUserMonsters] = useState([0, []]); // [<count>, [<arrayOfMonsters>]]
  const [currentPage, setCurrentPage] = useState(1);
  const [showDiscoveryLoading, setShowDiscoveryLoading] = useState(false);
  const [discoveryResult, setDiscoveryResult] = useState();
  const [showDiscoveryResult, setShowDiscoveryResult] = useState(false);
  const [foundInventoryItems, setFoundInventoryItems] = useState([]);
  const [foundModifier, setFoundModifier] = useState([]);
  const [foundMonsterPart, setFoundMonsterPart] = useState({});
  const [foundZmlAmount, setFoundZmlAmount] = useState(0);
  const [discoveryEventCount, setDiscoveryEventCount] = useState(0);

  const stepLineMap = {
    1: "md:left-32 sm:left-28 left-16",
    2: "left-[50%]",
    3: "left-[100%]",
  };

  const loadMonsters = async (page) => {
    const monstersObj = await window.contracts.monster.userMonsters(page, PAGE_LIMIT, "");
    const monsters = monstersObj[1].filter(monster => monster.nftType).map(
      monster => transformMonster(monster)
    ).reverse();
    setUserMonsters([parseInt(monstersObj[0]), monsters]);
  };

  useEffect(() => {
    if (discoverLandItem) {
      loadMonsters(currentPage);
      setCurrentPage(1);
      setDiscoveryEventCount(discoverLandItem.discoverEvents);
    }
  }, [discoverLandItem]);

  useEffect(() => {
    repeatDiscover(true);
  }, [discoverLandPopupVisible]);

  useEffect(() => {
    if (currentStep === 1) {
      funRef.current = setInterval(() => {
        setCurrentDate(Date.now());
      }, 1000);
      return () => {
        clearInterval(funRef.current);
      };
    } else {
      clearInterval(funRef.current);
    }
  }, [currentStep]);

  const onPageChanged = (page) => {
    setCurrentPage(page);
    loadMonsters(page);
  };

  const StepCircle = ({step}) => (
    <div className="w-1/3 z-10 relative cursor-default">
      <div
        className={`border-2 rounded-full w-10 h-10 mx-auto pt-1 text-lg text-center font-semibold
        ${
          currentStep >= step
            ? "text-orange-400 bg-orange-50 border-orange-400"
            : "text-gray-400 bg-gray-100"
        }`}
      >
        {step}
      </div>
    </div>
  );

  const StepDescription = ({step, text}) => (
    <div className="w-1/3">
      <div
        className={`text-center text-sm mt-1 font-semibold 
      ${currentStep >= step ? "text-orange-400" : "text-gray-400"}
      `}
      >
        {text}
      </div>
    </div>
  );

  const selectMonsterForDiscovery = (monster) => {
    if (canDiscover(monster.nextLandDiscovery)) {
      setSelectedMonster(monster);
      setCurrentStep(2);
    } else {
      alert("This monster can't discover your Land, need a rest.");
    }
  };

  const showDiscoveryResults = async (discoveryResult, values, rewardDurability) => {
    setDiscoveryResult(discoveryResult);

    // load additional info
    if (discoveryResult === 1) {
      // Modifier
      let foundModifiers = [];
      const modifiersResponse = await window.contracts.modifier.userItems(1, 10, 0);
      const userModifiers = modifiersResponse[1].map(item => transformModifier(item));
      userModifiers
        .filter((item) => values.includes(item.tokenId))
        .map((item) => {
          foundModifiers.push(item);
        });
      setFoundModifier(foundModifiers);

    } else if (discoveryResult === 2) {
      // Inventory Items
      let foundItems = [];
      const inventoryItemResponse = await window.contracts.inventory.userItems(1, 10, 0);
      const inventoryItems = inventoryItemResponse[1].map(item => transformItem(item));

      inventoryItems
        .filter((item) => values.includes(item.tokenId))
        .map((item) => {
          item.durabilityMin = rewardDurability;
          item.durabilityMax = rewardDurability;
          foundItems.push(item);
        });

      if (foundItems.length > 0) {
        await postRequest("api/items/create", {items: foundItems});
      }
      setFoundInventoryItems(foundItems);

    } else if (discoveryResult === 3) {
      setFoundZmlAmount(values[0]);
      updateUserBalance(dispatch, currentUser.accountId);
    }
    // else if (discoveryResult === 4) {
    //   // Staking monster part
    //   const monsterPart = await mainContract.monsterPartDetails(values[0]);
    //   setFoundMonsterPart(monsterPart);
    // }

    handleDiscoverFinished();
    setShowDiscoveryLoading(true);
    setTimeout(() => {
      setShowDiscoveryResult(true);
      loadMonsters(currentPage);
    }, 1800);
  }

  const getRandomArbitrary = (min, max) => {
    return parseInt(Math.random() * (max - min) + min);
  }

  const selectRegion = async (landRegion) => {
    setLandRegion(landRegion);
    const random = getRandomArbitrary(1, 1000);
    const gas = await window.contracts.land.estimateGas.landDiscovery(
      discoverLandItem.tokenId,
      selectedMonster.tokenId,
      landRegion,
      random
    );
    // console.log(`gas`, parseInt(gas));

    window.contracts.land.landDiscovery(
      discoverLandItem.tokenId,
      selectedMonster.tokenId,
      landRegion,
      random,
      {gasLimit: parseInt(gas * 1.2)}
    ).then(tx => {
      setCurrentStep(3);
      tx.message = "Land Discovery";
      addPendingTransaction(dispatch, tx, tx.message);

      tx.wait().then(receipt => {
        if (receipt.status === 1) {
          receipt.events.map(event => {
            if (event.args) {
              const {rewardType, rewardValues, rewardDurability} = event.args;
              const values = rewardValues.map(v => parseInt(v));
              console.log(`[rewardType, values, rewardDurability]`, [rewardType, values, rewardDurability]);
              showDiscoveryResults(rewardType, values, rewardDurability);
            }
          });
        }
      });
    }).catch(err => {
      addTransactionError(dispatch, err.message);
    });
  };

  const canDiscover = (nextLandDiscovery) => {
    const now = +new Date();
    return now > nextLandDiscovery;
  };

  const currentEvent = () => {
    let result = discoveryEventCount + 1;
    let max = maxDiscoveryEvents(discoverLandItem.landType);
    if (result > max) {
      return max;
    }
    return result;
  };

  const multiplyCharacteristics = () => {
    if (selectedMonster.cardRarity === "Common") {
      return ["x0.5", "x1", "Level 1"];
    } else if (selectedMonster.cardRarity === "UnCommon") {
      return ["x1", "x1", "Level 1"];
    } else if (selectedMonster.cardRarity === "Rare") {
      return ["x2", "x2", "Level 2"];
    }
    return ["x4", "x3", "Level 3"];
  };

  const canDiscoverLand = () => {
    return (
      discoverLandItem.discoverEvents <
      maxDiscoveryEvents(discoverLandItem.landType)
    );
  };

  const repeatDiscover = (isInit) => {
    setCurrentStep(1);
    setLandRegion(null);

    setShowDiscoveryLoading(false);
    setDiscoveryResult();
    setShowDiscoveryResult(false);
    if (!isInit) {
      setDiscoveryEventCount(discoveryEventCount + 1);
    }
  };

  const resetLandEvents = async () => {
    console.log(`resetLandEvents...`);
    // await mainContract.landRestoreDiscoveryEvents(
    //   discoverLandItem.tokenId,
    //   convertToYocto(resetDiscoveryPriceMap[discoverLandItem.landType])
    // );
  };

  return (
    <Popup
      title={`${discoverLandItem?.landType} Land Discovery`}
      width={`sm:w-[800px] lg:w-[1060px] max-w-full`}
      popupVisible={discoverLandPopupVisible}
      setPopupVisible={setDiscoverLandPopupVisible}
    >
      {discoverLandItem && (
        <div className="md:mx-8 mt-2">
          <div>
            <p className="font-semibold mb-3">
              {canDiscoverLand() ? (
                <span className="lg:text-lg text-sky-200">
                  Land discovery: {currentEvent()} /{" "}
                  {maxDiscoveryEvents(discoverLandItem.landType)} events.
                </span>
              ) : (
                <>
                  <span className="text-lg text-red-300">
                    Land discovered, you can reset all land discovery events (
                    {resetDiscoveryPriceMap[discoverLandItem.landType]} {process.env.PAYMENT_TOKEN_SYMBOL}):
                  </span>
                  <div className={"w-72 mx-auto mt-3 mb-8"}>
                    <Button
                      title={"Reset Discovery Events"}
                      onClick={resetLandEvents}
                      className="w-full bg-red-600"
                      icon={
                        <RefreshIcon className="h-5 w-5 ml-2 font-semibold"/>
                      }
                    />
                  </div>
                </>
              )}
            </p>

            {canDiscoverLand() && (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="mb-6">
                      Select your Monster for discovery. Monster "brain" and
                      "health" affect opportunity to escape from hunters.
                      <br/>
                      Depend on monster Card Rarity, found items characteristics
                      and tokens will be multiplied.{" "}
                      <a
                        href="https://zomland.gitbook.io/zomland-whitepaper/game-overview/lands#land-discovery"
                        target="_blank"
                        className="link"
                      >
                        Read More
                      </a>
                      .
                    </div>
                    <div className="mx-10">
                      <div>
                        {userMonsters[0] > 0 ? (
                          <div className="flex flex-row gap-3 flex-wrap justify-center">
                            {userMonsters[1].map((monster) => (
                              <div
                                className={`w-34 mb-1 cursor-pointer ${
                                  canDiscover(monster.nextLandDiscovery)
                                    ? ""
                                    : "opacity-50 pointer-events-none"
                                }`}
                                key={monster.tokenId}
                                onClick={() =>
                                  selectMonsterForDiscovery(monster)
                                }
                              >
                                <CardRotate nft={monster} size="sm"/>
                                <small className="block w-full h-4 mt-2">
                                  {!canDiscover(monster.nextLandDiscovery) ? (
                                    <>
                                      {diffDays(monster.nextLandDiscovery) >
                                        0 && (
                                          <>
                                            {diffDays(
                                              monster.nextLandDiscovery
                                            )}{" "}
                                            day{" "}
                                          </>
                                        )}
                                      {secondsToStringShort(
                                        timeDiffSeconds(
                                          monster.nextLandDiscovery
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <>Available</>
                                  )}
                                </small>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="text-red-300 mt-8">
                            *You don't have monsters for Land discovery.
                          </p>
                        )}
                      </div>

                      {userMonsters[0] > PAGE_LIMIT && (
                        <Pagination
                          total={userMonsters[0]}
                          limit={PAGE_LIMIT}
                          selectedPage={currentPage}
                          onPageChanged={onPageChanged}
                        />
                      )}
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    {canDiscoverLand() && (
                      <>
                        <p className="mb-6">
                          Select Land Region for discovery. More dangerous areas
                          have more resources and better modifiers, <br/>
                          but your monster is more likely to be killed or
                          wounded.
                        </p>
                        <div className="lg:flex lg:flex-row justify-around px-10">
                          {/*<CardLandRegion region={LandRegions[0]} selectRegion={(id) => selectRegion(id)} />*/}
                          <CardLandRegion
                            region={LandRegions[1]}
                            selectRegion={(id) => selectRegion(id)}
                          />
                          <CardLandRegion
                            region={LandRegions[2]}
                            selectRegion={(id) => selectRegion(id)}
                          />
                          <CardLandRegion
                            region={LandRegions[3]}
                            selectRegion={(id) => selectRegion(id)}
                          />
                        </div>
                        <div className="text-center mt-6 pt-6 border-t-2 border-white/10">
                          {process.env.ZML_TOKEN} Multiplier:{" "}
                          <b className="mr-4">{multiplyCharacteristics()[0]}</b>
                          Inventory Multiplier:{" "}
                          <b className="mr-4">{multiplyCharacteristics()[1]}</b>
                          Modifiers: <b>{multiplyCharacteristics()[2]}</b>
                        </div>
                      </>
                    )}
                  </>
                )}

                {currentStep === 3 && (
                  <div className="flex justify-center mt-4">
                    {!showDiscoveryResult ? (
                      <div
                        className={`relative ${
                          showDiscoveryLoading ? "rotate-card" : ""
                        }`}
                      >
                        {landRegion && (
                          <CardLandRegion
                            region={LandRegions[landRegion]}
                            noInfo
                          />
                        )}

                        <img
                          src={discoveryIcon}
                          alt=""
                          className={`absolute left-[20%] top-[20%] w-16 h-16 pointer-events-none ${
                            !showDiscoveryLoading ? "move-discovery-icon" : ""
                          }`}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-row relative transition">
                          <div className="rotate-card-reverse hidden md:block">
                            <div
                              className={`${
                                discoveryResult === 6 ? "opacity-40" : ""
                              }`}
                            >
                              <CardRotate nft={selectedMonster} noFlip/>
                            </div>

                            {discoveryResult === 5 && (
                              <img
                                src={brokenIcon}
                                alt="broken"
                                className="absolute top-0 right-8"
                              />
                            )}
                            {discoveryResult === 6 && (
                              <img
                                src={ripIcon}
                                alt="broken"
                                className="absolute bottom-10 left-4 w-24"
                              />
                            )}
                          </div>

                          <div className="md:ml-16 md:w-96 text-left discovery-text-results pt-4">
                            {discoveryResult === 1 && (
                              <>
                                <p className="mb-4">
                                  Your monster found{" "}
                                  <span className="text-lg font-semibold">
                                    Zombie Modifier
                                    {foundModifier.length > 1 ? "s" : ""}
                                  </span>
                                  :
                                </p>
                                <div>
                                  {foundModifier ? (
                                    <>
                                      <div className="flex flex-wrap gap-3">
                                        {foundModifier.map((item) => (
                                          <CardItem
                                            item={item}
                                            key={item.tokenId}
                                            size="sm"
                                            noFlip
                                          />
                                        ))}
                                      </div>
                                      <p className="mt-6">
                                        <Button
                                          title="Repeat Discovery"
                                          noIcon
                                          secondary
                                          onClick={() => repeatDiscover()}
                                        />
                                      </p>
                                    </>
                                  ) : (
                                    <Loader/>
                                  )}
                                </div>
                              </>
                            )}

                            {discoveryResult === 2 && (
                              <>
                                <p className="mb-4">
                                  Your monster found
                                  <span className="text-lg font-semibold ml-1">
                                    {foundInventoryItems.length} Inventory Item
                                    {foundInventoryItems.length > 1 ? "s" : ""}
                                  </span>
                                  :
                                </p>
                                {foundInventoryItems ? (
                                  <>
                                    <div className="flex flex-wrap gap-3">
                                      {foundInventoryItems.map((item) => (
                                        <CardItem
                                          isItem
                                          item={item}
                                          key={item.tokenId}
                                          size="sm"
                                          noFlip
                                        />
                                      ))}
                                    </div>
                                    <p className="mt-6">
                                      <Button
                                        title="Repeat Discovery"
                                        noIcon
                                        secondary
                                        onClick={() => repeatDiscover()}
                                      />
                                    </p>
                                  </>
                                ) : (
                                  <Loader/>
                                )}
                              </>
                            )}

                            {discoveryResult === 3 && (
                              <>
                                <p className="mb-4">
                                  Your monster found{" "}
                                  <span className="text-lg font-semibold">
                                    {process.env.ZML_TOKEN} Tokens:
                                  </span>
                                </p>
                                <p className="text-2xl font-semibold">
                                  +{convertFromYocto(foundZmlAmount, 0, true)} {process.env.ZML_TOKEN}
                                </p>
                                <p className="mt-6">
                                  <Button
                                    title="Repeat Discovery"
                                    noIcon
                                    secondary
                                    onClick={() => repeatDiscover()}
                                  />
                                </p>
                              </>
                            )}

                            {discoveryResult === 4 && (
                              <>
                                <p className="mb-4">
                                  Your monster found{" "}
                                  <span className="text-lg font-semibold">
                                    Part of staking Monster
                                  </span>
                                  :
                                </p>
                                <div>
                                  {foundMonsterPart ? (
                                    <>
                                      <div style={{zoom: "0.8"}}>
                                        <PartOfMonster
                                          item={foundMonsterPart}
                                        />
                                      </div>
                                      <p className="mt-6">
                                        <Button
                                          title="Repeat Discovery"
                                          noIcon
                                          secondary
                                          onClick={() => repeatDiscover()}
                                        />
                                      </p>
                                    </>
                                  ) : (
                                    <Loader/>
                                  )}
                                </div>
                              </>
                            )}

                            {discoveryResult === 5 && (
                              <>
                                <p>
                                  Your monster{" "}
                                  <span className="text-lg font-semibold">
                                    Wounded by Hunters
                                  </span>
                                  . Monster will be able to discover Lands after
                                  48 hours.
                                </p>
                                <p className="mt-6">
                                  <Button
                                    title="Repeat Discovery"
                                    noIcon
                                    secondary
                                    onClick={() => repeatDiscover()}
                                  />
                                </p>
                              </>
                            )}

                            {discoveryResult === 6 && (
                              <>
                                <p>
                                  Your monster{" "}
                                  <span className="text-lg font-semibold">
                                    Killed by Hunters
                                  </span>
                                  .
                                </p>
                                <p className="mt-6">
                                  <Button
                                    title="Repeat Discovery"
                                    noIcon
                                    secondary
                                    onClick={() => repeatDiscover()}
                                  />
                                </p>
                              </>
                            )}

                            {discoveryResult === 7 && (
                              <>
                                <p>
                                  Your monster came back{" "}
                                  <span className="text-lg font-semibold">
                                    without any goods
                                  </span>
                                  , but alive.
                                </p>
                                <p className="mt-6">
                                  <Button
                                    title="Repeat Discovery"
                                    noIcon
                                    secondary
                                    onClick={() => repeatDiscover()}
                                  />
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="mt-16">
            <div className="flex flex-row relative lg:mx-28">
              <div className="absolute h-1 md:left-32 sm:left-28 left-16 md:right-32 sm:right-28 right-16 bg-orange-400 top-5 z-0">
                &nbsp;
              </div>
              <div
                className={`absolute h-1 md:right-32 sm:right-28 right-16 ${stepLineMap[currentStep]} bg-gray-200 top-5 z-0`}
              >
                &nbsp;
              </div>
              <StepCircle step={1}/>
              <StepCircle step={2}/>
              <StepCircle step={3}/>
            </div>
            <div className="flex flex-row lg:mx-28">
              <StepDescription step={1} text="Select Monster"/>
              <StepDescription step={2} text="Select Region"/>
              <StepDescription step={3} text="Discovery"/>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};
