import React, {useEffect, useState} from "react";
import {statusColorTextMap} from "../../web3/utils";
import attack_icon from "../../assets/images/attack_icon.png";
import health_icon from "../../assets/images/health_icon.png";
import intellect_icon from "../../assets/images/intelect_icon.png";
import speed_icon from "../../assets/images/speed_icon.png";
import {postRequest} from "../../web3/api";
import {useSelector} from "react-redux";
import {Popup} from "../Popup";

export const BattleZombiesPopup = ({battle, visible, setVisible}) => {
  const currentUser = useSelector(state => state.user.user);
  const [myZombies, setMyZombies] = useState([]);

  const loadUserZombies = async () => {
    return await postRequest("api/clan-battle/user-zombies", {
      accountId: btoa(currentUser.accountId),
      battleId: battle.id,
    });
  };

  const CardCharacteristic = ({item, icon}) => (
    <div className="my-1 flex text-center items-center ">
      <img src={icon} alt="" className="w-4 mr-2"/>
      <span className="text-indigo-50">{item}</span>
    </div>
  );

  const getPower = (item) =>
    item.attack + item.health + item.brain + item.speed;

  useEffect(() => {
    loadUserZombies().then((result) => {
      setMyZombies(result.data);
    });
  }, [battle]);

  return (
    <Popup
      width="sm:w-[816px]"
      title={"My Zombies in Battle"}
      popupVisible={visible}
      setPopupVisible={setVisible}
    >
      <div className={"font-semibold mb-6"}>
        Battle {battle.requiredSize / 2}x{battle.requiredSize / 2},{" "}
        {battle.cardRarity}
      </div>

      <div className={"flex flex-row flex-wrap justify-center gap-8 mb-6"}>
        {myZombies.map((item) => (
          <div
            key={item.id}
            className={
              "relative flex flex-col w-36 bg-main/50 items-center justify-center py-4 rounded-lg border-2 border-gray-500"
            }
          >
            <p
              className={`font-semibold mb-1 ${statusColorTextMap(
                battle.cardRarity
              )}`}
            >
              {battle.cardRarity} ({getPower(item.zombie)})
            </p>
            <CardCharacteristic item={item.zombie.attack} icon={attack_icon}/>
            <CardCharacteristic item={item.zombie.health} icon={health_icon}/>
            <CardCharacteristic
              item={item.zombie.brain}
              icon={intellect_icon}
            />
            <CardCharacteristic item={item.zombie.speed} icon={speed_icon}/>

            {Object.keys(item.inventory).length > 0 && (
              <div
                className={
                  "w-16 h-16 text-sm text-center absolute -right-5 -bottom-4 border-2 border-dashed border-white/20 text-white rounded-md"
                }
              >
                <img src={item.inventory.image} alt=""/>
                {/*<span className={"absolute left-0 right-0 bottom-2"}>{item.inventory.type}</span>*/}
              </div>
            )}
          </div>
        ))}
      </div>
    </Popup>
  );
};
